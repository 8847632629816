import React, { useEffect, useState } from "react";
import "../UserManagement/UserManagement.css";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { getUserEmail, getUserRole } from "../../services/loggedIn";
import { buttoncontainded, inputfieldstyle1, tablehead } from "../../styles.js";
import { UserRow } from "../../components/Users/UserRow.jsx";
import { UserDetails } from "../../components/Users/UserDetails.jsx";
import { tableheaddata } from "../UserManagement/tableheaddata.js";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../../services/crypto.js";
import { deleteUser } from "../../services/UsermanagementApi.js";
import { getOrganizationUsers } from "../../services/OrganizationManagemnetApi.js";
import { Loader } from "../../components/Loader/Loader.jsx";
import ConfirmModal from "../../components/PopUpDialog/ConfirmModal.jsx";
import ThankyouModal from "../../components/PopUpDialog/ThankyouModal.jsx";

// The main component to display the user management page
const ManageUser = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  let [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [modalForEditUser, setModalForEditUser] = useState(false);

  const location = useLocation();

  const orgName = location.state.organizationName;
  const deptEdited = location.state.deptEdited;
  const ouEdited = location.state.ouEdited;
  const orgEmail = location.state.organizationEmail;

  // console.log("location.state", location.state);

  const [ouAndDeptEdited, setOuAndDeptEdited] = useState(
    location.state.ouAndDeptEdited
  );
  const userRole = getUserRole();
  // // // // console.log("state", location.state);

  const navigate = useNavigate();

  useEffect(() => {
    if (deptEdited || ouEdited) {
      setModalForEditUser(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      // // console.log("orgEmail", orgEmail);
      await getOrganizationUsers(orgEmail)
        .then((response) => {
          // // console.log("response", response);

          const decryptedData = decryptData(response.data.encryptedData);
          const parsedData = JSON.parse(decryptedData);
          // // console.log("parsedDataAAAAAAAA", parsedData);

          // // console.log("manageUsers", parsedData);

          setUsers(parsedData.users);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchUserData();
  }, []);
  const handleView = (user) => {
    setSelectedUser(user);
    // setShowModal(true);
    // setModalMode("view");
    navigate("/view-user", {
      state: {
        user: user,
      },
    });
  };
  const handleClose = () => {
    setShowModal(false);
    setModalMode(null);
  };
  const handleAddForm = () => {
    navigate("/user/add-user", {
      state: {
        organizationName: location.state.organizationName,
        orgEmail: location.state.organizationEmail,
      },
    });
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setShowModal(false);
    setModalMode(null);
  };
  const handleDelete = (user) => {
    const e = getUserEmail();
    if (user.email === e) {
      toast.error("Unable to delete logged user");
    } else {
      setUserToDelete(user);
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const userData = {
        email: userToDelete.email,
      };
      deleteUser(userData)
        .then((response) => {
          setUsers((prevUsers) =>
            prevUsers.filter((u) => u.email !== userToDelete.email)
          );
          toast.success("User Deleted Successfully!");
        })
        .catch((error) => {
          console.error(error);
        });
      setIsConfirmModalOpen(false);
      setUserToDelete(null);
    }
  };
  const handlePageChange = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
  };

  const handleUpdate = (user) => {
    // setSelectedUser(user);
    // setShowModal(true);
    // setModalMode("update");
    navigate("/user/update-user", {
      state: {
        user: user,
      },
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const closePopup = () => {
    setModalForEditUser(false);
    setOuAndDeptEdited(false);
  };

  // // // console.log("users", users);
  return (
    <>
      <Hamburger />
      <div className="container-user">
        <h1 className="frame_heading">User Management List for {orgName}</h1>
        <div className="add-user-btn-search">
          <Button
            variant="contained"
            style={buttoncontainded}
            onClick={handleAddForm}
          >
            Add User
          </Button>
          <div>
            <TextField
              id="outlined-basic"
              label="Search User"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <TableContainer sx={{ maxHeight: 440 }} style={{ overflow: "scroll" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              padding: "10px",
            }}
          >
            <TableBody>
              <TableRow style={tablehead}>
                {tableheaddata.map((item) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                      key={item.id}
                    >
                      {item.value}
                    </TableCell>
                  );
                })}
              </TableRow>
              {users
                .filter((user) => {
                  return (
                    user.userName
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    user.email.toLowerCase().includes(search.toLowerCase()) ||
                    user.mobile.includes(search)
                  );
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, id) => {

                  return (
                    <UserRow
                      key={user.userName}
                      user={user}
                      id={id + 1 + page * rowsPerPage}
                      onView={handleView}
                      onDelete={handleDelete}
                      onUpdate={handleUpdate}
                    />)
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 12, 20]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Loader loading={loading} />
      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${userToDelete?.userName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
      />
      {modalForEditUser && (
        <ThankyouModal
          closePopup={closePopup}
          handleNavigate={closePopup}
          openPopup={modalForEditUser}
          message={"Please Edit the User Department and Association"}
          title={""}
          confirmText="Ok"
        />
      )}

      {showModal && (
        <>
          {modalMode === "view"
            ? selectedUser && (
              <UserDetails user={selectedUser} handleClose={handleClose} />
            )
            : (modalMode = "none")}
        </>
      )}
    </>
  );
};

export default ManageUser;
