import { toast } from "react-toastify";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getCountryCurrency, getUserRole } from "../../services/loggedIn";

import { BsInfoCircle } from "react-icons/bs";
import { useEffect, useState } from "react";
import {
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../styles";
import BasicModal from "../PopUpDialog/BasicModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import { useNavigate } from "react-router-dom";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFieldKPIState,
  setKPIinputdata,
} from "../../redux/slice/KpiSlice";
import { updatePreservedInput } from "../../redux/slice/PreservedSlice";
import { CommentPopUp } from "../../dashboard/pages/SPOCDataReview/CommentPopUp";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CommentIcon from '@mui/icons-material/Comment';
import { initializeItemWithDefaults } from "../../services/helpingFunctions";

export const FieldListTable = ({
  orgplt,
  orgdept,
  setTableFromat,
  tableData,
  setKpiinputdata = () => { },
  tableRendering,
  tableDraftRendering,
  frequency,
  status,
  kpicode,
  reviewedBy,
  isreviewed,
  comments
}) => {
  const [updatedTableData, setUpdatedTableData] = useState();
  const [editableInput, setEditiableInput] = useState();
  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);


  const dispatch = useDispatch();
  const { fieldListKpiInputdata } = useSelector((state) => state.kpi);


  const navigate = useNavigate();
  const formData = "";
  const fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";


  const currentKpiData = fieldListKpiInputdata.find(item => Object.keys(item)[0] === `${frequency}~${kpicode}~${orgdept}~${orgplt}`);

  // useEffect(() => {
  //   // Initialize the table data with empty values while maintaining the structure
  //   const initialData = tableData.map(item => {
  //     const newItem = { ...item };
  //     // For each field that has a format, initialize an empty value
  //     Object.keys(newItem).forEach(key => {
  //       if (newItem[key]?.format?.includes("input")) {
  //         newItem[key] = {
  //           ...newItem[key],
  //           value: ''  // Initialize empty value
  //         };
  //       }
  //     });
  //     return newItem;
  //   });

  //   setUpdatedTableData(initialData);
  //   if (!currentKpiData) {
  //     setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
  //   } else {
  //     setEditiableInput(currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]);
  //   }
  // }, [tableDraftRendering, currentKpiData, frequency, kpicode, orgdept, orgplt, tableData]);

  useEffect(() => {
    // Initialize table data with default values
    const initialData = tableData.map(initializeItemWithDefaults);
    setUpdatedTableData(initialData);

    // Set editable input based on current KPI data or draft rendering
    const editableData = currentKpiData
      ? currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]
      : tableDraftRendering?.map(item => ({ ...item }));

    setEditiableInput(editableData);
  }, [
    tableDraftRendering,
    currentKpiData,
    frequency,
    kpicode,
    orgdept,
    orgplt,
    tableData,
  ]);


  const frequency_ = frequency;

  const handleEditInputChange = (index, key, value) => {
    setEditiableInput((prevInput) => {
      if (!prevInput || !Array.isArray(prevInput)) {
        console.error("prevInput is not an array:", prevInput);
        return prevInput; // Return unchanged if it's not an array
      }
      const newInput = [...prevInput];
      if (!newInput[index]) {
        console.error("No item at index:", index);
        return prevInput; // Return unchanged if index doesn't exist
      }
      newInput[index] = {
        ...newInput[index],
        [key]: { ...newInput[index][key], value: value },
      };
      return newInput;
    });
  };

  const handleInputChange = (index, key, value) => {
    setUpdatedTableData(prevData => {
      const newData = [...prevData];
      if (newData[index] && newData[index][key]) {
        newData[index] = {
          ...newData[index],
          [key]: {
            ...newData[index][key],
            value: value
          }
        };
      }
      return newData;
    });
    // Create a deep copy of updatedTableData
    // let updatedData = updatedTableData;

    // // Update the value property of the corresponding object
    // if (updatedData[index] && updatedData[index][key]) {
    //   updatedData[index][key].value = value;
    // }

    // setUpdatedTableData(updatedData);
    //   const hasChanges = updatedData.some((row, rowIndex) =>
    //     Object.keys(row).some((fieldKey) =>
    //         row[fieldKey].value !== "" && row[fieldKey].value !== null && row[fieldKey].value !== undefined
    //     )
    // );
    // setIsDirty(hasChanges);

    // alltable[index][key] = value;
  };

  // console.log("updatedData", updatedTableData);


  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      setEditiableInput((prevInput) => {
        if (!prevInput || !Array.isArray(prevInput)) {
          console.error("prevInput is not an array:", prevInput);
          return prevInput; // Return unchanged if it's not an array
        }
        const newInput = [...prevInput];
        if (!newInput[index]) {
          console.error("No item at index:", index);
          return prevInput; // Return unchanged if index doesn't exist
        }
        newInput[index] = {
          ...newInput[index],
          [key]: { ...newInput[index][key], value: value },
        };
        // // console.log("newInput", newInput);
        return newInput;
      });
    }
  };

  const submit = () => {
    const kpi_data = {
      [frequency_ + "~" + kpicode]: updatedTableData,
    };

    // Update accumulated data by merging with any existing data for this KPI
    const kpiKey = `${kpicode}-${orgdept}-${orgplt}`;

    // Update Redux state
    dispatch(updatePreservedInput({
      kpiCode: kpiKey,
      period: frequency,
      value: updatedTableData,
      department: orgdept,
      operationalUnit: orgplt
    }));

    toast.success("Fields saved successfully");
    setKpiinputdata(kpi_data);
  };

  const submitForDraft = () => {
    if (typeof setKpiinputdata === "function") {
      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      const redux_kpi_key = {
        [frequency + "~" + kpicode + "~" + orgdept + "~" + orgplt]: editableInput
      }

      toast.success("Fields saved successfully");
      // Call setKpiinputdata to update state or context
      dispatch(setKPIinputdata(redux_kpi_key));

      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
    // setIsDirty(false);
  };

  const handleChange = (index, key, value, flag) => {
    if (flag) {
      let updatedData = JSON.parse(JSON.stringify(updatedTableData));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setUpdatedTableData(updatedData);
    }
  };

  function numbeval(value) {
    const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    return regex.test(value);
  }

  const validateNumber = (value, onChange, key, index) => {
    // Validate number: maximum 13 places and two decimal places
    if (numbeval(value)) {
      if (status === "drafted") {
        onChange(index, key, value);
      } else {
        onChange(value);
      }
    } else {
      // If the input is not valid, don't update the value and display an error
      toast.error("Invalid number format!");
    }
  };

  const validatePerc = (value) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    return regex.test(value);
  };

  const validatePercent = (value, onChange, index, key) => {
    if (validatePerc(value)) {
      if (status === "drafted") {
        handleEditInputChange(index, key, value)
      } else {
        onChange(value);
      }
    } else {
      toast.error("Invalid percent format!");
    }
  };

  const validateURL = (value) => {
    // const validateURL = (value) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (!isValid) {
      toast.error("Invalid URL format!");
    }
    return isValid;
  };

  const validateEmail = (value, onChange, index, key) => {
    const regex = /^[a-zA-Z0-9._@]*$/;
    const isValid = regex.test(value);
    if (isValid) {
      if (status === "drafted") {
        handleEditInputChange(index, key, value)
      } else {
        onChange(value)
      }
    } else {
      const validationToastId = 'invalid-email-toast';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid Email format!", {
          toastId: validationToastId,
        });
      }
    }
  };

  const renderCellValue = (item, key, index) => {
    const formatValue = item[key]?.format;
    const currentValue = item[key]?.value || '';

    if (formatValue?.includes("input")) {
      //input-feildtype
      const fieldType = formatValue?.split("-")[1];

      // if (formatValue?.includes("MCQ")) {
      //   const options = formatValue?.match(/\(([^)]+)\)/)[1].split("|");
      //   return (
      //     <FormControl
      //       sx={{ minWidth: 120 }}
      //       size="small"
      //       style={{ width: "100%" }}
      //     >
      //       <InputLabel id="demo-select-small-label">Select Option</InputLabel>
      //       <Select
      //         onChange={(e) => handleInputChange(index, key, e.target.value)}
      //         label="Select option"
      //       >
      //         {/* <MenuItem value="">Select Option</MenuItem> */}
      //         {options.map((option, idx) => (
      //           <MenuItem key={idx} value={option}>
      //             {option}
      //           </MenuItem>
      //         ))}
      //       </Select>
      //     </FormControl>
      //   );
      // } else if (fieldType === "Binary") {
      //   return (
      //     <FormControl
      //       sx={{ minWidth: 120 }}
      //       size="small"
      //       style={{ width: "100%" }}
      //     >
      //       <InputLabel id="demo-select-small-label">Select Option</InputLabel>
      //       <Select
      //         onChange={(e) => handleInputChange(index, key, e.target.value)}
      //         label="Select option"
      //       >
      //         {/* <option value="">Select Option</option> */}
      //         <MenuItem value="Yes">Yes</MenuItem>
      //         <MenuItem value="No">No</MenuItem>
      //       </Select>
      //     </FormControl>
      //   );
      // } else if (formatValue?.includes("input")) {
      //   switch (fieldType) {
      //     case "number":
      //       return (
      //         <TextField
      //           style={{ width: "100%" }}
      //           type="number"
      //           placeholder="Enter Number"
      //           onChange={(e) =>
      //             validateNumber(e.target.value, handleInputChange, key, index)
      //           }
      //         />
      //       );
      //     case "wholeNumber":
      //       return (
      //         <TextField
      //           style={{ width: "100%" }}
      //           type="number"
      //           placeholder="Enter Number"
      //           onChange={(e) =>
      //             validateNumber(e.target.value, handleInputChange, key, index)
      //           }
      //           inputProps={{ maxLength: 5 }}
      //         />
      //       );
      //     case "Currency":
      //       return (
      //         <>
      //           <TextField
      //             style={{ width: "100%" }}
      //             type="text"
      //             placeholder="Currency"
      //             step="0.01"
      //             onChange={(e) => {
      //               validateNumber(
      //                 e.target.value,
      //                 handleInputChange,
      //                 key,
      //                 index
      //               );
      //             }}
      //           />
      //           <span>{getCountryCurrency()} </span>
      //         </>
      //       );
      //     case "decimal":
      //       return (
      //         <TextField
      //           style={{ width: "100%" }}
      //           type="number"
      //           step="0.01"
      //           placeholder="Enter Decimal"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             validateNumber(value, handleInputChange, key, index);
      //           }}
      //         />
      //       );
      //     case "percent":
      //       return (
      //         <TextField
      //           style={{ width: "100%" }}
      //           type="percent"
      //           value={currentValue}
      //           placeholder="Enter Percent"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             validatePercent(value, handleInputChange, index, key);
      //           }}
      //         />
      //       );
      //     case "openText":
      //       return (
      //         <TextField
      //           type="text"
      //           style={{ width: "100%" }}
      //           placeholder="Enter Text"
      //           onChange={(e) => handleInputChange(index, key, e.target.value)}
      //           inputProps={{ maxLength: 500 }}
      //         />
      //       );
      //     case "url":
      //       return (
      //         <TextField
      //           type="text"
      //           style={{ width: "100%" }}
      //           placeholder="Enter URL"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             if (validateURL(value)) {
      //               handleInputChange(index, key, value);
      //             }
      //           }}
      //         />
      //       );
      //     case "eMail":
      //       return (
      //         <TextField
      //           type="text"
      //           style={{ width: "100%" }}
      //           placeholder="Enter Email"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             if (validateEmail(value)) {
      //               handleInputChange(index, key, value);
      //             }
      //           }}
      //         />
      //       );

      //     default:
      //       return (
      //         <TextField
      //           style={{ width: "100%" }}
      //           type="text"
      //           placeholder="Enter Text"
      //           onChange={(e) => handleInputChange(index, key, e.target.value)}
      //         />
      //       );
      //   }
      // }


      //TODO: remove above code if below code works

      return InputRenderers.renderInput(
        fieldType,
        currentValue,
        (value) => handleInputChange(index, key, value),
      );
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid #45b653",
              background: "#45b653",
              borderRadius: "15px",
              color: "white",
              padding: "5px 35px",
            }}
            onClick={submit}
          >
            Save
          </Button>
        </>
      );
    } else {
      return <div>{formatValue}</div>;
    }
  };

  // Input Renderer Utility
  const InputRenderers = {
    // Utility to extract options from format string
    extractOptions: (format) => {
      const optionsMatch = format.match(/\(([^)]+)\)/);
      return optionsMatch ? optionsMatch[1].split('|') : [];
    },

    // Generic input type handlers
    renderDropdown: (
      inputType,
      value,
      onChange,
      extraProps
    ) => {
      const options = InputRenderers.extractOptions(inputType);

      return (
        <select
          value={value === "Not Filled" ? "" : value || ""}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "200px" }}
          {...extraProps}
        >
          <option value="">Select Option</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    },


    renderWholeNumberInput: (
      value,
      onChange,
      inputType,
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={parseInt(value) > 0 ? value : "" || ""}
          placeholder={`Enter Number (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "0" : value;
            validateNumber(newValue, onChange)
          }}
          inputProps={{
            maxLength: limit,
          }}
        />
      );
    },

    renderOpenTextInput: (
      value,
      onChange,
      inputType,
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={value === "Not Filled" ? "" : value || ""}
          placeholder={`Enter Open Text (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "Not Filled" : value;
            onChange(newValue)
          }}
          inputProps={{
            maxLength: limit,
          }}
          style={{ width: "100%" }}
        />
      );
    },

    // Main input renderer
    renderInput: (
      inputType,
      value,
      onChange
    ) => {

      if (inputType.includes('dropDown(')) {
        return InputRenderers.renderDropdown(inputType, value, onChange);
      }

      if (inputType.includes('wholeNumber(')) {
        return InputRenderers.renderWholeNumberInput(value, onChange, inputType);
      }

      if (inputType.includes('openText(')) {
        return InputRenderers.renderOpenTextInput(value, onChange, inputType);
      }

      // Default type-based renderers
      switch (inputType) {
        case 'openText':
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
              style={{ width: "200px" }}
            />
          );

        case 'wholeNumber':
          return (
            <TextField
              type="number"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateNumber(newValue, onChange)
              }}
              placeholder="Enter Number"
              style={{ width: "200px" }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
            />
          );

        case 'percent':
          return (
            <TextField
              type="percent"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target
                const newValue = value.trim() === "" ? "0" : value;
                validatePercent(newValue, onChange)
              }}
              placeholder="Enter Percentage"
              style={{ width: "200px" }}
            />
          );

        case 'decimal':
        case 'number':
          return (
            <TextField
              type="number"
              step="any"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateNumber(
                  newValue,
                  onChange
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
              placeholder="Decimal"
              style={{ width: "200px" }}
            />
          );

        case 'currency':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="number"
                step="any"
                value={parseInt(value) > 0 ? value : "" || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  const newValue = value.trim() === "" ? "0" : value;
                  validateNumber(
                    newValue,
                    onChange
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
                placeholder="Decimal"
                style={{ width: "200px", marginRight: '8px' }}
              />
              <span>{getCountryCurrency()}</span>
            </div>
          );

        case 'url':
          return (
            <TextField
              type="text"
              placeholder="Enter URL"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                validateURL(
                  newValue,
                  onChange
                );
              }}
              style={{ width: "200px" }}
            />
          );

        case 'Binary':
          return InputRenderers.renderDropdown(
            'input-dropDown(Yes|No)',
            value,
            onChange
          );

        case 'eMail':
          return (
            <TextField
              variant="outlined"
              type="email"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                validateEmail(
                  newValue,
                  onChange
                );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              placeholder="Enter Email"
            />
          );
        default:
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
            />
          );
      }
    }
  };

  const renderCellValueSaved = (item, key, index) => {
    // // console.log("item", item[key]);

    if (index === tableData.length - 1) {
      if (item.Field_button) {
        return <div></div>;
      }
    }
    return <div>{item[key]?.value}</div>;
  };

  const renderEditableCellValue = (item, value, index, key) => {
    // console.log("item", item);

    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }
    if (value?.format?.includes("input")) {
      const fieldType = value.format.split("-")[1];
      const existingValue = item[key]?.value || "";

      if (fieldType.includes('openText(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];

        return (
          <TextField
            type="text"
            value={existingValue === "Not Filled" ? "" : existingValue || ""}
            placeholder={`Enter Open Text (max ${limit})`}
            onChange={(e) => {
              const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
              handleEditInputChange(index, key, newValue)
            }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }

      if (fieldType.includes('wholeNumber(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];
        return (
          <TextField
            type="text"
            value={parseInt(existingValue) > 0 ? existingValue : ""}
            placeholder={`Enter Number (max ${limit})`}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value.trim() === "" ? "0" : value;
              validateNumber(newValue, handleEditInputChange, key, index)
            }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }

      if (fieldType.includes('dropDown(')) {
        const options = InputRenderers.extractOptions(fieldType);
        return (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Option"
              // sx={{ width: '100px' }}
              value={existingValue === "Not Filled" ? "" : existingValue || ""}
              onChange={(e) => handleEditInputChange(index, key, e.target.value)}
            >
              <MenuItem value="Not Filled">Select Option</MenuItem>
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }

      if (value.format.includes("Binary(")) {
        const options = InputRenderers.extractOptions(fieldType);
        return (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Option"
              // sx={{ width: '100px' }}
              value={existingValue === "Not Filled" ? "" : existingValue || ""}
              onChange={(e) => handleEditInputChange(index, key, e.target.value)}
            >
              <MenuItem value="Not Filled">Select Option</MenuItem>
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue === "Not Filled" ? "" : existingValue || ""}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : ""}
                placeholder="Enter Number"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                  validateNumber(
                    newValue,
                    handleEditInputChange,
                    key,
                    index
                  );
                }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : ""}
                placeholder="Enter Percent"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                  validatePercent(
                    newValue,
                    handleEditInputChange,
                    index,
                    key
                  );
                }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter URL"
                onChange={(e) => {
                  const isValid = validateURL(e.target.value);
                  if (isValid) {
                    const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                    handleEditInputChange(index, key, newValue);
                  }
                }}
              />
            );
          case "eMail":
            return (
              <TextField
                type="email"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Email"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                  validateEmail(newValue,
                    handleEditInputChange,
                    index,
                    key);
                }}
              />
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Text"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                  handleEditInputChange(index, key, newValue)
                }}
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue === "Not Filled" ? "" : existingValue || ""}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          placeholder="Enter Number"
        />
      );
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name?.replaceAll("_", " ");
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetFieldKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };


    return [
      {
        KPICode: kpicode,
        KPIInput: editableInput,
        format: setTableFromat,
        draftStatus: draftStatus,
        comments: comments,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };

  const handleClick = () => {
    setLoading(true);
    try {
      // // console.log("About to call handleSubmit");
      const returnValue = handleSubmit(
        dispatch,
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      dispatch,
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      setIsSavingDraft,
      [],
      "",
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  const handleBack = () => {
    navigate(-1);
  };
  const role = getUserRole();

  const handleCommentBoxOpen = (detail) => {
    setCommentBoxOpen(true);
  };
  const handleCommentBoxClose = () => {
    setCommentBoxOpen(false);
  };

  return (
    <>
      {getUserRole() === "User" &&
        <div style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: 'end',
          marginRight: "20px"
        }}>

          {/* {detail.Description && ( */}
          <Tooltip title="Guide">
            <IconButton
              // disabled={detail.Description === undefined}

              className="thumbdown"
            // onClick={() =>
            //   handleOpenDecsModal(detail, i)
            // }
            >
              <MenuBookIcon style={{ color: "rgb(69, 182, 83)" }}
              />{" "}
            </IconButton>
          </Tooltip>
          {/* )}{" "} */}
          <Tooltip title="Comment">
            <span>
              <IconButton
                className="thumbdown"
                onClick={() => handleCommentBoxOpen()}
                disabled={(status ===
                  "approved") || (status ===
                    "unfilled") || (status ===
                      "saved") || (reviewedBy === undefined)}
              >
                <CommentIcon style={{ color: "#3079bd" }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
      <TableContainer>
        {(status === "unfilled" || (status === "drafted" && role !== "SPOC")) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsInfoCircle
                style={{ color: "red", fontWeight: "600", marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Please Save the Table Details Before Submitting
              </Typography>
            </div>
            <Button
              onClick={status === "unfilled" ? submit : submitForDraft}
              sx={{ textTransform: "none" }}
              style={{
                border: "1px solid #45b653",
                background: "#45b653",
                borderRadius: "15px",
                color: "white",
                padding: "5px 35px",
              }}
            >
              Save
            </Button>
          </Box>
        )}
        <Table style={{ overflow: "scroll" }}>
          <TableBody
            style={{
              padding: "2rem",
            }}
          >
            {status === "unfilled" &&
              updatedTableData?.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      {Object.keys(item)
                        .sort()
                        .map((key, keyIndex) => {
                          if (key.startsWith("Field_")) {
                            return (
                              <>
                                {/* renderDynamicInput(item, key, index, handle) */}
                                <TableCell
                                  key={keyIndex}
                                  style={{
                                    wordBreak: "break-word",
                                    // backgroundColor:
                                    //   keyIndex=== 0 ? "#3079bd" :"transparent",
                                    backgroundColor:
                                      keyIndex === 0
                                        ? "#E3F4F4"
                                        : "transparent",
                                    visibility:
                                      item[key]?.format?.includes("Please") ||
                                        item[key]?.format?.includes("save")
                                        ? "hidden"
                                        : "visible",
                                    // color: keyIndex === 0 ? "white" : "",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    {renderCellValue(item, key, index)}
                                  </div>
                                </TableCell>
                              </>
                            );
                          }
                          return null;
                        })}
                    </TableRow>
                  </>
                );
              })}

            {(status === "saved" || status === "approved" || (status === "drafted" && isreviewed === "yes")) &&
              tableRendering?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      .sort()
                      .map((key, keyIndex) => {
                        if (key.startsWith("Field_")) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "transparent",
                                // color: keyIndex === 0 ? "white" : "",
                                width: keyIndex === 0 ? "400px" : "",
                              }}
                            >
                              {/* <div style={{ width: "100%" }}> */}
                              {renderCellValueSaved(item, key, index)}
                              {/* </div> */}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                  </TableRow>
                );
              })}

            {/* ------------ */}

            {status === "drafted" &&
              editableInput?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      .sort()
                      .map((key, keyIndex) => {
                        if (key.startsWith("Field_")) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                backgroundColor:
                                  keyIndex === 0 ? "#E3F4F4" : "transparent",
                                // color: keyIndex === 0 ? "white" : "",
                                width: keyIndex === 0 ? "400px" : "",
                              }}
                            >
                              {/* <div style={{ width: "100%" }}> */}
                              {renderEditableCellValue(
                                item,
                                item[key],
                                index,
                                key
                              )}
                              {/* </div> */}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved" || status === "approved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved" || status === "approved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
      {commentBoxOpen &&
        <CommentPopUp
          open={commentBoxOpen}
          handleClose={handleCommentBoxClose}
          // onSubmitComment={handleAddComment}
          // submittedComments={submittedComments}
          // draftComment={draftComment}
          // setDraftComment={setDraftComment}
          // onDeleteComment={handleDeleteComment}
          // onEditComment={handleEditComment}
          reviewedBy={reviewedBy}
          commentsArray={comments}
        />}
    </>
  );
};
