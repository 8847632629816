import { toast } from "react-toastify";
import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCountryCurrency, getUserRole } from "../../services/loggedIn";
import {
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
  userdeletebutton,
} from "../../styles";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import BasicModal from "../PopUpDialog/BasicModal";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAddMoreKPIState,
  setAddMoreKpiInputdata,
} from "../../redux/slice/KpiSlice";
import { updatePreservedInput } from "../../redux/slice/PreservedSlice";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CommentIcon from '@mui/icons-material/Comment';
import { CommentPopUp } from "../../dashboard/pages/SPOCDataReview/CommentPopUp";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getStatesAndDistricts } from "../../services/ClientSideApis";
// import statesAndDistricts from "../../../src/stateDstrict.json"
import { FixedSizeList, ListBox } from "react-window"
import { getInitialValue, initializeItemWithDefaults } from "../../services/helpingFunctions";


export const AddMoreTable = ({
  setTableFromat,
  orgdept,
  orgplt,
  tableData,
  setKpiinputdata = () => { },
  tableRendering,
  tableDraftRendering,
  frequency,
  kpicode,
  status,
  reviewedBy,
  isreviewed,
  comments
}) => {
  const { addMoreKpiInputdata } = useSelector((state) => state.kpi);

  const [tableData1, setTableData1] = useState();
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [editableInput, setEditiableInput] = useState();
  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);
  const [statesAndDistricts, setStatesAndDistricts] = useState([])
  // Create an array to store input values for each row
  const [inputValues, setInputValues] = useState();
  const [confirm, setConfirm] = useState(false);


  const formData = "";
  const fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";


  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (addMoreKpiInputdata.length === 0) {
  //     setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
  //   } else {
  //     setEditiableInput(addMoreKpiInputdata);
  //   }
  // }, [tableDraftRendering, addMoreKpiInputdata, frequency]);

  const currentKpiData = addMoreKpiInputdata.find(item => Object.keys(item)[0] === `${frequency}~${kpicode}~${orgdept}~${orgplt}`);
  // console.log("currentKpiData", currentKpiData);

  useEffect(() => {
    // Initialize table data with default values
    const initialData = tableData.map(initializeItemWithDefaults);
    setTableData1(initialData)
    setJsonData([tableData[0]])
    setInputValues(Array(initialData.length).fill({}))
  }, [tableData]);

  useEffect(() => {
    // Initialize table data with default values
    // const initialData = tableData.map(initializeItemWithDefaults);
    // setTableData1(initialData)
    // setJsonData(tableData[0])
    if (!currentKpiData) {
      setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
    } else {
      setEditiableInput(currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]);
    }
  }, [tableDraftRendering, currentKpiData, frequency, kpicode, orgdept, orgplt]);

  //TODO: Very Important UseEffect do not remove this below code
  // useEffect(() => {
  //   // If no data in Redux, use the latest tableDraftRendering
  //   if (!currentKpiData) {
  //     setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
  //     return;
  //   }

  //   const currentData = currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`];

  //   // Compare if tableDraftRendering has any changes compared to currentData
  //   const hasChanges = tableDraftRendering?.some((draftItem, index) => {
  //     const currentItem = currentData[index];

  //     // If lengths are different, there's definitely a change
  //     if (!currentItem) return true;

  //     // Compare all fields including auto-increment
  //     return Object.keys(draftItem).some(key => {
  //       return JSON.stringify(draftItem[key]) !== JSON.stringify(currentItem[key]);
  //     });
  //   });

  //   // Set state based on whether there are changes
  //   setEditiableInput(hasChanges ? 
  //     tableDraftRendering?.map((item) => ({ ...item })) : 
  //     currentData
  //   );
  // }, [tableDraftRendering, currentKpiData, frequency, kpicode, orgdept, orgplt]);

  // console.log("tableDraftRendering", tableDraftRendering);
  // console.log("editableinput", editableInput);


  // In your component or data fetching logic

  // useEffect(() => {
  //   // Initialize table data with default values
  //   const initialData = tableData.map(initializeItemWithDefaults);
  //   setTableData1(initialData);

  //   // Set editable input based on current KPI data or draft rendering
  //   const editableData = currentKpiData
  //     ? currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]
  //     : tableDraftRendering?.map(item => ({ ...item }));

  //   setEditiableInput(editableData);
  // }, [
  //   tableDraftRendering,
  //   currentKpiData,
  //   frequency,
  //   kpicode,
  //   orgdept,
  //   orgplt,
  //   tableData,
  // ]);

  useEffect(() => {
    // Check if any row has input-dropDown(StateAPI) format
    const hasStateDropdownUnfilled = tableData1?.some(row =>
      Object.values(row).some(field =>
        field?.format === "input-dropDown(StateAPI)"
      )
    );

    const hasStateDropdownDraft = tableDraftRendering?.some(row =>
      Object.values(row).some(field =>
        field?.format === "input-dropDown(StateAPI)"
      )
    );

    // Only fetch states if StateAPI dropdown is present
    if (hasStateDropdownUnfilled || hasStateDropdownDraft) {
      fetchStatesAndDistricts();
    }
  }, [tableData1, tableDraftRendering]); // Depend on tableData1 to re-check when data changes

  const fetchStatesAndDistricts = async () => {
    try {
      // setIsLoading(true);
      const response = await getStatesAndDistricts()
      setStatesAndDistricts(response.data);
    } catch (error) {
      console.error('Failed to fetch states and districts:', error);
    } finally {
      // setIsLoading(false);
    }
  }

  const addRow = () => {
    if (status === "unfilled") {
      // const newRow = {};
      // for (const key in tableData1[0]) {
      //   console.log("--->", newRow[key] = tableData1[1][key]);
      //   newRow[key] = tableData1[1][key];
      //   console.log("0000>", newRow[key] = tableData1[1][key]);

      // }
      // setTableData1([...tableData1, newRow]);
      // Use the first table template and initialize with default values
      const newRow = initializeItemWithDefaults(tableData1[1]);
      setTableData1([...tableData1, newRow]);
    } else if (status === "drafted") {
      const newEditableRow = initializeItemWithDefaults(editableInput[1]);;
      // for (const key in editableInput[0]) {
      //   if (key.startsWith("Field_")) {
      //     newEditableRow[key] = { ...editableInput[1][key], value: "" };
      //     console.log("in if", newEditableRow);
      //   } else {
      //     // Copy metadata fields directly from an existing row
      //     newEditableRow[key] = editableInput[1][key];
      //     console.log("in else", newEditableRow);
      //   }
      // }
      setEditiableInput([...editableInput, newEditableRow]);
    }

    setInputValues([...inputValues, {}]);
  };

  // console.log("tabled", tableData1);
  // console.log("editnput", editableInput);



  const deleteRow = (index) => {
    if (status === "unfilled") {
      const updatedData = [...tableData1];
      updatedData.splice(index, 1);
      setTableData1(updatedData);
    } else if (status === "drafted") {
      const updatedDraftData = [...editableInput];
      updatedDraftData.splice(index, 1);

      // Update the No_of_rows_columns field
      const rowsColumns = updatedDraftData[0].No_of_rows_columns.split(" x ");
      const newRowCount = parseInt(rowsColumns[0]) - 1;
      updatedDraftData.forEach((row) => {
        row.No_of_rows_columns = `${newRowCount} x ${rowsColumns[1]}`;
      });

      setEditiableInput(updatedDraftData);
    }

    const updatedInputValues = [...inputValues];
    updatedInputValues.splice(index, 1);
    setInputValues(updatedInputValues);

    const updatedJsonData = [...jsonData];
    updatedJsonData.splice(index, 1);
    setJsonData(updatedJsonData.filter(Boolean));
  };

  const handleChange = async (value, rowIndex, fieldKey) => {
    //add ratio type
    // Create a deep copy of updatedTableData
    let updatedData = JSON.parse(JSON.stringify(tableData1));

    // Update the value property of the corresponding object
    if (updatedData[rowIndex] && updatedData[rowIndex][fieldKey]) {
      updatedData[rowIndex][fieldKey].value = value;

    }
    setTableData1(updatedData);

    const updatedInputValues = [...inputValues];
    updatedInputValues[rowIndex][fieldKey] = value;

    // If changing state, reset corresponding district
    if (updatedData[rowIndex][fieldKey]?.format === "input-dropDown(StateAPI)") {
      const districtKey = Object.keys(updatedData[1]).find(k => {
        return updatedData[1][k]?.format === 'input-dropDown(DistrictAPI)'
      });


      if (districtKey) {
        updatedInputValues[rowIndex][districtKey] = '';
        // Also reset district in updatedData if it exists
        if (updatedData[rowIndex] && updatedData[rowIndex][districtKey]) {
          updatedData[rowIndex][districtKey].value = '';
        }
      }
    }
    setInputValues(updatedInputValues);

    const newRowJson = {};

    Object.keys(tableData1[0]).forEach((key) => {
      newRowJson[key] = tableData1[0][key];
    });

    updatedData[rowIndex] &&
      Object.keys(updatedData[rowIndex]).forEach((key) => {
        if (updatedData[rowIndex][key]) {
          newRowJson[key] = updatedData[rowIndex][key];
        }
      });

    setJsonData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = newRowJson;
      return newData.filter(Boolean);
    });
  };

  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(editableInput));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setEditiableInput(updatedData);
      // // // console.log("editableInput", editableInput);
    }
  };

  const saveFields = () => {

    const updatedJsonData = [...jsonData];
    const isTableData0Present = updatedJsonData.some((obj) =>
      Object.keys(obj).every((key) => obj[key] === tableData[0][key])
    );

    if (!isTableData0Present) {
      updatedJsonData.push(tableData[0]);
    }

    const filteredJsonData = updatedJsonData.filter(
      (obj) => Object.keys(obj).length !== 0
    );
    setJsonData(filteredJsonData);

    const kpi_data = {
      [frequency + "~" + kpicode]: filteredJsonData,
    };

    // Update accumulated data by merging with any existing data for this KPI
    const kpiKey = `${kpicode}-${orgdept}-${orgplt}`;

    // Update Redux state
    dispatch(updatePreservedInput({
      kpiCode: kpiKey,
      period: frequency,
      value: filteredJsonData,
      department: orgdept,
      operationalUnit: orgplt
    }));
    toast.success("Fields saved successfully");
    setKpiinputdata(kpi_data);
  };

  const submitForDraft = () => {

    if (typeof setKpiinputdata === "function") {
      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      const redux_kpi_key = {
        [frequency + "~" + kpicode + "~" + orgdept + "~" + orgplt]: editableInput
      }
      toast.success("Fields saved successfully");
      // Call setKpiinputdata to update state
      dispatch(setAddMoreKpiInputdata(redux_kpi_key));

      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
  };

  const tableHeader = (
    // E3F4F4
    <TableRow style={{ background: "#E3F4F4", borderRadius: "20px" }}>
      {Object.keys(tableData[0])
        .sort()
        .map((key, index) => {
          if (key.startsWith("Field_")) {
            return (
              <TableCell style={{}} key={index}>
                {tableData[0][key]?.format}
              </TableCell>
            );
          }
          return null;
        })}
    </TableRow>
  );

  const tableDraftHeader = (
    // E3F4F4
    <TableRow style={{ background: "#E3F4F4", borderRadius: "20px" }}>
      {editableInput &&
        Object.keys(editableInput[0])
          .sort()
          .map((key, index) => {
            if (key.startsWith("Field_")) {
              return (
                <TableCell style={{}} key={index}>
                  {editableInput[0][key]?.format}
                </TableCell>
              );
            }
            return null;
          })}
    </TableRow>
  );

  const renderCellValueAddmore = (value) => {
    return value.value;
  };

  const handleEditInputChange = (index, key, value, item) => {
    // Deep copy of editableInput
    const newEditableInput = [...editableInput];

    if (
      item[key]?.format?.includes("input-number") ||
      item[key]?.format?.includes("input-decimal") ||
      item[key]?.format?.includes("input-currency") || item[key]?.format?.includes("input-wholeNumber")
    ) {
      if (valnum(value) || value === "") {
        newEditableInput[index] = {
          ...newEditableInput[index],
          [key]: { ...newEditableInput[index][key], value: value },
        };
      } else {
        toast.error("Invalid number format!");
      }
    } // Handle state dropdown
    else if (item[key]?.format?.includes("input-dropDown(StateAPI)")) {
      // Reset corresponding district when state changes
      const districtKey = Object.keys(item).find(k =>
        item[k]?.format?.includes("input-dropDown(DistrictAPI)")
      );

      if (districtKey) {
        newEditableInput[index] = {
          ...newEditableInput[index],
          [key]: { ...newEditableInput[index][key], value: value },
          [districtKey]: {
            ...newEditableInput[index][districtKey],
            value: ''
          }
        };
      } else {
        // If no district key found, just update the state
        newEditableInput[index] = {
          ...newEditableInput[index],
          [key]: { ...newEditableInput[index][key], value: value },
        };
      }
    } else {
      newEditableInput[index] = {
        ...newEditableInput[index],
        [key]: { ...newEditableInput[index][key], value: value },
      };
    }
    // Update state
    setEditiableInput(newEditableInput);
  };


  const renderEditableCellValue = (item, value, index, key) => {
    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }

    if (value?.format?.includes("auto_increment")) {
      const existingValue = item[key]?.value || "";
      return (
        <TextField
          type="text"
          value={existingValue === "Not Filled" ? "" : existingValue || ""}
          placeholder="Enter Text"
          onChange={(e) =>
            handleEditInputChange(index, key, e.target.value, item)
          }
          style={{ width: "200px" }}
        />
      )
    }
    if (value?.format?.includes("input")) {

      const fieldType = value.format.split("-")[1];
      const existingValue = item[key]?.value || "";

      if (fieldType.includes('openText(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];

        return (
          <TextField
            type="text"
            value={existingValue === "Not Filled" ? "" : existingValue || ""}
            placeholder={`Enter Text max(${limit})`}
            onChange={(e) => {
              const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
              handleEditInputChange(index, key, newValue, item)
            }}
            style={{ width: "200px" }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }

      if (fieldType.includes('wholeNumber(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];
        return (
          <TextField
            type="text"
            value={parseInt(existingValue) > 0 ? existingValue : "" || ""}
            placeholder={`Enter Number max(${limit})`}
            onChange={(e) => {
              const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
              handleEditInputChange(index, key, newValue, item);
            }}
            style={{ width: "200px" }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }

      if (value.format.includes("MCQ") || value.format.includes("dropDown")) {
        if (value.format.includes("dropDown(StateAPI)")) {
          const states = statesAndDistricts?.states?.map(item => item.state);

          return (
            // <TableCell key={key}>
            <Autocomplete
              sx={{ width: "200px" }}
              options={states ? states : []}
              value={existingValue === "Not Filled" ? "" : existingValue}
              onChange={(event, newValue) => {
                // Update state value and clear district when state changes
                // handleEditInputChange(index, key, e.target.value, item);
                const defaultValue = newValue.trim() === "" ? "Not Filled" : newValue;
                handleEditInputChange(index, key, defaultValue, item)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select State"
                />
              )}
            />
            // </TableCell>
          );
        }

        if (value.format.includes("input-dropDown(DistrictAPI)")) {
          // Find the selected state
          const stateKey = Object.keys(item).find(k =>
            item[k].format === "input-dropDown(StateAPI)"
          )
          const selectedState = stateKey ? item[stateKey]?.value === "Not Filled" ? "" : item[stateKey]?.value : '';

          // Get districts for selected state
          const districts = selectedState
            ? (statesAndDistricts?.states?.find(s => s.state === selectedState)?.districts || [])
            : [];

          return (
            // <TableCell key={key}>
            <Autocomplete
              sx={{ width: "200px" }}
              options={districts}
              value={existingValue === "Not Filled" ? "" : existingValue}
              onChange={(event, newValue) => {
                const defaultValue = newValue.trim() === "" ? "Not Filled" : newValue;
                handleEditInputChange(index, key, defaultValue, item);
              }}
              disabled={!selectedState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={selectedState ? "Select District" : "Select State first"}
                  disabled={!selectedState}
                />
              )}
            />
            // </TableCell>
          );
        }
        const options = InputRenderers.extractOptions(fieldType);
        return (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Option"
              // sx={{ width: '100px' }}
              value={existingValue === "Not Filled" ? "" : existingValue || ""}
              onChange={(e) => handleEditInputChange(index, key, e.target.value, item)}
            >
              <MenuItem value="Not Filled">Select Option</MenuItem>
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue === "Not Filled" ? "" : existingValue || ""}
            onChange={(e) =>
              handleEditInputChange(index, key, e.target.value, item)
            }
            style={{ width: "200px" }}
          >
            <option value="Not Filled">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : "" || ""}
                placeholder="Enter Number"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                  handleEditInputChange(index, key, newValue, item);
                }}
                style={{ width: "200px" }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : "" || ""}
                placeholder="Enter Percent"
                onChange={(e) => {
                  const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
                  const isValid = regex.test(e.target.value)
                  if (isValid) {
                    const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                    handleEditInputChange(index, key, newValue, item)
                  } else {
                    toast.error("Invalid percent format!")
                  }
                }}
                style={{ width: "200px" }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter URL"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const newValue = inputValue.trim() === "" ? "Not Filled" : inputValue;
                  handleEditInputChange(index, key, newValue, item);

                  // Validating URL only after the user has finished typing or is close to a valid format
                  const trimmedValue = inputValue.trim();
                  if (trimmedValue && !/^(ftp|http|https):\/\/[^ "]+$/.test(trimmedValue)) {
                    const validationToastId = 'invalid-url-format';
                    if (!toast.isActive(validationToastId)) {
                      toast.error("Invalid URL format!", {
                        toastId: validationToastId
                      });
                    }
                  }
                }}
                style={{ width: "200px" }}
              />
            )
          case 'DatePicker':
            return (
              <div style={{ width: "200px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={getInitialValue(value)}
                    onChange={(value) => {
                      if (value && value instanceof Date && !isNaN(value)) {
                        const day = value.getDate().toString().padStart(2, '0');
                        const month = (value.getMonth() + 1).toString().padStart(2, '0');
                        const year = value.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;
                        const newValue = formattedDate.trim() === "" ? "Not Filled" : formattedDate;
                        handleEditInputChange(index, key, newValue, item);
                      } else {
                        handleEditInputChange(index, key, null, item);
                      }
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
            );
          case "eMail":
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Email"
                onChange={(e) => {
                  const isValid = /^[a-zA-Z0-9._@]*$/.test(e.target.value);
                  if (isValid) {
                    const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                    handleEditInputChange(index, key, newValue, item);
                  } else {
                    const validationToastId = 'invalid-email-toast';
                    if (!toast.isActive(validationToastId)) {
                      toast.error("Invalid Email format!", {
                        toastId: validationToastId,
                      });
                    }
                  }
                }}
                style={{ width: "200px" }}
              />
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Text"
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                  handleEditInputChange(index, key, newValue, item)
                }}
                style={{ width: "200px" }}
              />
            );
        }
      }
    }


    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue === "Not Filled" ? "" : existingValue || ""}
          onChange={(e) => {
            validateNumber(e.target.value, handleEditInputChange, key, index);
          }}
          placeholder="Enter Number"
          style={{ width: "200px" }}
        />
      );
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.value || value?.format || null;
      }
    }
  };

  function valnum(value) {
    // Validate number: maximum 13 places and two decimal places
    const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,2})?))$/;
    return regex.test(value);
  }

  const validateNumber = (value, handleChange, rowIndex, key) => {

    // const regex = /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
    // const isValid = regex.test(value);
    if (valnum(value)) {
      handleChange(value, rowIndex, key);
    } else {
      toast.error("Invalid number format!");
    }
    // return isValid;
  };

  function validateWholeNumber(newValue, handleChange, rowIndex, key) {
    // Check if the entered value is a positive whole number
    const isWholeNumber = /^[0-9]\d*$/.test(newValue);

    // If it's a positive whole number, call the handleChange function to update the state
    if (isWholeNumber || newValue === "") {
      handleChange(newValue, rowIndex, key);
    } else {
      const validationToastId = 'invalid-character-toast';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid WholeNumber format!", {
          toastId: validationToastId,
        });
      }
    }
  }

  const validateURL = (value, handleChange, rowIndex, key) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (isValid) {
      handleChange(value, rowIndex, key);
    } else {
      const validationToastId = 'invalid-url-format';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid URL format!", {
          toastId: validationToastId
        })
      }
    }
    return isValid;
  };

  const validatePercent = (value, handleChange, rowIndex, key) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    const isValid = regex.test(value);
    if (isValid) {
      handleChange(value, rowIndex, key);
    } else {
      const validationToastId = 'invalid-percent-format';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid percent format!", {
          toastId: validationToastId
        })
      }
    }
    return isValid;
  };


  const validateEmail = (newValue, handleChange, rowIndex, key) => {
    const emailRegex = /^[a-zA-Z0-9._@]*$/;
    // If it's a valid, call the handleChange function to update the state
    if (emailRegex.test(newValue) || newValue === "") {
      handleChange(newValue, rowIndex, key);
    } else {
      const validationToastId = 'invalid-email-toast';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid Email format!", {
          toastId: validationToastId,
        });
      }
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };

    // // // console.log("determineFrequency", determineFrequency());

    return [
      {
        KPICode: kpicode,
        KPIInput: editableInput,
        format: setTableFromat,
        draftStatus: draftStatus,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetAddMoreKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleClick = () => {
    setLoading(true);

    try {
      const returnValue = handleSubmit(
        dispatch,
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      dispatch,
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      setIsSavingDraft,
      [],
      "",
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };
  const role = getUserRole();

  const handleCommentBoxOpen = (detail) => {
    setCommentBoxOpen(true);
  };
  const handleCommentBoxClose = () => {
    setCommentBoxOpen(false);
  };

  // Custom ListBox component using react-window's FixedSizeList
  const VirtualizedListbox = React.forwardRef(function VirtualizedListbox(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemSize = 40;
    const maxItems = 8;

    const renderRow = React.useCallback(
      ({ data, index, style }) => {
        return <div style={style}>{data[index]}</div>;
      },
      [],
    );

    return (
      <div ref={ref}>
        <FixedSizeList
          height={Math.min(itemSize * maxItems, itemData.length * itemSize)}
          width="100%"
          itemSize={itemSize}
          itemCount={itemData.length}
          overscanCount={5}
          itemData={itemData}
          {...other}
        >
          {renderRow}
        </FixedSizeList>
      </div>
    );
  });

  // Optimized State Dropdown Component
  const StateDropdown = ({
    states,
    value,
    onChange,
    rowIndex,
    columnKey
  }) => {
    return (
      <Autocomplete
        sx={{ width: "200px" }}
        options={states || []}
        value={value || ''}
        onChange={(event, newValue) => onChange(newValue, rowIndex, columnKey)}
        ListboxComponent={VirtualizedListbox}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select State"
          // size="small"
          />
        )}
        // Improving performance by limiting unnecessary filtering
        filterOptions={(options, { inputValue }) => {
          const filtered = options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          );
          return filtered.slice(0, 100); // Limit to first 100 matches
        }}
      />
    );
  };

  // Optimized District Dropdown Component
  const DistrictDropdown = ({
    districts,
    value,
    onChange,
    rowIndex,
    columnKey,
    selectedState
  }) => {
    return (
      <Autocomplete
        sx={{ width: "200px" }}
        options={districts}
        value={value || ''}
        onChange={(event, newValue) => onChange(newValue, rowIndex, columnKey)}
        disabled={!selectedState}
        ListboxComponent={VirtualizedListbox}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectedState ? "Select District" : "Select State first"}
            // size="small"
            disabled={!selectedState}
          />
        )}
        // Improving performance by limiting unnecessary filtering
        filterOptions={(options, { inputValue }) => {
          const filtered = options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          );
          return filtered.slice(0, 100); // Limit to first 100 matches
        }}
      />
    );
  };

  const StateDistrictCell = ({
    inputType,
    statesAndDistricts,
    inputValues,
    handleChange,
    rowIndex,
    columnKey,
    row
  }) => {
    if (inputType?.includes("input-dropDown(StateAPI)")) {

      const states = statesAndDistricts?.states?.map(item => item.state) || []

      return (
        <TableCell key={columnKey}>
          <StateDropdown
            states={states}
            value={inputValues[rowIndex][columnKey]}
            onChange={handleChange}
            rowIndex={rowIndex}
            columnKey={columnKey}
          />
        </TableCell>
      );
    }

    if (inputType?.includes("input-dropDown(DistrictAPI)")) {
      const stateKey = Object.keys(row).find(k =>
        row[k].format === "input-dropDown(StateAPI)"
      );
      const selectedState = stateKey ? inputValues[rowIndex][stateKey] : '';

      const districts = selectedState
        ? (statesAndDistricts.states.find(s => s.state === selectedState)?.districts || [])
        : []

      return (
        <TableCell key={columnKey}>
          <DistrictDropdown
            districts={districts}
            value={inputValues[rowIndex][columnKey]}
            onChange={handleChange}
            rowIndex={rowIndex}
            columnKey={columnKey}
            selectedState={selectedState}
          />
        </TableCell>
      );
    }

    return null;
  };

  const memoizedStateOptions = useMemo(() => {
    return statesAndDistricts?.states?.map(item => item.state) || [];
  }, [statesAndDistricts]);

  // Input Renderer Utility
  const InputRenderers = {
    // Utility to extract options from format string
    extractOptions: (format) => {
      const optionsMatch = format.match(/\(([^)]+)\)/);
      return optionsMatch ? optionsMatch[1].split('|') : [];
    },

    // Generic input type handlers
    renderDropdown: (
      inputType,
      value,
      onChange,
      extraProps
    ) => {
      const options = InputRenderers.extractOptions(inputType);

      return (
        <select
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "200px" }}
          {...extraProps}
        >
          <option value="">Select Option</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    },

    renderStateDropdown: (
      value,
      onChange,
      stateOptions
    ) => {
      return <Autocomplete
        sx={{ width: "200px" }}
        options={stateOptions}
        value={value || ''}
        onChange={(event, newValue) => onChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select State"
          // size="small"
          />
        )}
      />
    },

    renderDistrictDropdown: (
      value,
      onChange,
      selectedState,
      statesAndDistricts
    ) => {
      const districts = selectedState
        ? (statesAndDistricts?.states?.find(s => s.state === selectedState)?.districts || [])
        : [];

      return (
        <Autocomplete
          sx={{ width: "200px" }}
          options={districts}
          value={value || ''}
          onChange={(event, newValue) => onChange(newValue)}
          disabled={!selectedState}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectedState ? "Select District" : "Select State first"}
              // size="small"
              disabled={!selectedState}
            />
          )}
        />
      );
    },

    renderWholeNumberInput: (
      value,
      onChange,
      inputType
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={parseInt(value) > 0 ? value : "" || ""}
          placeholder={`Enter Number (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "0" : value;
            validateWholeNumber(
              newValue,
              onChange,
            );
          }}
          inputProps={{
            maxLength: limit,
          }}
          style={{ width: "200px" }}
        />
      );
    },

    renderOpenTextInput: (
      value,
      onChange,
      inputType
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={value === "Not Filled" ? "" : value || ""}
          placeholder={`Enter Open Text (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "Not Filled" : value;
            onChange(newValue)
          }}
          inputProps={{
            maxLength: limit,
          }}
          style={{ width: "200px" }}
        />
      );
    },

    // Main input renderer
    renderInput: (
      inputType,
      value,
      onChange,
      additionalProps
    ) => {
      // Specific format-based renderers
      if (inputType.includes('input-dropDown(StateAPI)')) {
        return InputRenderers.renderStateDropdown(
          value,
          onChange,
          additionalProps.stateOptions
        );
      }

      if (inputType.includes('input-dropDown(DistrictAPI)')) {
        return InputRenderers.renderDistrictDropdown(
          value,
          onChange,
          additionalProps.selectedState,
          additionalProps.statesAndDistricts
        );
      }

      if (inputType.includes('input-dropDown(') || inputType.includes('input-Binary(')) {
        return InputRenderers.renderDropdown(inputType, value, onChange);
      }


      if (inputType.includes('wholeNumber(')) {
        return InputRenderers.renderWholeNumberInput(value, onChange, inputType);
      }

      if (inputType.includes('openText(')) {
        return InputRenderers.renderOpenTextInput(value, onChange, inputType);
      }

      // Default type-based renderers
      switch (inputType) {
        case 'input-openText':
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
              style={{ width: "200px" }}
              inputProps={{ maxLength: 500 }}
            />
          );

        case 'input-wholeNumber':
          return (
            <TextField
              type="number"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateWholeNumber(
                  newValue,
                  onChange
                );
              }}
              placeholder="Enter Number"
              style={{ width: "200px" }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
            />
          );

        case 'input-percent':
          return (
            <TextField
              type="percent"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validatePercent(
                  newValue,
                  onChange
                );
              }}
              placeholder="Enter Percentage"
              style={{ width: "200px" }}
            />
          );

        case 'input-decimal':
        case 'input-number':
          return (
            <TextField
              type="number"
              step="any"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateNumber(
                  newValue,
                  onChange
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
              placeholder="Decimal"
              style={{ width: "200px" }}
            />
          );

        case 'input-currency':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="number"
                step="any"
                value={parseInt(value) > 0 ? value : "" || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  const newValue = value.trim() === "" ? "0" : value;
                  validateNumber(
                    newValue,
                    onChange
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
                placeholder="Decimal"
                style={{ width: "200px", marginRight: '8px' }}
              />
              <span>{getCountryCurrency()}</span>
            </div>
          );

        case 'input-url':
          return (
            <TextField
              type="text"
              placeholder="Enter URL"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                validateURL(
                  newValue,
                  onChange
                );
              }}
              style={{ width: "200px" }}
            />
          );

        case 'input-Binary':
          return InputRenderers.renderDropdown(
            'input-dropDown(Yes|No)',
            value,
            onChange
          );

        case 'input-DatePicker':
          return (
            <div style={{ width: "200px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={value ? (() => {
                    const [day, month, year] = value.split('/');
                    return new Date(year, month - 1, day);
                  })() : null}
                  onChange={(value) => {
                    if (value && value instanceof Date && !isNaN(value)) {
                      const day = value.getDate().toString().padStart(2, '0');
                      const month = (value.getMonth() + 1).toString().padStart(2, '0');
                      const year = value.getFullYear();
                      const formattedDate = `${day}/${month}/${year}`;
                      onChange(formattedDate);
                    } else {
                      onChange(null);
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
          );
        case 'input-eMail':
          return (
            <TextField
              style={{ width: "200px" }}
              variant="outlined"
              type="email"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Fiiled" : value;
                validateEmail(
                  newValue,
                  onChange
                );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              placeholder="Enter Email"
            />
          );
        default:
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
              style={{ width: "200px" }}
            />
          );
      }
    }
  };

  const renderDynamicInput = (
    row,
    rowIndex,
    key,
    inputValues,
    handleChange,
    additionalProps = {}
  ) => {
    const inputType = row[key]?.format;

    if (
      key.startsWith("Field_") &&
      (inputType?.startsWith("input") || inputType?.startsWith("auto"))
    ) {
      return (
        <TableCell key={key}>
          {InputRenderers.renderInput(
            inputType,
            inputValues[rowIndex][key],
            (value) => handleChange(value, rowIndex, key),
            additionalProps
          )}
        </TableCell>
      );
    }

    return null;
  };


  return (
    <>
      {getUserRole() === "User" &&
        <div style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: 'end',
          marginRight: "20px"
        }}>

          {/* {detail.Description && ( */}
          <Tooltip title="Guide">
            <IconButton
              // disabled={detail.Description === undefined}

              className="thumbdown"
            // onClick={() =>
            //   handleOpenDecsModal(detail, i)
            // }
            >
              <MenuBookIcon style={{ color: "rgb(69, 182, 83)" }}
              />{" "}
            </IconButton>
          </Tooltip>
          {/* )}{" "} */}
          <Tooltip title="Comment">
            <span>
              <IconButton
                className="thumbdown"
                onClick={() => handleCommentBoxOpen()}
                disabled={(status ===
                  "approved") || (status ===
                    "unfilled") || (status ===
                      "saved") || (reviewedBy === undefined)}
              >
                <CommentIcon style={{ color: "#3079bd" }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
      <TableContainer
        sx={{
          overflowX: "auto",
          // width: status === "drafted" ? "2500px" : "auto",
        }}
      >
        {(status === "unfilled" ||
          (status === "drafted" && role !== "SPOC")) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "10px",
                gap: "12px",
              }}
            >
              <BsInfoCircle style={{ color: "red", fontWeight: "600" }} />
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Please Save the Table Details Before Submitting
              </Typography>
            </Box>
          )}
        <Table style={{ overflow: "scroll" }}>
          {status === "unfilled" && tableHeader}
          {status === "drafted" && tableDraftHeader}
          <TableBody>
            {status === "unfilled" &&
              tableData1?.sort().map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row)
                    .sort()
                    .map((key, index) => {
                      const stateKey = Object.keys(row).find(k =>
                        row[k].format === "input-dropDown(StateAPI)"
                      );
                      const selectedState = stateKey ? inputValues[rowIndex][stateKey] : '';
                      return renderDynamicInput(
                        row,
                        rowIndex,
                        key,
                        inputValues,
                        handleChange,
                        {
                          stateOptions: memoizedStateOptions,
                          selectedState: selectedState,
                          statesAndDistricts: statesAndDistricts
                        }
                      )
                      //TODO: below commented code can be removed if above code works properly.
                      // if (
                      //   key.startsWith("Field_") &&
                      //   (row[key]?.format?.startsWith("input") || row[key]?.format?.startsWith("auto"))
                      // ) {
                      //   //input-feildType
                      //   //input
                      //   // Extract the input type from the row[key] value
                      //   const inputType = row[key]?.format; // Extracts input type from "input-dropDown(Male|Female|Prefer Not to Say)"

                      //   if (inputType && (inputType.includes("input-MCQ") || inputType.includes("input-dropDown"))) {
                      //     if (inputType.includes("input-dropDown(StateAPI)")) {
                      //       return (
                      //         <TableCell key={key}>
                      //           <Autocomplete
                      //             sx={{ width: "200px" }}
                      //             options={memoizedStateOptions}
                      //             value={inputValues[rowIndex][key] || ''}
                      //             onChange={(event, newValue) => {
                      //               handleChange(newValue, rowIndex, key);
                      //             }}
                      //             renderInput={(params) => (
                      //               <TextField
                      //                 {...params}
                      //                 placeholder="Select State"
                      //                 size="small"
                      //               />
                      //             )}
                      //           />
                      //         </TableCell>
                      //       );
                      //     }

                      //     if (inputType.includes("input-dropDown(DistrictAPI)")) {
                      //       // Find the selected state
                      //       const stateKey = Object.keys(row).find(k =>
                      //         row[k].format === "input-dropDown(StateAPI)"
                      //       );
                      //       const selectedState = stateKey ? inputValues[rowIndex][stateKey] : '';

                      //       // Get districts for selected state
                      //       const districts = selectedState
                      //         ? (statesAndDistricts.states.find(s => s.state === selectedState)?.districts || [])
                      //         : [];

                      //       return (
                      //         <TableCell key={key}>
                      //           <Autocomplete
                      //             sx={{ width: "200px" }}
                      //             options={districts}
                      //             value={inputValues[rowIndex][key] || ''}
                      //             onChange={(event, newValue) => {
                      //               handleChange(newValue, rowIndex, key);
                      //             }}
                      //             disabled={!selectedState}
                      //             renderInput={(params) => (
                      //               <TextField
                      //                 {...params}
                      //                 placeholder={selectedState ? "Select District" : "Select State first"}
                      //                 size="small"
                      //                 disabled={!selectedState}
                      //               />
                      //             )}
                      //           />
                      //         </TableCell>
                      //       );
                      //     }

                      //     const options = row[key]?.format
                      //       .match(/\(([^)]+)\)/)[1]
                      //       .split("|"); // Extracts options from "input-dropDown(Male|Female|Prefer Not to Say)"

                      //     return (
                      //       <TableCell key={index}>
                      //         <select
                      //           value={inputValues[rowIndex][key] || ""}
                      //           onChange={(e) =>
                      //             handleChange(e.target.value, rowIndex, key)
                      //           }
                      //           style={{ width: "200px" }}
                      //         >
                      //           <option value="">Select Option</option>
                      //           {options.map((option, idx) => (
                      //             <option key={idx} value={option}>
                      //               {option}
                      //             </option>
                      //           ))}
                      //         </select>
                      //       </TableCell>
                      //     );
                      //   } else if (inputType.includes("wholeNumber(")) {
                      //     const limit = row[key]?.format.match(/\(([^)]+)\)/)[1];

                      //     return (
                      //       <TableCell key={index}>
                      //         <TextField
                      //           type="text"
                      //           value={inputValues[rowIndex][key] || ""}
                      //           placeholder="Enter Number 5"
                      //           onChange={(e) => {
                      //             const { value } = e.target;

                      //             // if (value === '' || (parseInt(value) <= limit && value.length <= limit)) {
                      //             console.log("val", value);

                      //             validateWholeNumber(
                      //               value,
                      //               handleChange,
                      //               rowIndex,
                      //               key
                      //             );
                      //             // }
                      //           }}
                      //           inputProps={{
                      //             maxLength: limit,
                      //           }}
                      //           style={{ width: "200px" }}
                      //         />
                      //       </TableCell>
                      //     );
                      //   } else {
                      //     // Render input element based on input type [format]
                      //     switch (inputType) {
                      //       case "input-openText":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="text"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) =>
                      //                 handleChange(
                      //                   e.target.value,
                      //                   rowIndex,
                      //                   key
                      //                 )
                      //               }
                      //               placeholder="open-text"
                      //               style={{ width: "200px" }}
                      //               inputProps={{ maxLength: 500 }}
                      //             />
                      //           </TableCell>
                      //         );
                      //       case "input-wholeNumber":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="number"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) => {
                      //                 const { value } = e.target;
                      //                 validateWholeNumber(
                      //                   value,
                      //                   handleChange,
                      //                   rowIndex,
                      //                   key
                      //                 );
                      //               }}
                      //               placeholder="Enter Number"
                      //               style={{ width: "200px" }}
                      //               inputProps={{ maxLength: 5 }}
                      //             />
                      //           </TableCell>
                      //         );
                      //       case "input-percent":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="percent"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) => {
                      //                 const { value } = e.target;
                      //                 validatePercent(
                      //                   value,
                      //                   handleChange,
                      //                   rowIndex,
                      //                   key
                      //                 );
                      //               }}
                      //               placeholder="Enter Percentage"
                      //               style={{ width: "200px" }}
                      //             />
                      //           </TableCell>
                      //         );
                      //       case "input-decimal":
                      //       case "input-number":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="number"
                      //               step="any"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) => {
                      //                 const { value } = e.target;
                      //                 validateNumber(
                      //                   value,
                      //                   handleChange,
                      //                   rowIndex,
                      //                   key
                      //                 );
                      //               }}
                      //               placeholder="decimal"
                      //               style={{ width: "200px" }}
                      //             />
                      //           </TableCell>
                      //         );
                      //       case "input-currency":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="number"
                      //               step="any"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) => {
                      //                 const { value } = e.target;
                      //                 validateNumber(
                      //                   value,
                      //                   handleChange,
                      //                   rowIndex,
                      //                   key
                      //                 );
                      //               }}
                      //               placeholder="decimal"
                      //               style={{ width: "200px" }}
                      //             />
                      //             <span>
                      //               {localStorage.getItem("countryCurrency")}{" "}
                      //             </span>
                      //           </TableCell>
                      //         );
                      //       case "input-url":
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="text"
                      //               placeholder="Enter URL"
                      //               onChange={(e) => {
                      //                 const { value } = e.target;
                      //                 validateURL(
                      //                   value,
                      //                   handleChange,
                      //                   rowIndex,
                      //                   key
                      //                 );
                      //               }}
                      //               style={{ width: "200px" }}
                      //             />
                      //           </TableCell>
                      //         );
                      //       case "input-Binary":
                      //         return (
                      //           <TableCell key={index}>
                      //             <select
                      //               onChange={(e) =>
                      //                 handleChange(
                      //                   e.target.value,
                      //                   rowIndex,
                      //                   key
                      //                 )
                      //               }
                      //               style={{ width: "200px" }}
                      //             >
                      //               <option value="">Select Option</option>
                      //               <option value="Yes">Yes</option>
                      //               <option value="No">No</option>
                      //             </select>
                      //           </TableCell>
                      //         );
                      //       case "input-DatePicker":
                      //         return (
                      //           <TableCell key={index}>
                      //             <div style={{ width: "200px" }}>
                      //               <LocalizationProvider dateAdapter={AdapterDateFns}>
                      //                 <DatePicker
                      //                   value={inputValues[rowIndex][key] ?
                      //                     // Parse the existing date string if it exists (dd/mm/yyyy)
                      //                     inputValues[rowIndex][key].includes('/') ?
                      //                       (() => {
                      //                         const [day, month, year] = inputValues[rowIndex][key].split('/');
                      //                         return new Date(year, month - 1, day);
                      //                       })()
                      //                       : null
                      //                     : null
                      //                   }
                      //                   onChange={(value) => {
                      //                     if (value && value instanceof Date && !isNaN(value)) {
                      //                       // Format the date string as dd/mm/yyyy
                      //                       const day = value.getDate().toString().padStart(2, '0');
                      //                       const month = (value.getMonth() + 1).toString().padStart(2, '0');
                      //                       const year = value.getFullYear();
                      //                       const formattedDate = `${day}/${month}/${year}`;
                      //                       handleChange(formattedDate, rowIndex, key);
                      //                     } else {
                      //                       handleChange(null, rowIndex, key);
                      //                     }
                      //                   }}
                      //                   inputFormat="dd/MM/yyyy"  // This ensures the display format
                      //                   renderInput={(params) => (
                      //                     <TextField {...params} fullWidth size="small" />
                      //                   )}
                      //                 />
                      //               </LocalizationProvider>
                      //             </div>
                      //           </TableCell>
                      //         );
                      //       default:
                      //         return (
                      //           <TableCell key={index}>
                      //             <TextField
                      //               type="text"
                      //               value={inputValues[rowIndex][key] || ""}
                      //               onChange={(e) =>
                      //                 handleChange(
                      //                   e.target.value,
                      //                   rowIndex,
                      //                   key
                      //                 )
                      //               }
                      //               placeholder="open-text"
                      //               style={{ width: "200px" }}
                      //             />
                      //           </TableCell>
                      //         );
                      //     }
                      //   }
                      // }
                    })}
                  {rowIndex > 1 && (
                    <TableCell>
                      <Button
                        onClick={() => deleteRow(rowIndex)}
                        style={userdeletebutton}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}

            {(status === "saved" || status === "approved" || (status === "drafted" && isreviewed === "yes")) &&
              tableRendering?.sort().map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  style={
                    rowIndex === 0
                      ? {
                        background: "#E3F4F4",
                        //E3F4F4
                      }
                      : {}
                  }
                >
                  {Object.keys(row)
                    .sort()
                    .map((key, index) => {
                      if (key.startsWith("Field_")) {
                        return (
                          <TableCell
                            key={index}
                          // style={rowIndex === 0 ? { color: "#fff" } : {}}
                          >
                            {renderCellValueAddmore(row[key])}
                          </TableCell>
                        );
                      }
                      // else {
                      //   // Render input element based on input type [format]
                      //   const inputType = row[key]?.format;
                      //   switch (inputType) {
                      //     case "input-openText":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             placeholder="open-text"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-wholeNumber":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateWholeNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="number"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-percent":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="percent"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validatePercent(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="Enter Percentage"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-decimal":
                      //     case "input-number":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             step="any"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="decimal"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-currency":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="number"
                      //             step="any"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateNumber(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             placeholder="decimal"
                      //             style={{ width: "200px" }}
                      //           />
                      //           <span>{getCountryCurrency()} </span>
                      //         </TableCell>
                      //       );
                      //     case "input-url":
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             placeholder="Enter URL"
                      //             onChange={(e) => {
                      //               const { value } = e.target;
                      //               validateURL(
                      //                 value,
                      //                 handleChange,
                      //                 rowIndex,
                      //                 key
                      //               );
                      //             }}
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //     case "input-Binary":
                      //       return (
                      //         <TableCell key={index}>
                      //           <select
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             style={{ width: "200px" }}
                      //           >
                      //             <option value="">Select Option</option>
                      //             <option value="Yes">Yes</option>
                      //             <option value="No">No</option>
                      //           </select>
                      //         </TableCell>
                      //       );
                      //     default:
                      //       return (
                      //         <TableCell key={index}>
                      //           <TextField
                      //             type="text"
                      //             value={inputValues[rowIndex][key] || ""}
                      //             onChange={(e) =>
                      //               handleChange(e.target.value, rowIndex, key)
                      //             }
                      //             placeholder="open-text"
                      //             style={{ width: "200px" }}
                      //           />
                      //         </TableCell>
                      //       );
                      //   }
                      // }
                    })}
                </TableRow>
              ))}

            {status === "drafted" &&
              editableInput?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(item)
                      // .filter((keyFilter) => keyFilter.startsWith("Field_"))
                      .sort()
                      .map((keyMap, keyIndex) => {
                        if (
                          keyMap.startsWith("Field_") &&
                          (item[keyMap]?.format?.startsWith("input") || item[keyMap]?.format?.startsWith("auto"))
                        ) {
                          return (
                            <TableCell
                              key={keyIndex}
                              style={{
                                wordBreak: "break-word",
                                // backgroundColor: keyIndex === 0 ? "#E3F4F4" : "",
                                padding: "8px 12px",
                              }}
                            >
                              {renderEditableCellValue(
                                item,
                                item[keyMap],
                                index,
                                keyMap
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    {index > 1 && (
                      <TableCell>
                        <Button
                          onClick={() => deleteRow(index)}
                          style={userdeletebutton}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>

          {(status === "unfilled" || (status === "drafted" && role !== "SPOC")) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={addRow}
                sx={{ textTransform: "none" }}
                style={{
                  margin: "10px",
                  width: "125px",
                  border: "1px solid #3079bd",
                  borderRadius: "15px",
                  color: "#fff",
                  backgroundColor: "#3079bd",
                }}
              >
                Add Row
              </Button>

              <Button
                onClick={status === "drafted" ? submitForDraft : saveFields}
                // disabled={status==="unfilled"?!isDirty:!isDraftDirty}
                sx={{ textTransform: "none" }}
                style={{
                  border: "1px solid #45b653",
                  background: "#45b653",
                  borderRadius: "15px",
                  color: "white",
                  padding: "5px 35px",
                }}
              >
                Save
              </Button>
            </div>
          )}
        </Table>
      </TableContainer>
      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved" || status === "approved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved" || status === "approved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
      {commentBoxOpen &&
        <CommentPopUp
          open={commentBoxOpen}
          handleClose={handleCommentBoxClose}
          // onSubmitComment={handleAddComment}
          // submittedComments={submittedComments}
          // draftComment={draftComment}
          // setDraftComment={setDraftComment}
          // onDeleteComment={handleDeleteComment}
          // onEditComment={handleEditComment}
          reviewedBy={reviewedBy}
          commentsArray={comments}
        />}
    </>
  );
};
