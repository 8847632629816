import { Tooltip } from "@mui/material"
const colors = [
    "#222831", "#17153B", "#A0153E", "#344955", "#363062",
    "#750E21", "#005B41", "#352F44", "#1F6E8C", "#0E8388",
    "#404258", "#472D2D", "#750550", "#1E5128", "#B85C38",
    "#222831", "#519872", "#C70039", "#363062","#382039","#00454A"
  ];
  
  function stringToHash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
  }

  function getColorForInitials(initials) {
      const hash = stringToHash(initials);
      const index = Math.abs(hash) % colors.length;
      return colors[index];
  }

export const Profile=({name,email})=>{
    const initials = name.split(" ").map(n => n[0]).join("");
    const backgroundColor = getColorForInitials(initials);
    return(
        <>
        <Tooltip title={email}>
         <div
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              background: backgroundColor,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              textTransform: "uppercase",
              cursor:"pointer"
            }}
          >
            {name[0]}
          </div>
        </div>
        </Tooltip>
        </>
    )
}