import React, { useState } from "react";
import SignInForm from "../../components/SignIn/SignIn";
import About from "../../components/About/About";
import { Box, Button } from "@mui/material";
import { buttonoutlined } from "../../styles";
import "./Login.css";

export default function Login() {
  const [type, setType] = useState("About");
  const handleOnClick = (text) => {
    if (text !== type) {
      setType(text);
      return;
    }
  };

  const containerClass =
    "container " + (type === "About" ? "right-panel-active" : "");
  return (
    <Box className="Login">
      <div className={containerClass} id="container">
        <About />
        <SignInForm />
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <div className="div-for-i">
                <img
                  src="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
                  className="logo-i"
                  alt="envint"
                />
              </div>
              <h1>EnvSaGe</h1>
              <p className="h1">The digital ESG pathway</p>
              <br />

              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOnClick("signIn")}
                style={buttonoutlined}
              >
                Sign In
              </Button>
            </div>
            <div className="overlay-panel overlay-right">
              <div className="div-for-i">
                <img
                  src="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
                  className="logo-i"
                  alt="envint"
                />
              </div>
              <h1>EnvSaGe</h1>
              <p className="h1">The digital ESG pathway</p>
              <Button
                variant="outlined"
                onClick={() => handleOnClick("About")}
                style={buttonoutlined}
              >
                About
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
