import axios from "axios";
import { baseUrl } from "../baseurl";
import { getOrg } from "./loggedIn";

const organizationName = getOrg();

export const getAllManagersAndConsultant = () => {
  return axios.post(
    `${baseUrl}/getAllManagersAndConsultant`,
    { organizationName },
    { withCredentials: true }
  );
};

export const getCountryCodes = () => {
  return axios.get(`${baseUrl}/countryCodes`, {
    withCredentials: true,
  });
};

export const checkOrganization = (data) => {
  return axios.post(`${baseUrl}/check-organization`, data, {
    withCredentials: true,
  });
};

export const tokenValidate = () => {
  return axios.get(`${baseUrl}/tokenValidate`, {
    withCredentials: true,
  });
};
