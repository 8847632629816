// Function to filter organizations
export async function filterOrganizationsForUser(
  role,
  email,
  orgData,
  userData
) {
  // // console.log("orgData", orgData);
  // // console.log("userData", userData);
  // // console.log("role", role);
  // // console.log("email", email);

  // Check if userData exists and has the Manager key
  if (!userData || !userData.Manager) {
    console.error(
      "User data is not in the expected format or no managers found"
    );
    return [];
  }

  if (!userData || !userData.Consultant) {
    console.error(
      "User data is not in the expected format or no consultant found"
    );
  }

  if (role === "Manager") {
    // Find the manager in the users data
    const manager = userData.Manager.find((user) => user.email === email);

    if (!manager) {
      // // console.log("Manager not found");
      return []; // If manager not found, return empty array
    }

    // Get the list of organizations the manager is assigned to
    const managerProjects = Array.isArray(manager.projects)
      ? manager.projects.map((project) => project.orgEmail)
      : [];

    // // console.log("managerProjects", managerProjects);

    // Filter the organization data
    return orgData.filter((user) =>
      managerProjects.includes(
        user.organizationEmail || user.organizationName || user.organization
      )
    );
  } else {
    // Find the consultant in the users data
    const consultant = userData.Consultant.find((user) => user.email === email);

    if (!consultant) {
      return []; // If consultant not found, return empty array
    }

    // Get the list of organizations the consultant is assigned to
    const consultantProjects = Array.isArray(consultant.projects)
      ? consultant.projects.map((project) => project.orgEmail)
      : [];

    // // console.log("consultantProjects", consultantProjects);

    // Filter the organization data
    return orgData.filter((user) =>
      consultantProjects.includes(
        user.organizationEmail || user.organizationName || user.organization
      )
    );
  }
}

export const getJoyrideSteps = (userRole, steps) => {
  let filteredSteps = steps;

  if (userRole === "Consultant") {
    filteredSteps = steps.filter(
      (step) =>
        step.target !== ".editOrg" &&
        step.target !== ".deleteOrg" &&
        step.target !== ".add-org-btn"
    );
  }

  if (userRole === "Manager") {
    filteredSteps = steps.filter(
      (step) => step.target !== ".deleteOrg"
    );
  }

  // Reset disableBeacon for the first step after filtering
  if (filteredSteps.length > 0) {
    filteredSteps[0].disableBeacon = true;
  }

  return filteredSteps;
};

// Constants for input types
const NUMBER_INPUTS = [
  'input-decimal',
  'input-wholeNumber',
  'input-number',
  'input-percent',
  'input-currency'
];

const TEXT_INPUTS = [
  'input?srishti',
  'input-openText',
  'input-Binary',
  'input-dropDown(',
  'input-eMail',
  'input-url',
  'input-DatePicker',
  'auto_increment'
];

// Helper function to determine default value based on input type
export const getDefaultValue = (format) => {
  if (format === 'auto_increment') {
    console.log("format", format);
    const isTextInput = TEXT_INPUTS.some(type => format.includes(type));
    if (isTextInput) return 'Not Filled';
  }
  if (!format?.includes('input')) return null;


  const isNumberInput = NUMBER_INPUTS.some(type => format.includes(type));
  const isTextInput = TEXT_INPUTS.some(type => format.includes(type));

  if (isNumberInput) return '0';
  if (isTextInput) return 'Not Filled';
  return null;
};

// Helper function to initialize item with default values
export const initializeItemWithDefaults = (item) => {
  const newItem = { ...item };

  Object.entries(newItem).forEach(([key, value]) => {
    const defaultValue = getDefaultValue(value?.format);

    if (defaultValue !== null) {
      newItem[key] = {
        ...value,
        value: defaultValue
      };
    }
  });

  return newItem;
};

// Helper function to parse date string
export const parseDate = (dateString) => {
  if (!dateString || dateString === "Not Filled") return null;

  try {
    const [day, month, year] = dateString.split('/');
    const date = new Date(year, month - 1, day);
    return isNaN(date.getTime()) ? null : date;
  } catch (error) {
    console.error("Error parsing date:", error);
    return null;
  }
};

// Helper function to get initial date value
export const getInitialValue = (value) => {
  if (!value) return null;
  if (typeof value === 'string') return parseDate(value);
  if (value.value === "Not Filled") return null;
  return parseDate(value.value);
};

//Helper Function to extract domain from email
export const extractDomain = (email) => {
  const atIndex = email.indexOf('@');
  return atIndex !== -1 ? email.slice(atIndex) : '';
};
