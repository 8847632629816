import React, { useEffect, useState } from "react";
import "./Hstyle.css";
import { Link, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaEarthAsia, FaXTwitter } from "react-icons/fa6";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import axios from "axios";
import { baseUrl } from "../../baseurl";
import { clearData, getOrg, getOrgEmail, getUserRole } from "../../services/loggedIn";
import { clientNavList, envintNavList, spocNavList } from "./clientnavbardata";
import { toast } from "react-toastify";
import { getOrganizationImage } from "../../services/ClientSideApis";
import Navbar from "./Navbar";
import ThankyouModal from "../PopUpDialog/ThankyouModal";
import { handleError } from "../../services/HandleError";

const Hamburger = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(
    "https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
  );
  const [showExpirationPopup, setShowExpirationPopup] = useState(false);

  const navigate = useNavigate();
  const userRole = getUserRole();
  const isEnvint = ["admin", "manager", "consultant"].includes(
    userRole.toLowerCase()
  );
  let navList;
  if (isEnvint) navList = envintNavList;
  else if (userRole === "SPOC") navList = spocNavList;
  else if (userRole === "Management") navList = spocNavList.filter((item) => item.id !== 4);
  else navList = clientNavList;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSessionExpired = () => {
    setShowExpirationPopup(false);
    clearData();
    navigate("/", { replace: true });
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.get(`${baseUrl}/logout`, {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      // console.log("log", res);
      clearData();
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error.response.data.success);
      if (error.response.data.success === false) {
        setShowExpirationPopup(true)
      }
    }
  };

  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        if (!isEnvint) {
          const res = await getOrganizationImage(getOrgEmail());
          setLogoUrl(res.data.imageUrl || "Logo");
        }
      } catch (error) {
        console.error(error)
        // toast.error("Error fetching organization image");
      }
    };

    fetchLogoUrl();
  }, []);

  return (
    <nav id="navbar" className="navbar">
      <Logo
        url={logoUrl}
        href={navList.filter((item) => item.id === 1)[0].navigateTo}
      />
      {isMobile ? (
        <MobileView
          navList={navList}
          isOpen={isOpen}
          toggleMenu={toggleMenu}
          handleLogout={handleLogout}
        />
      ) : (
        <Navbar
          handleLogout={handleLogout}
          navList={navList}
          isSPOC={userRole === "SPOC" || userRole === "Management"}
        />
      )}
      {showExpirationPopup &&
        <ThankyouModal
          openPopup={showExpirationPopup}
          closePopup={() => setShowExpirationPopup(false)}
          handleNavigate={handleSessionExpired}
          message={"Your session has expired. Please login again."}
          title={"Session Expired"}
          confirmText={"Login Again"}
        />
      }
    </nav>
  );
};

const MenuItem = ({ href, icon, text, onClick, dropMenu }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem>
        <Link
          to={href}
          className="nav-list-item"
          onClick={onClick || handleClick}
        >
          <ListItemButton>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
            {dropMenu ? open ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItemButton>
        </Link>
      </ListItem>
      {dropMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {dropMenu.map((subItem) => (
            <ListItem key={subItem.id} style={{ paddingLeft: "2rem" }}>
              <Link
                to={subItem.navigateTo}
                className="nav-list-item"
                onClick={onClick}
              >
                <ListItemButton>
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText primary={subItem.name} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
};

const MobileView = ({ toggleMenu, isOpen, navList, handleLogout }) => (
  <>
    <Tooltip title="Menu">
      <Button id="hamburger-menu" onClick={toggleMenu}>
        <MoreVertIcon className="menu-icon" />
      </Button>
    </Tooltip>
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleMenu}
      sx={{ width: "85vw", "& .MuiDrawer-paper": { width: "85vw" } }}
    >
      <IconButton style={{ fontSize: "2rem" }} onClick={toggleMenu}>
        <CloseIcon className="close-btn" />
      </IconButton>
      <List style={{ margin: "40px 0" }}>
        {navList.map((item) => (
          <MenuItem
            key={item.id}
            href={item.navigateTo}
            text={item.name}
            icon={item.icon}
            dropMenu={item.dropMenu}
          />
        ))}
        <Divider />
        <MenuItem
          href="#"
          icon={<LogoutIcon className="naviconstyle" />}
          text="Logout"
          onClick={handleLogout}
        />
      </List>
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          margin: "10px 0",
          bottom: "0",
        }}
      >
        <SocialLinks />
      </div>
    </Drawer>

  </>
);

const SocialLinks = () => (
  <Stack
    sx={{
      display: "flex",
      gap: "1rem",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "1rem",
    }}
  >
    <div className="social-container">
      <a
        href="https://www.linkedin.com/company/envintglobal/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>
      <a href="https://envintglobal.com/" target="_blank" rel="noreferrer">
        <FaEarthAsia />
      </a>
      <a
        href="https://twitter.com/EnvintGlobal"
        target="_blank"
        rel="noreferrer"
      >
        <FaXTwitter />
      </a>
    </div>
    <Logo
      url="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
      href="/"
    />
  </Stack>
);

const Logo = ({ url, href }) => (
  <Link to={href}>
    <img src={url} alt="Logo" className="nav-envint-logo" />
  </Link>
);

export default Hamburger;
