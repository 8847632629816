import React, { useEffect, useState } from "react";
import "./OrgManagement.css";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {
  buttoncontainded,
  buttoncontaindedDisabled,
  inputfieldstyle1,
  tablehead,
} from "../../styles.js";
import { tableheaddata } from "./tableheaddata.js";
import { OrgTableRow } from "../../components/Organization/OrgTableRow.jsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaFileArrowDown } from "react-icons/fa6";
import * as XLSX from "xlsx";
import { decryptData } from "../../services/crypto.js";
import {
  deleteOrganization,
  fetchAllOrganizations,
} from "../../services/OrganizationManagemnetApi.js";
import { Loader } from "../../components/Loader/Loader.jsx";
import { getLoginAttempts, getUserRole } from "../../services/loggedIn";
import ConfirmModal from "../../components/PopUpDialog/ConfirmModal.jsx";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../Joyride.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice.js";
import { getJoyrideSteps } from "../../services/helpingFunctions.js";
import pageguideimg from '../../navspoc (2).jpg'

// The main component to display the user management page
export const OrgManagement = () => {

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  let [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  // const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const loginAttempts = parseInt(getLoginAttempts());
  const [filteredOrgData, setFilteredOrgData] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const organizationsResponse = await fetchAllOrganizations();
        // Process organizations data
        const decryptedOrgsData = decryptData(organizationsResponse.data);
        const parsedOrgsData = JSON.parse(decryptedOrgsData);

        if (parsedOrgsData.dataStatus === false) {
          setErrorMessage(parsedOrgsData.message);
          setFilteredOrgData(parsedOrgsData.unmarshalledData);
        } else {
          setFilteredOrgData(parsedOrgsData.unmarshalledData || []);
          setErrorMessage("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  const handleView = (user) => {
    setSelectedUser(user);
    navigate("/view-org", {
      state: { user: user },
    });
  };
  const handleClose = () => {
    setModalMode(null);
  };
  const handleAddForm = () => {
    // setShowModal(true);
    navigate("/organization/add-org");
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setModalMode(null);
  };
  const handleDelete = async (user) => {
    setOrgToDelete(user);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (orgToDelete) {
      const params = {
        organizationEmail: orgToDelete.organizationEmail,
      };
      deleteOrganization(params)
        .then((response) => {
          setUsers((prevUsers) =>
            prevUsers.filter((u) => u.email !== orgToDelete.email)
          );
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.error(error);
        });
      setIsConfirmModalOpen(false);
      setOrgToDelete(null);
    }
  };

  const manageUser = (user) => {
    navigate("/organization_list", {
      state: {
        organizationName: user.organizationName,
        organizationEmail: user.organizationEmail,
      },
    });
  };

  const handleUpdate = (user) => {
    navigate("/organization/update-org", {
      state: {
        user: user,
      },
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleCheckboxTable = (user) => {
    navigate("/organization/view-dept-ou-table", {
      state: {
        operationalUnits: user.operationalUnits,
      },
    });
  };
  const deletedUsersCount = filteredOrgData.filter(
    (user) => user.deleteStatus === true
  ).length;

  const transformData = (data) => {
    return data.map((item) => {
      const newItem = { ...item };

      // Transform departments
      if (newItem.departments) {
        try {
          const depts = JSON.parse(newItem.departments);
          newItem.departments = depts
            .map((dept, index) => {
              const [key, value] = Object.entries(dept)[0];
              return `${key}- ${value.replace(/_/g, " ")}`;
            })
            .join("; ");
        } catch (error) {
          console.error("Error parsing departments:", error);
          newItem.departments = "Error parsing departments";
        }
      }

      // Transform operationalUnits
      if (newItem.operationalUnits) {
        try {
          const units = JSON.parse(JSON.parse(newItem.operationalUnits));

          // // console.log(
          //   "1",
          //   units
          //     .map((unit, index) => {
          //       const [key, value] = Object.entries(unit)[0];
          //       return `${key}- ${value}`;
          //     })
          //     .join("; ")
          // );

          newItem.operationalUnits = units
            .map((unit, index) => {
              const [key, value] = Object.entries(unit)[0];
              return `${key}- ${value}`;
            })
            .join("; ");
        } catch (error) {
          console.error("Error parsing operationalUnits:", error);
          newItem.operationalUnits = "Error parsing operationalUnits";
        }
      }

      // Remove unnecessary fields
      delete newItem.spocPassword;
      delete newItem.deleteStatus;
      delete newItem.isFirstTime;

      return newItem;
    });
  };

  const excelReportingName = "organization_data_";

  const handleDownloadExcel = () => {
    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    // Append the current time to the final excel reporting name
    finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;

    // Transform the data
    const transformedData = transformData(filteredOrgData);

    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, finalExcelReportingName);
  };


  const steps = [
    {
      target: ".add-org-btn",
      content: "You can add a new organization.",
      disableBeacon: true,
    },
    {
      target: ".org-table",
      content:
        "This table shows all the organizations. You can view, edit, delete, or manage users for each organization.",
    },
    {
      target: "#view0",
      content: "Click here to view details of an organization.",
    },
    {
      target: ".viewOrg",
      content: "You can view the organization's details.",
    },
    {
      target: ".editOrg",
      content: "Click here to edit an organization's details.",
    },
    {
      target: ".deleteOrg",
      content: "Click here to delete an organization.",
    },
    {
      target: ".manageUsers",
      content: "Click here to manage users for an organization.",
    },
    {
      target: ".org-list-download",
      content: "You can download the organization list .",
    },
  ];
  const userRole = getUserRole();
  const finalSteps = getJoyrideSteps(userRole, steps);




  const filteredUsers = filteredOrgData?.filter((user) => {
    return (
      user.organizationName?.toLowerCase().includes(search.toLowerCase()) ||
      user.spocEmail?.toLowerCase().includes(search.toLowerCase()) ||
      user.organizationEmail?.toLowerCase().includes(search.toLowerCase()) ||
      user.spocMobile?.includes(search)
    );
  });

  // Handle case where page might be out of bounds after filtering
  const maxPage = Math.max(
    0,
    Math.ceil(filteredUsers.length / rowsPerPage) - 1
  );
  const currentPage = page > maxPage ? maxPage : page;

  const paginatedUsers = filteredUsers
    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    .map((user, index) => (
      <OrgTableRow
        key={user.organizationEmail}
        user={user}
        id={index + 1 + currentPage * rowsPerPage}
        onView={handleView}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onManage={manageUser}
        handleCheckboxTable={handleCheckboxTable}
      />
    ));

  return (
    <>
      <Hamburger />
      <JoyRide
        steps={finalSteps}
      />
      <div className="container-user">
        <h1 className="frame_heading">Organization Management</h1>
        <div className="add-user-btn-search">
          <Button
            variant="contained"
            style={
              userRole === "Consultant"
                ? buttoncontaindedDisabled
                : buttoncontainded
            }
            onClick={handleAddForm}
            disabled={userRole === "Consultant"}
            className="add-org-btn"
          >
            Add Organization
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Search Organization"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              variant="outlined"
              sx={{ marginRight: "30px" }}
              onClick={handleDownloadExcel}
              className="org-list-download"
            >
              <FaFileArrowDown />
            </IconButton>
          </div>
        </div>
        {/* {errorMessage ? (
          <div className="error-message" style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
            {errorMessage}
          </div>
        ) : ( */}
        <>
          <TableContainer
            className="org-table"
            sx={{ maxHeight: 440 }}
            style={{ overflow: "scroll" }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                // padding: "10px",
              }}
            >
              <TableHead>
                <TableRow style={tablehead}>
                  {tableheaddata.map((item) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "1.1rem",
                          color: "#3079bd",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                        key={item.id}
                      >
                        {item.value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers}
              </TableBody>
            </Table>
            {errorMessage && <div className="error-message" style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
              {errorMessage}
            </div>}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={filteredUsers.length - deletedUsersCount} // Set count to the length of filteredUsers
            rowsPerPage={rowsPerPage}
            page={currentPage} // Use updated currentPage based on filtered results
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
        {/* )} */}
      </div>
      <Loader loading={loading} />

      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${orgToDelete?.organizationName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
      />

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
            <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
              <span>Would you like a tour of the page?</span>
              <p style={{ color: 'red' }}>Click on the icon at top-right corner of the page to have a tour anytime</p>
              <img
                src={pageguideimg}
                alt="guide"
              />
            </div>
          }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};
