import { Box, Button, Divider, Drawer } from "@mui/material";
import { buttoncontaindedfixed, userdeletebutton, usereditbutton } from "../../../styles";
import { useState } from "react";
import './spoccomment.css'
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getUserRole } from "../../../services/loggedIn";


export const CommentPopUp = ({
  handleOpen,
  handleClose,
  open,
  onSubmitComment,
  submittedComments,
  savedComments,
  draftComment,
  setDraftComment,
  onDeleteComment,
  onEditComment,
  reviewedBy,
  commentsArray,
  userEmail,
  spocEmail,
}) => {

  const maxChars = 500;
  const [editingIndex, setEditingIndex] = useState(null);

  // const formattedDate = (dateStr) => {
  //   const date = new Date(dateStr);
  //   console.log("dateStr", dateStr);

  //   const formattedDate = new Intl.DateTimeFormat('en-GB', {
  //     day: '2-digit',
  //     month: 'short',
  //     year: 'numeric',
  //   }).format(date);
  //   return formattedDate
  // }

  const formattedDate = (dateStr) => {
    try {
      // Split the date string into components
      const [day, month, year] = dateStr.split('/');

      // Create date using ISO format (YYYY-MM-DD)
      const date = new Date(`${year}-${month}-${day}`);

      // Check if date is valid
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      // Format the date using Intl.DateTimeFormat
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const handleChange = (e) => {
    setDraftComment(e.target.value);
  };

  const handleSubmit = (e) => {
    if (draftComment.trim() === "") {
      return toast.error("Cannot enter empty comments");
    } else {
      onSubmitComment(draftComment);
      toast.success("Comment added");
      handleClose();
    }
  };

  const handleEdit = (index) => {
    setDraftComment(submittedComments[index].comment);
    setEditingIndex(index);
  };

  const handleEditSubmit = () => {
    onEditComment(editingIndex, draftComment);
    setEditingIndex(null);
    setDraftComment('');
  };

  const handleDelete = (index) => {
    onDeleteComment(index);
    handleClose(); // Ensure the drawer closes and reopens with updated data
    toast.success("Comment deleted");
  };

  const userRole = getUserRole()

  const shouldShowTail = (currentMessage, prevMessage) => {
    if (!prevMessage) return true;
    return currentMessage.captureDate !== prevMessage.captureDate ||
      currentMessage.captureTime !== prevMessage.captureTime;
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      classes={{ paper: 'comment-slider' }}
    >
      <h2 className="frame_heading">{userRole === "SPOC" ? "Rejection Comment for User" : "Rejection Comment from SPOC"} </h2>
      {userRole === "SPOC" && <>

        <span style={{ fontSize: '12px', color: 'rgb(92, 92, 92)', marginTop: '10px' }}>From : {spocEmail}</span>
        <span style={{ fontSize: '12px', color: 'rgb(92, 92, 92)', marginBottom: '10px' }}>To : {userEmail}</span>
      </>
      }
      <Divider />
      {reviewedBy && <Box
        sx={{
          display: "flex",
          alignItems: "self-start",
          justifyContent: "start",
          gap: "12px",
          marginTop: '16px'
        }}
      >
        <span style={{ color: "#8C8D91" }}>Reviewd By: </span>
        <Box
          sx={{
            display: "flex",
            alignItems: "self-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ textTransform: "capitalize" }}>
            {reviewedBy.spocName}
          </span>
          <span style={{ color: "#8C8D91", fontSize: "14px" }}>
            {reviewedBy.spocEmail}
          </span>
        </Box>
      </Box>
        // : userRole !== "SPOC" ? <><h4 style={{ marginTop: "16px", color: "red", textAlign: "center" }} >SPOC Didn't review yet</h4> <h5 style={{ color: "red", textAlign: "center" }}>No Comments</h5> </> : ""
      }
      <div className="history-box">
        {commentsArray !== "NA" && commentsArray ? commentsArray?.map((comment, i) => (
          <div
            className={`sample-comment ${shouldShowTail(comment, commentsArray[i - 1]) ? 'with-tail' : ''}`}
            key={i}>
            {comment.comment}
            <span className="comment-date-time">{formattedDate(comment.captureDate)}, {comment.captureTime}</span>
          </div>
        )) : <h3 style={{ color: "red" }}>No comments</h3>
        }
        {submittedComments?.map((comment, i) => (
          <div className="drafted-comment" key={i}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{comment.comment}
              <div className="comment-actions">
                <Button onClick={() => handleEdit(i)}><EditIcon style={{ color: '#fff' }} /></Button>
                {/* <Button onClick={() => handleDelete(i)}><DeleteIcon style={{color:"#fff"}}/></Button> */}
              </div>
            </div>
            <span className="comment-date-time">
              {formattedDate(comment.captureDate)}, {comment.captureTime}
            </span>

          </div>
        ))}
      </div>
      {userRole === "SPOC" &&
        <div style={{ width: "95%", position: 'relative', bottom: '20px' }}>
          <textarea
            rows="8"
            placeholder="Add comment..."
            value={draftComment} // Show drafted comment in textarea
            onChange={handleChange}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              border: '1px solid gray',
              margin: '20px 0',
              resize: 'none'
            }}
            maxLength={maxChars}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === "Enter") {
                editingIndex !== null ? handleEditSubmit() : handleSubmit();
              }
            }}
          />
          <div style={{ textAlign: 'right', fontSize: '13px', color: draftComment?.length > maxChars ? 'red' : 'darkgray' }}>
            {draftComment?.length}/{maxChars}
          </div>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "white",
              padding: "5px 15px",
              borderRadius: "20px",
              background: '#45b653',
              margin: "0 10px 10px 10px",
            }}
            onClick={editingIndex !== null ? handleEditSubmit : handleSubmit}
          >
            {editingIndex !== null ? "Update" : "Add"}
          </Button>
        </div>}
    </Drawer>
  );
};