import Joyride, { STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "./redux/slice/TourGuideSlice";

export const JoyRide = ({ steps }) => {

  const dispatch = useDispatch()

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;
    if (status === STATUS.RUNNING) {
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
      // disableScroll(); // Disable scrolling when Joyride starts
    }
    if (status === STATUS.FINISHED) {
      dispatch(setRunTour(false))
      dispatch(setTourCompleted(true))
      document.body.style.overflow = "scroll";
    }
    if (status === STATUS.SKIPPED) {
      dispatch(setRunTour(false))
      document.body.style.overflow = "scroll";
    }
    if (type === "step:before") {
      // Scroll the table horizontally if needed
      const tableContainer = document.querySelector(".MuiTableContainer-root");
      const targetElement = document.querySelector(steps[index].target);
      if (tableContainer && targetElement) {
        const containerRect = tableContainer.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        if (targetRect.right > containerRect.right) {
          tableContainer.scrollLeft +=
            targetRect.right - containerRect.right + 20; // 20px extra for padding
        }
      }
    }
  };

  const { runTour } = useSelector((state) => state.tourGuide)
  return (
    <Joyride
      steps={steps}
      run={runTour}
      continuous={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 1000,
          arrowColor: '#fff',
          backgroundColor: '#fff',
          primaryColor: '#ff9c00',
          textColor: '#000',
          outline: 'none',
          overFlow: "scroll !important"
        },
        // overlay: {
        //   backgroundColor: 'rgba(0, 0, 0, 0.75)',
        //   height: '100%'
        // }
      }}
      disableScrolling={true}
    />
  )
}