import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  backBtnfixed,
  buttoncontaindedfixed,
  inputfieldstyle,
} from "../../styles";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseurl";
import MultiStep from "./MultiStep";
import { BsInfoCircle } from "react-icons/bs";
import { decryptData } from "../../services/crypto";
import { getOrg, getOrgEmail } from "../../services/loggedIn";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from '@mui/icons-material/Add';
import { i } from "mathjs";
import { extractDomain } from "../../services/helpingFunctions";

export const OrgDetails = ({ onAdd }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const OrgData = location.state?.orgData;
  const skills = location.state?.skills;
  const operationalUnits = location.state?.operationalUnits;
  const skillsWithDepartments = location.state?.skillsWithDepartments;
  const selectedItems = location.state?.selectedItems;

  const [image, setImage] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  let [orgname, setOrgname] = useState(location.state?.orgData?.name || "");
  let [orgEmail, setOrgEmail] = useState(
    location.state?.orgData?.organizationEmail || ""
  );
  const [orgtype, setOrgType] = useState(location.state?.orgData?.type || "");
  const [address, setAddress] = useState(
    location.state?.orgData?.address || ""
  );
  const [mobile, setMobile] = useState(location.state?.orgData?.mobile || "");
  let [spocName, setSpocName] = useState(
    location.state?.orgData?.spocName || ""
  );
  let [spocEmail, setSpocEmail] = useState(
    location.state?.orgData?.spocEmail || ""
  );
  const [spocMobile, setSpocMobile] = useState(
    location.state?.orgData?.spocMobile || ""
  );
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [code, setCode] = useState(location.state?.orgData?.code || "");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [country, setCountry] = useState(
    location.state?.orgData?.country || ""
  );
  const [countryData, setCountryData] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState(
    location.state?.selectedManagers || ""
  );
  const [managers, setManagers] = useState([]);

  const [orgEmailError, setOrgEmailError] = useState("");
  const [spocEmailError, setSpocEmailError] = useState("");
  const [validationPerformed, setValidationPerformed] = useState(false);
  const [selectedStartOfReporting, setSelectedStartOfReporting] = useState(
    location.state?.orgData?.startOfReporting
      ? parseDate(location.state.orgData.startOfReporting)
      : null
  );
  const [selectedEndOfReporting, setSelectedEndOfReporting] = useState(
    location.state?.orgData?.endOfReporting
      ? parseDate(location.state.orgData.endOfReporting)
      : null
  );
  const [OrgMobileError, setOrgMobileError] = useState("");
  const [mfa, setMfa] = useState(location.state?.orgData?.mfa || "");
  const [endReportingError, setEndReportingError] = useState("");
  const [spocEmailDomain, setSpocEmailDomain] = useState("");

  const handleMfaChange = (event) => {
    setMfa(event.target.value);
  };

  function parseDate(dateString) {
    const [month, year] = dateString.split("'");
    return new Date(
      parseInt(`20${year}`),
      new Date(Date.parse(month + " 1, 2000")).getMonth(),
      1
    );
  }

  const formatDate = (date) => {
    if (!date) return "";
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().substr(-2);

    return `${month}'${year}`;
  };

  const validateEmails = async (orgEmail, spocEmail, spocMobile) => {
    try {
      const response = await axios.post(
        `${baseUrl}/check-organization`,
        { organizationEmail: orgEmail, spocEmail, spocMobile },
        { withCredentials: true }
      );

      const isOrgEmailExists = response.data.organizationExists;
      const isSpocEmailValid = response.data.spocExists;
      const isOrganizationMobileExists = response.data.mobileExists;

      // // console.log("isOrgEmailExists", isOrgEmailExists);
      // // console.log("isSpocEmailValid", isSpocEmailValid);
      // // console.log("isOrganizationMobileExists", isOrganizationMobileExists);

      setOrgEmailError(
        isOrgEmailExists ? "Organization email already exists." : ""
      );
      // setOrgMobileError(
      //   isOrganizationMobileExists
      //     ? "Organization mobile number already exists."
      //     : ""
      // );
      setSpocEmailError(
        isSpocEmailValid ? "SPOC email or mobile already exists." : ""
      );
      setSpocMobileError(
        isSpocEmailValid ? "SPOC email or mobile already exists." : ""
      );

      return isOrgEmailExists || isOrganizationMobileExists || isSpocEmailValid;
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during validation. Please try again.");
      return false;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`, {
          withCredentials: true,
        });
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);

        // After setting country data, find and set the selected country
        if (country && code) {
          const countryObject = Object.entries(response.data)
            .map(([label, value]) => ({ label, value }))
            .find((c) => c.label === country && c.value === code);

          if (countryObject) {
            setSelectedCountry(countryObject);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getAllEnvintUsers = async () => {
      try {
        const organizationName = getOrg();
        const res = await axios.post(
          `${baseUrl}/getAllManagersAndConsultant`,
          { organizationName },
          { withCredentials: true }
        );
        const decryptedData = decryptData(res.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);
        setManagers(parsedData.users.Manager);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
    getAllEnvintUsers();
  }, []);

  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
  };


  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));

  const handleChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
      setSelectedCountry(newValue);
    } else {
      setCountry(null);
      setCode(null);
    }
  };

  const handleTypeChange = (event) => {
    setOrgType(event.target.value);
  };

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value) && value.length === 10;
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };
  const validateSpocEmail = (spocEmailValue, orgEmailValue) => {
    if (!spocEmailValue) return "";
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(spocEmailValue);
    if (!isValid) {
      return "Invalid email format";
    }

    // Extract domains from both emails
    if (orgEmailValue.length > 0) {
      const spocDomain = spocEmailValue.split('@')[1].toLowerCase();
      const orgDomain = orgEmailValue?.split('@')[1].toLowerCase();

      // Check if domains match
      if (spocDomain !== orgDomain) {
        return "SPOC email must be from the same domain as the organization email";
      }
    }

    return "";
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    // setMobile(limitedValue);
    // validateMobileNumber(value);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setSpocMobile(limitedValue);
  };
  const emailRegex = /^[a-zA-Z0-9._@]*$/;
  const nameRegex = /^[a-zA-Zà-ÿÀ-Ÿ' .-]*$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (emailRegex.test(value)) {
      setOrgEmail(value);


      // Extract and set the domain
      const domain = extractDomain(value);
      setSpocEmailDomain(domain);

      // If SPOC email exists, update it with new domain
      if (spocEmail) {
        const spocLocalPart = spocEmail.split('@')[0];
        if (domain) {
          setSpocEmail(spocLocalPart + domain);
        }
      }

      // Validate SPOC email against new org email if SPOC email exists
      if (spocEmail) {
        const error = validateSpocEmail(spocEmail, value);
        setSpocEmailError(error);
      }
    } else {
      setEmailError("Special characters not allowed");
    }
  };

  const handleOrgNameChange = (e) => {
    const value = e.target.value;
    if (nameRegex.test(value)) {
      setOrgname(value);
    }
  };
  const handleSpocnameChange = (e) => {
    const value = e.target.value;
    if (nameRegex.test(value)) {
      setSpocName(value);
    }
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    // Extract local part (everything before @)
    const localPart = value.split('@')[0];

    if (emailRegex.test(localPart)) {
      // Combine local part with organization domain
      const newSpocEmail = localPart + spocEmailDomain;
      setSpocEmail(newSpocEmail);

      // Validate SPOC email against current org email
      const error = validateSpocEmail(newSpocEmail, orgEmail);
      setSpocEmailError(error);
    }
  };

  const navigate = useNavigate();

  // console.log(country);

  const handleSubmit = async (e) => {
    e.preventDefault();

    orgEmail = orgEmail.toLowerCase();
    spocEmail = spocEmail.toLowerCase();
    orgname = orgname.charAt(0).toUpperCase() + orgname.slice(1);
    spocName = spocName.charAt(0).toUpperCase() + spocName.slice(1);

    // Check if emails match before proceeding
    const emailError = validateSpocEmail(spocEmail.toLowerCase(), orgEmail.toLowerCase());

    if (emailError) {
      setSpocEmailError(emailError);
      toast.error("SPOC email must match organization email");
      return;
    }

    if (spocMobile.length !== 10) {
      toast.error("SPOC Mobile numbers should be 10 digits long");
      return;
    }

    if (country.length === 0) {
      toast.error("Please Select the country!");
      return;
    }
    if (orgtype === "") {
      toast.error("Please select organization type");
      return;
    }

    // Check if there's an end reporting error
    if (endReportingError) {
      toast.error(endReportingError);
      return;
    }

    // Validate emails
    const isValid = await validateEmails(
      orgEmail.toLowerCase(),
      spocEmail.toLowerCase(),
      spocMobile
    );
    setValidationPerformed(true);

    if (!isValid) {
      const userData = {
        organizationEmail: orgEmail,
        name: orgname,
        type: orgtype,
        address,
        mobile,
        spocName,
        spocEmail,
        spocMobile,
        code,
        country,
        image,
        startOfReporting: formatDate(selectedStartOfReporting),
        endOfReporting: formatDate(selectedEndOfReporting),
        mfa,
      };
      // // console.log("userData", userData);

      navigate("/organization/add-dept", {
        state: {
          orgData: userData,
          skills: skills,
          operationalUnits: operationalUnits,
          skillsWithDepartments: skillsWithDepartments,
          selectedItems: selectedItems,
          selectedManagers: selectedManagers,
        },
      });
    } else {
      if (orgEmailError && OrgMobileError && spocEmailError) {
        toast.error(
          "Organization email or mobile number and SPOC information already exist."
        );
      } else if (orgEmailError.length > 0) {
        toast.error(orgEmailError);
      } else if (OrgMobileError.length > 0) {
        toast.error(OrgMobileError);
      } else if (spocEmailError.length > 0) {
        toast.error(spocEmailError);
      }
    }
  };
  const organizationName = getOrg()
  const organizationEmail = getOrgEmail()
  // {organizationName: 'Envint', orgEmail: 'envint@envintglobal.com'}
  // // console.log("organizationName", organizationName);
  // // console.log("organizationEmail", organizationEmail);

  const handleAddNewManager = () => {
    navigate("/user/add-user", {
      state: {
        organizationName: organizationName,
        orgEmail: organizationEmail, // Replace with actual Envint email
        isAddingManager: true
      }
    });
  };

  const step = 0;

  const getTooltipContent = (projects) => {
    if (projects.length === 0) {
      return "No projects assigned";
    }

    return (
      <div>
        <strong>{projects.length} Project{projects.length !== 1 ? 's' : ''} Assigned:</strong>
        <ul style={{
          margin: '4px 0 0 0',
          paddingLeft: '16px',
          maxHeight: '200px',
          overflow: 'auto'
        }}>
          {projects.map((project, index) => (
            <li key={index} style={{ color: 'white' }}>
              {project.orgName}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Function to check if a date is in a later period than the start date
  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) return false;
    return endDate > startDate;
  };

  // console.log("selectedEndOfReporting", formatDate(selectedEndOfReporting));


  return (
    <>
      <div className="add-container">
        <Box sx={{ marginBottom: "20px" }}>
          <MultiStep step={step} />
        </Box>
        <h3 className="frame_heading">Organization Details</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            color: "red",
            fontWeight: "600",
          }}
        >
          <BsInfoCircle />
          &nbsp; All the fields are required.
        </p>
        <Box
          sx={{
            width: "50%",
            margin: "0px auto",
            padding: "20px",
            borderRadius: "20px",
          }}
          boxShadow={3}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Organization Email"
                  variant="outlined"
                  type="email"
                  value={orgEmail}
                  onChange={handleEmailChange}
                  required
                  error={validationPerformed && !!orgEmailError}
                  helperText={validationPerformed && orgEmailError}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  value={orgname}
                  onChange={handleOrgNameChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orgtype}
                    label="Age"
                    required
                    onChange={handleTypeChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission
                      }
                    }}
                  >
                    <MenuItem value={"Client"}>Client</MenuItem>
                    <MenuItem value={"Envint"}>Envint</MenuItem>
                    <MenuItem value={"Partner"}>Partner</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  type="text"
                  value={address}
                  required
                  onChange={(e) => setAddress(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  type="tel"
                  value={mobile}
                  onChange={handleMobileNumberChange}
                  required
                  error={OrgMobileError}
                  helperText={OrgMobileError}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    } 
                  }}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  required
                  options={countries}
                  getOptionLabel={(option) =>
                    `${option.label || location.state?.orgData?.country} (${option.value || location.state?.orgData?.code
                    })`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select a country" />
                  )}
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  value={selectedCountry}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="SPOC Name"
                  variant="outlined"
                  type="tel"
                  value={spocName}
                  onChange={handleSpocnameChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  className="capitalize"
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="SPOC Email"
                  variant="outlined"
                  type="tel"
                  value={spocEmail}
                  onChange={handleSpocEmailChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  required
                  error={!!spocEmailError}
                  helperText={spocEmailError}
                  placeholder={spocEmailDomain ? `Enter local part${spocEmailDomain}` : "Enter SPOC email"}
                  InputProps={{
                    endAdornment: spocEmailDomain && (
                      <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{spocEmailDomain}</span>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="SPOC Mobile"
                  variant="outlined"
                  type="tel"
                  value={spocMobile}
                  onChange={handleSpocMobileNumberChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  required
                  error={validationPerformed && !!spocEmailError}
                  helperText={validationPerformed && spocEmailError}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="manager-label">Select Manager</InputLabel>
                  <Select
                    labelId="manager-label"
                    id="manager-label"
                    label="Select Manager"
                    value={selectedManagers || ""}
                    onChange={handleManagerChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission
                      }
                    }}
                    renderValue={(selected) => {


                      // Find the manager object for the selected email
                      const selectedManager = managers.find(
                        (manager) => manager.email === selected
                      );

                      // Return the selected manager name
                      return selectedManager ? selectedManager.userName : "";
                    }}
                  >
                    {managers.map((manager, index) =>
                      isMediumScreen ? (
                        <MenuItem key={index} value={manager.email}>
                          <ListItemText
                            primary={manager.userName}
                            secondary={manager.email}
                          />
                        </MenuItem>
                      ) : (
                        <MenuItem key={index} value={manager.email}>
                          <Box width="100%">
                            <Tooltip title={getTooltipContent(manager.projects)}
                              placement="right"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'rgba(0, 0, 0, 0.87)',
                                    '& .MuiTooltip-arrow': {
                                      color: 'rgba(0, 0, 0, 0.87)'
                                    },
                                    maxWidth: 'none'
                                  }
                                }
                              }}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <ListItemText primary={manager.userName} />
                                </Grid>
                                <Grid item xs={6}>
                                  <ListItemText secondary={manager.email} />
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </Box>
                        </MenuItem>
                      )
                    )}
                    <MenuItem sx={{
                      borderTop: '1px solid #e0e0e0',
                      marginTop: 1,
                      paddingTop: 1
                    }}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddNewManager();
                        }}
                        // fullWidth
                        variant="contained"
                        startIcon={<AddIcon />}
                        color="primary"
                        sx={{ textTransform: 'capitalize', backgroundColor: "#3079bd" }}
                      >
                        Add New Manager
                      </Button>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    required
                    label="Start of Reporting"
                    views={["year", "month"]}
                    value={selectedStartOfReporting}
                    onChange={(newValue) => {
                      setSelectedStartOfReporting(newValue);
                      // Reset end date if it's no longer valid
                      if (selectedEndOfReporting && !isEndDateValid(newValue, selectedEndOfReporting)) {
                        setSelectedEndOfReporting(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  // maxDate={new Date()} // Prevent selecting future dates for start of reporting
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    required
                    label="End of Reporting"
                    views={["year", "month"]}
                    value={selectedEndOfReporting}
                    onChange={(newValue) => {
                      // Check date validity before setting
                      if (selectedStartOfReporting) {
                        if (newValue <= selectedStartOfReporting) {
                          setEndReportingError("End of Reporting must be after Start of Reporting");
                        } else {
                          setEndReportingError("");
                        }
                      }
                      setSelectedEndOfReporting(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth disabled={!selectedStartOfReporting} error={!!endReportingError}
                        helperText={endReportingError} />
                    )}
                    minDate={selectedStartOfReporting} // Set minimum date to start of reporting
                    // maxDate={new Date()} // Prevent selecting future dates
                    disabled={!selectedStartOfReporting} // Disable picker if no start date
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">
                    Multi-Factor Authentication (MFA)
                  </FormLabel>
                  <RadioGroup
                    aria-label="mfa"
                    name="mfa"
                    value={mfa}
                    onChange={handleMfaChange}
                    row
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Enabled"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Disabled"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>



              <Grid item xs={12} md={6}>
                <TextField
                  label="Add Logo"
                  type="file"
                  onChange={handleImageChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  style={inputfieldstyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <br />
            <br />
            <Button
              type="submit"
              style={backBtnfixed}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button type="submit" style={buttoncontaindedfixed}>
              Continue
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
};
