import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaWpforms } from "react-icons/fa6";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { MdCloudDownload } from "react-icons/md";
import { Loader } from "../../../components/Loader/Loader";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import { getLoginAttempts } from "../../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import pageguideimg from '../../../navspoc (2).jpg'

const ClientLandingPage = () => {

  const dispatch = useDispatch()

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);


  const [loading, setLoading] = useState(true);
  const loginAttempts = getLoginAttempts();

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(true))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const steps = [
    {
      target: "#navbar",
      content: "The menu items here correspond to the cards below.",
      disableBeacon: true,
    },
    {
      target: ".landing-page-container",
      content:
        "These cards provide quick access to different sections of the application.",
    },
    {
      target: ".link-box.blue",
      content: "Click on the Reporting Dashboard card to get started.",
    },

  ];

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }


  // const disableScroll = () => {
  //   document.body.style.overflow = 'hidden';
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [])

  if (loading) {
    return <Loader loading={loading} />
  }

  return (
    <div>
      {/* <DashHamburger /> */}
      <ClientLayout>
        <JoyRide steps={steps} />
        <div className="landing-page-container">
          <div className="link-box-container">
            <Link
              to="/reporting/dashboard"
              className="link-box blue"
            >
              <div className="circle">
                <DashboardIcon
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Reporting  <br /> Dashboard
              </h4>
            </Link>

            {/* <Link to="/download_report" className="link-box green2">
              <div className="circle">
                <MdCloudDownload
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Download & Preview
                <br /> Report
              </h4>
            </Link> */}
            <Link to="/reporting/history/overview" className="link-box green">
              <div className="circle">
                <FaWpforms className="circle-icon" />

                {/* <h3>User Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                History
              </h4>
            </Link>
          </div>
        </div>
      </ClientLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
    <div style={{ display: "flex",flexDirection:'column', alignItems: "center" }}>
      <span>Would you like a tour of the page?</span>
      <p style={{color:'red'}}>Click on the icon at top-right corner of the page to have a tour anytime</p>
      <img 
        src={pageguideimg} 
        alt="guide"
      />
    </div>
  }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};

export default ClientLandingPage;
