import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import Hamburger from "../Navbars/Hamburger";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnfixed, buttoncontaindedfixed } from "../../styles";
import { PriorityTable } from "../AssignmentManager/PriorityTable";
import { toast } from "react-toastify";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager";
import { Loader } from "../Loader/Loader";
import { motion, useInView } from "framer-motion";
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../services/loggedIn";
import { JoyRide } from "../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import pageguideimg from '../../navspoc (2).jpg'

const data = [
  { A: "Only A" },
  {
    B: [
      { BC: "BRSR Core" },
      { BE: "BRSR Essential" },
      { BL: "BRSR Leadership" },
    ],
  },
  { C: "A + B + C" },
  { D: "A + B + C + D" },
  { E: "Third Party Rating" },
  { S: "Sector Specific" },
];

const jsonData = {
  A: {
    A: 46,
    B: 24,
    BC: 5,
    BE: 18,
    BL: 2,
    C: 46,
    D: 46,
    E: 0,
    S: 0,
  },
  B: {
    A: 24,
    B: 194,
    BC: 28,
    BE: 121,
    BL: 59,
    C: 194,
    D: 194,
    E: 0,
    S: 0,
  },
  BC: {
    A: 5,
    B: 28,
    BC: 28,
    BE: 28,
    BL: 1,
    C: 28,
    D: 28,
    E: 0,
    S: 0,
  },
  BE: {
    A: 18,
    B: 121,
    BC: 28,
    BE: 121,
    BL: 1,
    C: 121,
    D: 121,
    E: 0,
    S: 0,
  },
  BL: {
    A: 2,
    B: 59,
    BC: 1,
    BE: 1,
    BL: 59,
    C: 59,
    D: 59,
    E: 0,
    S: 0,
  },
  C: {
    A: 46,
    B: 194,
    BC: 28,
    BE: 121,
    BL: 59,
    C: 356,
    D: 356,
    E: 0,
    S: 0,
  },
  D: {
    A: 46,
    B: 194,
    BC: 28,
    BE: 121,
    BL: 59,
    C: 356,
    D: 634,
    E: 7,
    S: 8,
  },
  E: {
    A: 0,
    B: 0,
    BC: 0,
    BE: 0,
    BL: 0,
    C: 0,
    D: 7,
    E: 7,
    S: 0,
  },
  S: {
    A: 0,
    B: 0,
    BC: 0,
    BE: 0,
    BL: 0,
    C: 0,
    D: 8,
    E: 0,
    S: 8,
  },
};

export const PriorityDropdown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedData0 = location.state?.selectedData;
  const selectedCategories = location.state?.selectedCategories;
  const selectedSubcategories = location.state?.selectedSubcategories;
  const [selectedOption, setSelectedOption] = useState(
    location.state?.selectedOption || null
  );

  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.1 });
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const loginAttempts = getLoginAttempts();

  const dispatch = useDispatch();

  const { showTourModal, tourCompleted } = useSelector((state) => state.tourGuide);

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".priority-box",
      content: "Select a priority for assignment",
      disableBeacon: true,
    },
    {
      target: ".org-continue-btn",
      content:
        "Continue to go to next page",
    }
  ];


  const requestData = location.state;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    if (option === "B") {
      setSelectedSubOptions(data[1].B);
    } else {
      setSelectedSubOptions([]);
    }

    // Add the data for the selected option to the selectedData state
    setSelectedData((prevSelectedData) => ({
      ...prevSelectedData,
      ...jsonData[option],
    }));
  };

  const handleSubOptionChange = (subOption) => {
    if (!selectedSubOptions.includes(subOption)) {
      setSelectedSubOptions([...selectedSubOptions, subOption]);
    }
  };

  const handleSubmit = () => {
    try {
      setLoading(true);
      const json = {
        priority: selectedOption,
      };
      const prioritiesJson = {
        priority: json.priority,
      };

      if (!prioritiesJson.priority || prioritiesJson.priority === null) {
        return toast.error("Select atleast one Priority");
      }

      // // console.log("prioritiesJson", prioritiesJson);

      navigate("/assignment/select-framework", {
        state: {
          prioritiesJson: prioritiesJson,
          org: requestData.org,
          isAssigned: location.state.isAssigned,
          selectedData: selectedData0,
          selectedCategories: selectedCategories,
          selectedSubcategories: selectedSubcategories,
          responseData: location.state?.responseData,
          scorecard: location.state?.scorecard,
          changedFrequencies: location.state?.changedFrequencies,
          checkedRows: location.state?.checkedRows,
          selectedFrequencies: location.state?.selectedFrequencies,
          checkedAll: location.state?.checkedAll,
          frameworkFilter: location.state?.frameworkFilter,
          // selectedCount: location.state?.selectedCount,
          selectedUsers: location.state?.selectedUsers,
        },
      });
    } catch (error) {
      // // console.log(error);
      toast.error("Error occured");
    } finally {
      setLoading(false);
    }
  };

  //     { label: data[0].A, key: "A", total: jsonData.A.A },
  //     {
  //       label: "All BRSR (including overlap with GRI)",
  //       key: "B",
  //       total: jsonData.B.B,
  //     },
  //     { label: data[1].B[0].BC, key: "BC", total: jsonData.BC.BC },
  //     { label: data[1].B[1].BE, key: "BE", total: jsonData.BE.BE },
  //     { label: data[1].B[2].BL, key: "BL", total: jsonData.BL.BL },
  //     { label: data[2].C, key: "C", total: jsonData.C.C },
  //     { label: data[3].D, key: "D", total: jsonData.D.D },
  //     { label: data[4].E, key: "E", total: jsonData.E.E },
  //     { label: data[5].S, key: "S", total: jsonData.S.S },
  //   ];

  //   return (
  //     <TableContainer sx={{ maxWidth: "800px", margin: "auto" }}>
  //       <Table
  //         sx={{
  //           backgroundColor: "#fff",
  //         }}
  //       >
  //         <TableHead>
  //           <TableRow sx={tablehead}>
  //             <TableCell
  //               sx={{
  //                 fontSize: "1rem",
  //                 color: "#3079bd",
  //                 fontWeight: "bold",
  //                 padding: "15px",
  //                 textAlign: "center",
  //               }}
  //             >
  //               Priorities
  //             </TableCell>
  //             <TableCell
  //               sx={{
  //                 fontSize: "1rem",
  //                 color: "#3079bd",
  //                 fontWeight: "bold",
  //                 padding: "15px",
  //                 textAlign: "center",
  //               }}
  //             >
  //               Total
  //             </TableCell>
  //             <TableCell
  //               sx={{
  //                 fontSize: "1rem",
  //                 color: "#3079bd",
  //                 fontWeight: "bold",
  //                 padding: "15px",
  //                 textAlign: "center",
  //               }}
  //             >
  //               Filtered based on selection
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {tableData.map((row, index) => (
  //             <TableRow key={index}>
  //               <TableCell
  //                 sx={{
  //                   fontSize: "1rem",
  //                   color: "#3079bd",
  //                   fontWeight: "bold",
  //                   padding: "0px 20px",
  //                 }}
  //               >
  //                 <FormControl component="fieldset">
  //                   <RadioGroup
  //                     value={selectedOption}
  //                     onChange={handleOptionChange}
  //                   >
  //                     <div key={index}>
  //                       {selectedOption === "B" &&
  //                       (row.key === "BC" ||
  //                         row.key === "BE" ||
  //                         row.key === "BL") ? (
  //                         <FormControlLabel
  //                           value={row.key}
  //                           control={
  //                             <Checkbox
  //                               sx={{ marginLeft: "30px" }}
  //                               size="small"
  //                               checked
  //                             />
  //                           }
  //                           label={`${row.key}: ${row.label}`}
  //                         />
  //                       ) : row.key === "BC" ||
  //                         row.key === "BE" ||
  //                         row.key === "BL" ? (
  //                         <Typography
  //                           sx={{
  //                             color: "#3079bd",
  //                             padding: "5px",
  //                             marginLeft: "30px",
  //                           }}
  //                         >
  //                           {" "}
  //                           {row.key}: {row.label}{" "}
  //                         </Typography>
  //                       ) : (
  //                         <FormControlLabel
  //                           value={row.key}
  //                           control={<Radio />}
  //                           label={`${row.key}: ${row.label}`}
  //                         />
  //                       )}
  //                     </div>
  //                   </RadioGroup>
  //                 </FormControl>
  //                 {/* {row.label} */}
  //               </TableCell>
  //               <TableCell
  //                 sx={{
  //                   padding: "0px",
  //                   textAlign: "center",
  //                   fontSize: "1rem",
  //                 }}
  //               >
  //                 {row.total}
  //               </TableCell>
  //               <TableCell
  //                 sx={{ padding: "0px", textAlign: "center", fontSize: "1rem" }}
  //               >
  //                 {selectedOption === row.key
  //                   ? jsonData[selectedOption][selectedOption]
  //                   : jsonData[row.key][selectedOption] || 0}
  //               </TableCell>
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
  // };
  const handleBack = () => {
    navigate("/assignment/select-organization", {
      state: {
        org: requestData,
        isAssigned: location.state?.isAssigned,
        selectedOption: selectedOption,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        selectedData: selectedData0,
        responseData: location.state?.responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  const step = 1;
  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <div className="container-user">
      <Hamburger />
      <JoyRide steps={steps} />
      <Box sx={{ marginBottom: "20px" }}>
        <MultiStepAssignManager step={step} />
      </Box>
      <div className="enter">
        <h1 className="frame_heading">
          Select priority of KPIs based on type/scale of operations of
          <span
            style={{
              color: "#45b653",
              fontSize: "1.5rem",
              marginLeft: "8px",
              padding: "5px 0",
              borderBottom: "2px solid #45b653",
            }}
          >
            {requestData.org.name}
          </span>
        </h1>
      </div>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.4, delay: 0.2 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
            margin: "25px 0",
          }}

        >
          {/* {renderJsonData()} */}
          <PriorityTable
            data={data}
            jsonData={jsonData}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
          />
        </Box>
      </motion.div>
      <Button onClick={handleBack} style={backBtnfixed}>
        Back
      </Button>
      <Box>
        <Button style={buttoncontaindedfixed} onClick={handleSubmit} className="org-continue-btn">
          Continue
        </Button>
      </Box>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
    <div style={{ display: "flex",flexDirection:'column', alignItems: "center" }}>
      <span>Would you like a tour of the page?</span>
      <p style={{color:'red'}}>Click on the icon at top-right corner of the page to have a tour anytime</p>
      <img 
        src={pageguideimg} 
        alt="guide"
      />
    </div>
  }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};

export default PriorityDropdown;
