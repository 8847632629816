import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button,
} from "@mui/material";
import {
  inputfieldstyle,
  buttoncontainded,
  inputfieldstyle1,
} from "../../styles";
import { toast } from "react-toastify";
import { baseUrl } from "../../baseurl";

export const UpdateOrg = ({ user, handleClose }) => {
  const [organizationName, setorganizationName] = useState(
    user.organizationName
  );
  const [organizationEmail, setOrganizationEmail] = useState(
    user.organizationEmail
  );

  const [type, setType] = useState(user.type);
  const [address, setAddress] = useState(user.address);
  const [mobile, setMobile] = useState(user.mobile);
  const [spocEmail, setSpocEmail] = useState(user.spocEmail);
  const [spocName, setSpocName] = useState(user.spocName);
  const [spocMobile, setSpocMobile] = useState(user.spocMobile);
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [spocemailError, setSpocEmailError] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState(user.country);
  const [code, setCode] = useState(user.countryCode);
  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };
  const validateSpocEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setSpocEmailError(!isValid);
    return isValid;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    validateMobileNumber(value);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    setSpocMobile(value);
    validateSpocMobileNumber(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setOrganizationEmail(value);
    validateEmail(value);
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    setSpocEmail(value);
    validateSpocEmail(value);
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`);
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);
  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
    } else {
      setCountry(""); // Handle case where user clears the selection
      setCode("");
    }
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const { priorityTags, spocPassword, operationalUnits, departments } = user;

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mobile.length === 10 && spocMobile.length === 10) {
      const orginitialData = {
        organizationName: organizationName,
        organizationEmail: organizationEmail,
        departments,
        operationalUnits,
        mobile,
        code,
        country,
        spocPassword,
        priorityTags,
        type,
        address,
        spocEmail,
        spocMobile,
        spocName,
      };
      navigate("/edit-dept", {
        state: {
          orginitialData: orginitialData,
        },
      });
      handleClose();
    } else {
      toast.error("Mobile number should be of 10 digits");
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        Edit Organization Details
      </DialogTitle>
      <DialogContent style={{ height: "450px", width: "450px" }} dividers>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            value={organizationEmail}
            onChange={handleEmailChange}
            style={inputfieldstyle}
            required
            disabled
            error={emailError}
            helperText={emailError ? "Enter a valid email address" : ""}
          />
          <TextField
            label="Name"
            variant="outlined"
            type="text"
            value={organizationName}
            onChange={(e) => setorganizationName(e.target.value)}
            style={inputfieldstyle1}
            required
          />
          <FormControl style={inputfieldstyle1}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              defaultValue={type}
              label="Age"
              onChange={handleTypeChange}
            >
              <MenuItem value={"Client"}>Client</MenuItem>
              <MenuItem value={"Envint"}>Envint</MenuItem>
              <MenuItem value={"Partner"}>Partner</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Address"
            variant="outlined"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            style={inputfieldstyle1}
            required
          />
          <TextField
            label="Mobile Number"
            variant="outlined"
            type="tel"
            value={mobile}
            onChange={handleMobileNumberChange}
            style={inputfieldstyle1}
            error={mobileError}
            helperText={mobileError ? "Enter only numbers" : ""}
            required
          />
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => `${option.label} (${option.value})`}
            renderInput={(params) => (
              <TextField {...params} label="Select a country" />
            )}
            onChange={handleDropdownChange}
            style={inputfieldstyle}
            value={{ label: country, value: code }} // Set default value here
            isOptionEqualToValue={(option, value) =>
              option.label === value.label && option.value === value.value
            }
          />
          <TextField
            label="SPOC Email"
            variant="outlined"
            type="tel"
            value={spocEmail}
            onChange={handleSpocEmailChange}
            style={inputfieldstyle}
            required
            error={spocemailError}
            helperText={spocemailError ? "Enter a valid email address" : ""}
          />
          <TextField
            label="SPOC Name"
            variant="outlined"
            type="tel"
            value={spocName}
            onChange={(e) => setSpocName(e.target.value)}
            style={inputfieldstyle1}
            required
          />
          <TextField
            label="SPOC Mobile"
            variant="outlined"
            type="tel"
            value={spocMobile}
            onChange={handleSpocMobileNumberChange}
            style={inputfieldstyle1}
            error={spocmobileError}
            helperText={spocmobileError ? "Enter only numbers" : ""}
            required
          />

          <br />
          <br />
          <Button type="submit" style={buttoncontainded}>
            Continue
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
