import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { inputfieldstyle2, tablehead } from "../../../styles";
import { FaFileArrowDown } from "react-icons/fa6";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import encrypt, { decryptData } from "../../../services/crypto";
import { Loader } from "../../../components/Loader/Loader";
import "../tracker.css";
import { getLoginAttempts, getUserEmail } from "../../../services/loggedIn";
import { useradoption } from "../../services/trackerApis";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import pageguideimg from '../../../navspoc (2).jpg'

Chart.register(LinearScale, BarElement, CategoryScale);

// Define chart options
const chartOptions = {
  scales: {
    y: {
      type: "linear", // Ensure that the scale type is specified as 'linear'
      beginAtZero: true,
    },
  },
};

const commonCellStyle = {
  textAlign: 'center',
  padding: '12px 8px'
};

function UserAdoption() {
  const [frequency, setFrequency] = useState(""); // Initialize frequency state with an empty string
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [userData, setUserData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [search, setSearch] = useState("");
  const [showNoMonthsMessage, setShowNoMonthsMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [length, setLength] = useState(0);
  const loginAttempts = parseInt(getLoginAttempts());


  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }


  const steps = [
    {
      target: ".tracker-dropdowns-container.user-adoption",
      content:
        "The dropdowns help filtering out users on basis of frequency of KPIs.",
      disableBeacon: true,
    },
    {
      target: ".user-tracker-download",
      content: "Download the table data by clicking on this icon",
      disableBeacon: true,
    },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentMonth =
      currentDate.toLocaleString("default", { month: "short" }) +
      "'" +
      currentDate.getFullYear().toString().slice(2);

    // Set selectedYear and selectedMonth to the current financial year and month
    setSelectedYear(getFinancialYear(currentDate));
    setSelectedMonth(currentMonth);

    fetchData(getFinancialYear(currentDate), currentMonth);
  }, []);

  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };

  useEffect(() => {
    const filteredData = filterUserData(fetchedData);
    setUserData(filteredData);
  }, [fetchedData, selectedYear, selectedMonth]);

  useEffect(() => {
    const monthsSet = new Set();
    let hasMonths = false;
    Object.keys(fetchedData).forEach((user) => {
      const userData = fetchedData[user];
      if (selectedYear && userData.assignments[selectedYear]) {
        const monthFrequency =
          userData.assignments[selectedYear].monthFrequency;
        if (monthFrequency && Object.keys(monthFrequency).length > 0) {
          Object.keys(monthFrequency).forEach((month) => {
            monthsSet.add(month);
            hasMonths = true;
          });
        }
      }
    });

    if (hasMonths) {
      setMonths(Array.from(monthsSet));
      setShowNoMonthsMessage(false);
    } else {
      setMonths([]);
      setShowNoMonthsMessage(true);
    }
  }, [userData, selectedYear, frequency, selectedMonth]);

  const currentDate = new Date();
  const currentYear1 = getFinancialYear(currentDate);

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
    if (event.target.value === "annual") {
      setSelectedYear("");
    }
  };

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleChangeSelectedMonth = (event) => {
    const newMonth = event.target.value;
    if (months.includes(newMonth) || newMonth === "") {
      setSelectedMonth(newMonth);
    } else {
      console.error("Invalid month selected:", newMonth);
    }
  };

  // Inside the fetchData function
  const fetchData = async () => {
    try {
      setLoading(true);

      const email = getUserEmail();

      const encryptData = encrypt({ email });

      const response = await useradoption(encryptData);

      const decryptedData = decryptData(response.data);

      const parsedData = JSON.parse(decryptedData);

      setFetchedData(parsedData.aggregatedData);

      const yearsSet = new Set();
      const monthsSet = new Set();

      Object.values(parsedData.aggregatedData).forEach((user) => {
        Object.keys(user.assignments).forEach((year) => {
          yearsSet.add(year);
          Object.keys(user.assignments[year].monthFrequency).forEach(
            (month) => {
              monthsSet.add(month);
            }
          );
        });
      });

      setYears(Array.from(yearsSet));
      setMonths(Array.from(monthsSet));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const filterUserData = (data) => {
    const users = Object.entries(data);
    return users
      .map(([username, user]) => {
        const yearData = user.assignments[selectedYear];
        const annualData = yearData?.annualFrequency || { total: 0, filled: 0 };

        let monthlyTotal = 0;
        let monthlyFilled = 0;
        Object.values(yearData?.monthFrequency || {}).forEach((monthData) => {
          monthlyTotal += monthData.total || 0;
          monthlyFilled += monthData.filled || 0;
        });

        const totalAssignments = annualData.total + monthlyTotal;
        const totalFilled = annualData.filled + monthlyFilled;

        return {
          username,
          lastLogin: user.lastLogin,
          loginAttempts: user.loginAttempts,
          captureUpdated: user.captureUpdated,
          annualTotal: annualData.total,
          annualFilled: annualData.filled,
          monthlyTotal,
          monthlyFilled,
          totalAssignments,
          totalFilled,
          monthFrequency: yearData?.monthFrequency || {},
        };
      })
      .filter((user) => {
        if (!frequency || frequency === "") return true;
        if (frequency === "annual") {
          return user.annualTotal > 0;
        } else if (frequency === "monthly") {
          const monthData = user.monthFrequency[selectedMonth];
          return monthData?.total > 0;
        }
        return false;
      });
  };
  const excelReportingName = "user_adoption_";

  const downloadExcel = () => {
    // Filter the data to include only the selected frequency and year
    const filteredUserData = sortedUserData.filter((user) => {
      const assignments = user.assignments[selectedYear];
      if (!assignments) return false;
      if (frequency === "annual") {
        return assignments.annualFrequency;
      } else if (frequency === "monthly") {
        const monthData = assignments.monthFrequency[selectedMonth];
        return monthData;
      }
      return false;
    });

    // Flatten the assignments data and merge it with the user data
    const flattenedUserData = filteredUserData.map((user) => {
      const flattenedUser = {
        username: user.username,
        lastLogin: user.lastLogin,
        loginAttempts: user.loginAttempts,
        captureUpdated: user.captureUpdated || "N/A",
        totalAssignments:
          user.assignments[selectedYear]?.annualFrequency?.total || 0,
        filledAssignments:
          user.assignments[selectedYear]?.annualFrequency?.filled || 0,
      };
      return flattenedUser;
    });

    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    if (frequency === "monthly") {
      const newMonthStr = selectedMonth.split("'").join("-");
      finalExcelReportingName += `${newMonthStr}_${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    } else if (frequency === "annual") {
      finalExcelReportingName += `${selectedYear}_${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    } else {
      finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    }

    // Convert the filtered and flattened data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(flattenedUserData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UserData");

    // Save the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      finalExcelReportingName
    );
  };
  const handleSort = (column) => {
    if (column === "progress") {
      setUserData((prevUserData) =>
        [...prevUserData].sort((a, b) => {
          const getProgressValue = (user) => {
            if (frequency === "monthly") {
              const monthData = user.monthFrequency[selectedMonth];
              return monthData ? monthData.filled / (monthData.total || 1) : 0;
            } else if (frequency === "annual") {
              return user.annualFilled / (user.annualTotal || 1);
            } else {
              return user.totalFilled / (user.totalAssignments || 1);
            }
          };

          const aProgress = getProgressValue(a);
          const bProgress = getProgressValue(b);

          return sortOrder === "asc"
            ? aProgress - bProgress
            : bProgress - aProgress;
        })
      );
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      if (sortBy === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortBy(column);
        setSortOrder("asc");
      }
    }
  };

  const sortByChart = (a, b) => {
    const aTotal = a.assignments[selectedYear]?.annualFrequency?.total || 0;
    const bTotal = b.assignments[selectedYear]?.annualFrequency?.total || 0;
    const aFilled = a.assignments[selectedYear]?.annualFrequency?.filled || 0;
    const bFilled = b.assignments[selectedYear]?.annualFrequency?.filled || 0;

    // Calculate the chart values for comparison
    const aChartValue = aFilled / aTotal;
    const bChartValue = bFilled / bTotal;

    if (sortOrder === "asc") {
      return aChartValue - bChartValue;
    } else {
      return bChartValue - aChartValue;
    }
  };

  const sortByAssignments = (a, b, column) => {
    const aTotal = a.assignments[selectedYear]?.annualFrequency?.total || 0;
    const bTotal = b.assignments[selectedYear]?.annualFrequency?.total || 0;
    const aFilled = a.assignments[selectedYear]?.annualFrequency?.filled || 0;
    const bFilled = b.assignments[selectedYear]?.annualFrequency?.filled || 0;

    if (column === "totalAssignments") {
      return sortOrder === "asc" ? aTotal - bTotal : bTotal - aTotal;
    } else if (column === "filledAssignments") {
      return sortOrder === "asc" ? aFilled - bFilled : bFilled - aFilled;
    }
  };
  const sortByColumn = (a, b, column) => {
    let valueA, valueB;

    switch (column) {
      case "username":
      case "lastLogin":
      case "loginAttempts":
      case "captureUpdated":
        valueA = a[column];
        valueB = b[column];
        break;
      case "totalAssignments":
        valueA =
          frequency === "monthly"
            ? a.monthFrequency[selectedMonth]?.total || 0
            : frequency === "annual"
              ? a.annualTotal
              : a.totalAssignments;
        valueB =
          frequency === "monthly"
            ? b.monthFrequency[selectedMonth]?.total || 0
            : frequency === "annual"
              ? b.annualTotal
              : b.totalAssignments;
        break;
      case "filledAssignments":
        valueA =
          frequency === "monthly"
            ? a.monthFrequency[selectedMonth]?.filled || 0
            : frequency === "annual"
              ? a.annualFilled
              : a.totalFilled;
        valueB =
          frequency === "monthly"
            ? b.monthFrequency[selectedMonth]?.filled || 0
            : frequency === "annual"
              ? b.annualFilled
              : b.totalFilled;
        break;
      default:
        return 0;
    }

    // Handle "NA" values
    if (valueA === "NA" && valueB === "NA") return 0;
    if (valueA === "NA") return sortOrder === "asc" ? 1 : -1;
    if (valueB === "NA") return sortOrder === "asc" ? -1 : 1;

    // Convert captureUpdated to Date if it's not "NA" or null
    if (column === "captureUpdated") {
      if (!valueA && !valueB) return 0;
      if (!valueA) return sortOrder === "asc" ? 1 : -1;
      if (!valueB) return sortOrder === "asc" ? -1 : 1;
      valueA = new Date(valueA);
      valueB = new Date(valueB);
    }

    // Compare based on data type
    if (typeof valueA === "string") {
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB, undefined, { sensitivity: "base" })
        : valueB.localeCompare(valueA, undefined, { sensitivity: "base" });
    } else if (typeof valueA === "number") {
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    } else if (valueA instanceof Date) {
      return sortOrder === "asc"
        ? valueA.getTime() - valueB.getTime()
        : valueB.getTime() - valueA.getTime();
    }

    return 0;
  };

  const sortedUserData = useMemo(() => {
    if (sortBy === "progress") {
      return [...userData].sort((a, b) => {
        const getProgressValue = (user) => {
          if (frequency === "monthly") {
            const monthData = user.monthFrequency[selectedMonth];
            return monthData ? monthData.filled / (monthData.total || 1) : 0;
          } else if (frequency === "annual") {
            return user.annualFilled / (user.annualTotal || 1);
          } else {
            return user.totalFilled / (user.totalAssignments || 1);
          }
        };

        const aProgress = getProgressValue(a);
        const bProgress = getProgressValue(b);

        return sortOrder === "asc"
          ? aProgress - bProgress
          : bProgress - aProgress;
      });
    } else {
      return [...userData].sort((a, b) => {
        if (sortBy && sortOrder) {
          return sortByColumn(a, b, sortBy);
        }
        return 0;
      });
    }
  }, [userData, sortBy, sortOrder, frequency, selectedMonth]);

  // // console.log("sortedUserData", sortedUserData);

  //search bar
  const filteredUserData = sortedUserData.filter((user) => {
    const searchLower = search.toLowerCase();
    return (
      user.username.toLowerCase().includes(searchLower) ||
      user.lastLogin.toLowerCase().includes(searchLower) ||
      user.loginAttempts.toString().toLowerCase().includes(searchLower) ||
      (user.captureUpdated || "").toLowerCase().includes(searchLower) ||
      user.annualTotal.toString().toLowerCase().includes(searchLower) ||
      user.annualFilled.toString().toLowerCase().includes(searchLower) ||
      user.monthlyTotal.toString().toLowerCase().includes(searchLower) ||
      user.monthlyFilled.toString().toLowerCase().includes(searchLower) ||
      user.totalAssignments.toString().toLowerCase().includes(searchLower) ||
      user.totalFilled.toString().toLowerCase().includes(searchLower) ||
      Object.entries(user.monthFrequency).some(
        ([month, data]) =>
          month.toLowerCase().includes(searchLower) ||
          data.total.toString().toLowerCase().includes(searchLower) ||
          data.filled.toString().toLowerCase().includes(searchLower)
      )
    );
  });
  function BarChart({ filledAssignments, totalAssignments }) {
    const percentageFilled = (filledAssignments / totalAssignments) * 100 || 0;
    let barColor = "red"; // Default color: Orange

    if (totalAssignments > 0) {
      if (percentageFilled < 40) {
        barColor = "red";
      } else if (percentageFilled >= 40 && percentageFilled < 100) {
        barColor = "#ff9c00"; // Orange
      } else {
        barColor = "#45b653"; // Green
      }
    }
    return (
      <div
        style={{
          width: "100%",
          height: "8px",
          backgroundColor: "#dadada",
          borderRadius: "6px",
          position: "relative",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: `${percentageFilled}%`,
            height: "100%",
            backgroundColor: barColor,
            transition: "width .2s ease-out",
            position: "relative",
            borderRadius: "6px",
            zIndex: 50,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-20px",
              left: "100%",
              transform: "translateX(-50%)",
              color: barColor,
              fontSize: "12px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {totalAssignments > 0 ? `${percentageFilled.toFixed(0)}%` : "0%"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div
          // style={{ padding: "15px", marginTop: "82px" }}
          className="container-user"
        >
          <JoyRide
            steps={steps}
          />
          <div className="tracker-page-head">
            <h1 className="frame_heading">User Adoption</h1>
            <Box className="tracker-dropdowns-container user-adoption">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="frequency-label">Select Frequency</InputLabel>
                <Select
                  labelId="frequency-label"
                  label="Select Frequency"
                  id="frequency"
                  value={frequency}
                  onChange={handleChangeFrequency}
                >
                  <MenuItem value="">
                    <em>Select Frequency</em>
                  </MenuItem>
                  <MenuItem value="annual">Annual</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>

              {frequency === "annual" && frequency !== "" && (
                <>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="year-label">Select Year</InputLabel>
                    <Select
                      labelId="year-label"
                      id="year"
                      label="Select Year"
                      value={selectedYear}
                      onChange={handleChangeSelectedYear}
                    >
                      <MenuItem value="">
                        <em>Select Year</em>
                      </MenuItem>
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {frequency === "monthly" && frequency !== "" && (
                <>
                  <Box className="monthly-tracker-dropdown">
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="month-label">Select Month</InputLabel>
                      <Select
                        labelId="month-label"
                        id="month"
                        label="Select Month"
                        value={selectedMonth}
                        onChange={handleChangeSelectedMonth}
                      >
                        <MenuItem value="">
                          <em>Select Month</em>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                        {showNoMonthsMessage && (
                          <MenuItem value="" disabled>
                            {`No Data stored at a monthly frequency in the year ${selectedYear}`}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="year-label">Select Year</InputLabel>
                      <Select
                        id="year"
                        value={selectedYear}
                        label="Select Year"
                        onChange={handleChangeSelectedYear}
                      >
                        <MenuItem value="">
                          <em>Select Year</em>
                        </MenuItem>
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </Box>
            <div className="tracker-search-box">
              <TextField
                id="outlined-basic"
                label="Search User"
                variant="outlined"
                style={inputfieldstyle2}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton
                variant="outlined"
                onClick={downloadExcel}
                className="user-tracker-download"
              >
                <FaFileArrowDown />
              </IconButton>
            </div>
          </div>
          {/* </div> */}
          {frequency === "monthly" && showNoMonthsMessage && (
            <Typography
              variant="h6"
              sx={{ marginLeft: "18px", fontSize: "14px", color: "red" }}
            >
              No Data stored at a monthly frequency in the year {selectedYear}
            </Typography>
          )}
          <TableContainer style={{ overflow: "scroll" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                padding: "10px",
              }}
            >
              <TableHead>
                <TableRow sx={tablehead}>
                  <TableCell onClick={() => handleSort("username")} >
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      Username{" "}
                      {sortBy === "username" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  
                  <TableCell onClick={() => handleSort("lastLogin")} >
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      Total Login{" "}
                      {sortBy === "lastLogin" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort("loginAttempts")} >
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      {" "}
                      Last Login{" "}
                      {sortBy === "loginAttempts" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort("captureUpdated")}>
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      {" "}
                      Last Data Entry{" "}
                      {sortBy === "captureUpdated" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort("filledAssignments")}>
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      {" "}
                      Filled Assignments{" "}
                      {sortBy === "filledAssignments" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort("totalAssignments")} >
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      {" "}
                      Total KPI Assignments{" "}
                      {sortBy === "totalAssignments" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => handleSort("progress")}>
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        fontFamily: "Poppins",
                        display:"flex",
                        justifyContent:"center"
                      }}
                    >
                      Progress{" "}
                      {sortBy === "chart" &&
                        (sortOrder === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        ))}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUserData.length > 0 ? (
                  filteredUserData.map((user) => (
                    <TableRow key={user.username}>
                      <TableCell sx={commonCellStyle}>{user.username}</TableCell>
                      <TableCell sx={commonCellStyle}>{user.loginAttempts}</TableCell>
                      <TableCell sx={commonCellStyle}>{user.lastLogin}</TableCell>
                      <TableCell sx={commonCellStyle}>{user.captureUpdated || "N/A"}</TableCell>
                    
                      <TableCell sx={commonCellStyle}>
                        {frequency === "monthly"
                          ? user.monthFrequency[selectedMonth]?.filled || 0
                          : frequency === "annual"
                            ? user.annualFilled
                            : user.totalFilled}
                      </TableCell>
                      <TableCell sx={commonCellStyle}>
                        {frequency === "monthly"
                          ? user.monthFrequency[selectedMonth]?.total || 0
                          : frequency === "annual"
                            ? user.annualTotal
                            : user.totalAssignments}
                      </TableCell>
                      <TableCell sx={commonCellStyle}>
                        <BarChart
                          filledAssignments={
                            frequency === "monthly"
                              ? user.monthFrequency[selectedMonth]?.filled || 0
                              : frequency === "annual"
                                ? user.annualFilled
                                : user.totalFilled
                          }
                          totalAssignments={
                            frequency === "monthly"
                              ? user.monthFrequency[selectedMonth]?.total || 0
                              : frequency === "annual"
                                ? user.annualTotal
                                : user.totalAssignments
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[8, 12, 20]}
            component="div"
            count={filteredUserData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
    <div style={{ display: "flex",flexDirection:'column', alignItems: "center" }}>
      <span>Would you like a tour of the page?</span>
      <p style={{color:'red'}}>Click on the icon at top-right corner of the page to have a tour anytime</p>
      <img 
        src={pageguideimg} 
        alt="guide"
      />
    </div>
  }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
}

export default UserAdoption;
