import React, {useEffect, useRef, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {buttoncontainded} from "../../styles";
import {Button} from "@mui/material";
import {baseUrl} from "../../baseurl";
import {Loader} from "../../components/Loader/Loader";

function OTP() {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // create a history object
  const location = useLocation();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const { state } = useLocation();
  const username = state.username;
  const token = state.token;
  const [m, setMessage] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(false);

  useEffect(() => {
    if (m !== "") {
      toast.success(m, { duration: 3000, position: "top-right" });
    }
  }, [m]);

  useEffect(() => {
    const otpSentFlag = localStorage.getItem("otpSentFlag");

    if (otpSentFlag === "false" || otpSentFlag === null) {
      sendOTP();
      localStorage.setItem("otpSentFlag", "true");
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      // Check if the user is trying to go back to the OTP page
      if (location.pathname === "/varotp" && isOTPVerified) {
        // Prevent the user from going back to the OTP page
        navigate("/home", { replace: true });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname, navigate, isOTPVerified]);

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const sendOTP = () => {
    setMinutes(0);
    setSeconds(59);
    // call the API to send the OTP to the user
    const d = {
      userKey: username,
    };
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    axios
      .post(`${baseUrl}/sendOTP`, d, { headers }) // send a POST request to the /sendOTP endpoint with the phone number as the data
      .then((response) => {
        // handle the response from the server
        if (response.status === 200) {
          // if the response is successful
          localStorage.setItem("otpSentFlag", "true");
          toast.success("OTP sent successfully to " + username); // show a success message to the user
        } else {
          // if the response is unsuccessful
          toast.error("OTP sending failed"); // show an error message to the user
        }
      })
      .catch((error) => {
        // handle any errors
        console.error(error); // log the error
        toast.error("OTP sending failed"); // show an error message to the user
      });
  };

  const resendOTP = () => {
    setOtp(["", "", "", "", "", ""]);
    setMinutes(0);
    setSeconds(59);
    const d = {
      userKey: username,
    };
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };
    // call the API to resend the OTP to the user
    axios
      .post(`${baseUrl}/sendOTP`, d, { headers }) // send a POST request to the /sendOTP endpoint with the phone number as the data
      .then((response) => {
        // handle the response from the server
        if (response.status === 200) {
          // if the response is successful
          localStorage.setItem("otpSentFlag", "true");
          toast.success("OTP resent successfully to " + username); // show a success message to the user
        } else {
          // if the response is unsuccessful
          toast.error("OTP resending failed"); // show an error message to the user
        }
      })
      .catch((error) => {
        // handle any errors
        console.error(error); // log the error
        setMessage("OTP resending failed"); // show an error message to the user
      });
  };

  const verifyOTP = (otp) => {
    setLoading(true);
    const d = {
      userKey: username,
      otp: otp,
    };
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };
    // define the verifyOTP function that takes the OTP as an argument
    axios
      .post(`${baseUrl}/verifyOTP`, d, { headers }) // send a POST request to the /verifyOTP endpoint with the OTP and the secret as the data
      .then((response) => {
        setLoading(false);
        if (response.data.message === "approved") {
          setMessage("OTP Login Successful");
          localStorage.setItem("otpSentFlag", "false");
          // localStorage.setItem("token", token);
          setIsOTPVerified(true);
          navigate("/home", { replace: true });
        } else {
          toast.warn("OTP verification failed");
        }
      })
      .catch((error) => {
        // handle any errors
        console.error(error); // log the error
        toast.warn("OTP verification failed"); // show an error message to the user
      });
  };

  const submitOTP = () => {
    verifyOTP(otp.join(""));
  };

  const handleInputChange = (index, value) => {
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input box
    if (index < 5 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      // Move to the previous input box when backspace is pressed and the current box is empty
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitOTP(event);
    }
  };

  // Add a conditional rendering to prevent accessing the OTP page after verification
  if (isOTPVerified && location.pathname === "/varotp") {
    return <Navigate to="/home" replace />;
  }

  return (
    <div className="container0">
      <div className="card">
        <h1 style={{ color: "#3079bd" }}>Verify OTP</h1>
        <br />
        <div className="otp-input-container">
          {otp.map((value, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onKeyPress={handleKeyPress}
              style={{
                width: "60px",
                marginRight: "10px",
                textAlign: "center",
                border: "1px solid #3079bd",
                fontWeight: "bolder",
                fontSize: "1.3rem",
              }}
            />
          ))}
        </div>
        <div className="countdown-text">
          {seconds > 0 || minutes > 0 ? (
            <p style={{ textAlign: "left" }}>
              Time remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p>OTP not received?</p>
          )}
        </div>
        <div className="countdown-text">
          <Button
            disabled={seconds > 0 || minutes > 0}
            className="submit-btn"
            style={{
              color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
              backgroundColor: "#45b653",
              padding: "10px 65px",
              fontSize: "1rem",
              fontWeight: "bold",
              letterSpacing: "1px",
              borderRadius: "25px",
              outline: "none",
              border: "1px solid #45b653",
              margin: "0 20px",
            }}
            onClick={resendOTP}
          >
            Resend
          </Button>
          <Button style={buttoncontainded} onClick={submitOTP}>
            Submit
          </Button>
        </div>
      </div>
      {m && m !== "" ? <ToastContainer /> : <></>}
      <Loader loading={loading}/>
    </div>
  );
}
export default OTP;
