import React from 'react';
import {
    Box,
    CircularProgress,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Button
} from '@mui/material';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ProgressBar from '../../pages/ClientSide/ReportingDashboard/ProgressBar/ProgressBar';
import StatusButton from '../StatusButton/StatusButton';


const ReportingAndApproveTable = ({
    loading,
    plantsToDisplay,
    departmentsToDisplay,
    userAssignmentList,
    transposed,
    toggleTransposed,
    tabValue,
    buttonType,
    handleEntryClick,
    dashboard
}) => {
    if (loading) {
        return (
            <Box sx={{ width: '100%', height: '100%', textAlign: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="client-dashboard-table-container">
            <TableContainer sx={{ maxHeight: "440px" }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{
                        backgroundColor: "#fff",
                        borderRadius: "25px",
                        padding: "10px",
                    }}
                >
                    <TableBody>
                        <TableRow sx={{ position: "sticky", zIndex: 1, background: '#fff' }}>
                            <TableCell style={{ color: '#3079bd' }}>
                                <Tooltip title="Transpose Table">
                                    <Button
                                        onClick={toggleTransposed}
                                        sx={{ textTransform: "none", marginLeft: '10px' }}
                                        style={{ color: '#3079bd', fontSize: '1.1rem' }}
                                    >
                                        {!transposed ? "OU" : "Depts"}
                                        <SwapHorizontalCircleIcon style={{ margin: '0 5px', fontSize: '2rem' }} />
                                        {!transposed ? "Depts" : "OU"}
                                    </Button>
                                </Tooltip>
                            </TableCell>
                            {plantsToDisplay.map((plant) => (
                                <TableCell
                                    key={plant}
                                    style={{
                                        color: "#3079bd",
                                        fontWeight: '600',
                                        fontSize: "1.2rem",
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100px',
                                        textAlign: "center"
                                    }}
                                >
                                    {plant}
                                </TableCell>
                            ))}
                        </TableRow>

                        {departmentsToDisplay.map((department) => (
                            <TableRow key={department}>
                                <TableCell>{department}</TableCell>
                                {plantsToDisplay.map((plant, idx) => {
                                    const plantDepartments = !transposed
                                        ? userAssignmentList[department] || []
                                        : userAssignmentList[plant] || [];
                                    const departmentStats = plantDepartments.find((obj) =>
                                        obj.hasOwnProperty(!transposed ? plant : department)
                                    );
                                    const hasDepartment = !!departmentStats;

                                    return (
                                        <TableCell key={idx}>
                                            {hasDepartment ? (
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column"
                                                }}>
                                                    <ProgressBar
                                                        currentScore={
                                                            departmentStats[!transposed ? plant : department].saved
                                                        }
                                                        totalScore={
                                                            departmentStats[!transposed ? plant : department].total
                                                        }
                                                        approvedCount={
                                                            departmentStats[!transposed ? plant : department].approved
                                                        }
                                                        dashboard={dashboard}
                                                        inView={tabValue}
                                                    />
                                                    <StatusButton
                                                        buttonType={buttonType}
                                                        departmentStats={departmentStats}
                                                        department={department}
                                                        plant={plant}
                                                        transposed={transposed}
                                                        handleEntryClick={handleEntryClick}
                                                    />
                                                </div>
                                            ) : (
                                                <h4 style={{ textAlign: "center" }}>N/A</h4>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ReportingAndApproveTable;