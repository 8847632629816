import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help';
import { useDispatch } from 'react-redux';
import { setShowTourModal, setTourCompleted } from '../../redux/slice/TourGuideSlice';

const TourBtn = () => {
    const dispatch = useDispatch()

    const handleRunTourClick = () => {
        dispatch(setShowTourModal(true));
        dispatch(setTourCompleted(false));
    }

    return (

        <Tooltip title="Page Guide" arrow="bottom">
            <IconButton onClick={handleRunTourClick}>
                <HelpIcon />
            </IconButton>
        </Tooltip>

    )
}

export default TourBtn
