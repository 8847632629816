export const tableheaddata = [
  {
    id: 1,
    value: "Sr. No",
  },
  {
    id: 2,
    value: "Logo",
  },
  {
    id: 3,
    value: "Name",
  },
  {
    id: 4,
    value: " SPOC Email",
  },
  {
    id: 7,
    value: "Mobile No",
  },
  {
    id: 5,
    value: "No. of Depts.",
  },
  {
    id: 12,
    value: " ",
  },
  {
    id: 6,
    value: "No. of OU's",
  },
  {
    id: 9,
    value: "",
  },
  {
    id: 10,
    value: "Actions",
  },
  {
    id: 11,
    value: "",
  },
  {
    id: 15,
    value: "",
  }
];
