import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buttoncontaindedfixed,
  inputfieldstyle,
  inputfieldstyle1,
} from "../../styles";
import axios from "axios";
import { baseUrl, config } from "../../baseurl";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../../services/crypto";
import EditUserMultiStep from "./EditUserMultiStep";
import { fetchAllOrganizations } from "../../services/OrganizationManagemnetApi";
import { getOrg, getOrgEmail, getUserRole } from "../../services/loggedIn";

const EditUserDetails = () => {
  const location = useLocation();
  // // console.log("state", location.state);
  const user = location.state.user;
  const [username, setUsername] = useState(user.userName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.mobile);
  const [status, setStatus] = useState(user.userStatus);
  const [error, seterror] = useState("");
  const [reportingTo, setreportingTo] = useState(user.reportingTo);
  const [admin, setadmin] = useState(user.adminRights);
  const [orgnames, setnames] = useState([]);
  const [o, setOrganization] = useState(user.organizationName);
  const [organizationEmail, setOrganizationEmail] = user.organizationEmail;
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(user.country);
  const [code, setCode] = useState(user.countryCode);
  const [selectedManagers, setSelectedManagers] = useState(
    user.reportingTo || []
  );
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(user.projects || []);
  const [isOrgSelected, setIsOrgSelected] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.length === 10) {
      const userData = {
        userName: username,
        email: email,
        mobile: phone,
        organizationName: o,
        organizationEmail: user.organizationEmail,
        userStatus: status,
        reportingTo,
        operationalUnits: user.operationalUnits,
        adminRights: admin,
        countryCode: code,
        country: selectedCountry,
        // selectedManagers: report,
        projects: selectedProjects,
      };
      // // console.log("userData-------------------", userData);

      if (userData.operationalUnits !== "N/A") {
        navigate("/user/update-dept-ou", {
          state: {
            data: userData,
            backenddata: orgnames,
            checkedDepartments: location.state.checkedDepartments,
          },
        });
      } else {
        toast.error("No Department/ operational Units association found");
      }
    } else {
      seterror("Error : Phone number should be 10 ");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`);
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);
  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue.label);
      setCode(newValue.value);
    } else {
      setSelectedCountry(""); // Handle case where user clears the selection
      setCode("");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      await axios
        .get(`${baseUrl}/getAllOrganizations`, { withCredentials: true })
        .then((response) => {
          const decryptedData = decryptData(response.data);

          const parsedData = JSON.parse(decryptedData);
          // // // console.log("parsedData", parsedData.unmarshalledData);
          const organizationsWithDeleteStatus =
            parsedData.unmarshalledData.filter(
              (org) => org.deleteStatus === false
            );
          setnames(organizationsWithDeleteStatus);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const organizationEmail = getOrgEmail();

    const fetchData = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const [usersResponse, organizationsResponse] = await Promise.all([
          axios.post(
            `${baseUrl}/getAllManagersAndConsultant`,
            { organizationEmail },
            config
          ),
          fetchAllOrganizations(),
        ]);

        // Process users data
        const decryptedUsersData = decryptData(
          usersResponse.data.encryptedData
        );
        const parsedUsersData = JSON.parse(decryptedUsersData);
        // // console.log("parsedUsersData", parsedUsersData.users.Admin);
        setAllAdmins(parsedUsersData.users.Admin);
        setManagers(parsedUsersData.users.Manager);

        // Process organizations data
        const decryptedOrgsData = decryptData(organizationsResponse.data);
        const parsedOrgsData = JSON.parse(decryptedOrgsData);

        // Filter projects based on the user's organization
        const filteredProjects = parsedOrgsData.unmarshalledData.filter(
          (project) => project.organizationEmail !== user.organizationEmail
        );

        setProjects(filteredProjects);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterProjects = async (organization) => {
    const filterProjects = projects.filter(
      (project) => project.organizationEmail !== organization.organizationEmail
    );
    return setProjects(filterProjects);
  };

  const handleManagerReportingTo = (event) => {
    const selectedAdmin = event.target.value;
    setreportingTo(selectedAdmin);
  };

  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
    setreportingTo(selectedManager);
  };

  const handleProjectsChange = (event) => {
    const selectedValues = event.target.value;
    const selectedProjects = selectedValues
      .map((email) => {
        const project = projects.find((p) => p.organizationEmail === email);

        return project
          ? {
            orgEmail: project.organizationEmail,
            orgName: project.organizationName,
          }
          : null;
      })
      .filter(Boolean); // Remove any null values
    setSelectedProjects(selectedProjects);
  };

  const handleRolesChange = (e) => {
    setadmin(e.target.value);
    setSelectedManagers("");
    setSelectedProjects([]);
  };

  const AdminUsers = ["Admin", "Manager", "Consultant"];
  const ClientUsers = ["Management", "Spoc", "User"];

  const getUserRoles = () => {
    const userRole = getUserRole();
    const currentOrg = getOrg();
    // // console.log("currentOrg", currentOrg);

    const isAdminOrManager = userRole === "Admin" || userRole === "Manager";
    const consultant = userRole === "Consultant";
    // // console.log("user", user);

    const isEnvintOrg = user.organizationName === currentOrg;

    // Always return roles if admin is set
    if (admin || isOrgSelected) {
      if (consultant) {
        return ClientUsers.filter(
          (user) => user !== "Management" && user !== "Spoc"
        );
      }

      if (isAdminOrManager) {
        if (isEnvintOrg) {
          if (userRole === "Admin") return AdminUsers;
          if (userRole === "Manager")
            return AdminUsers.filter((user) => user !== "Admin");
        }
        return ClientUsers;
      }
      return ClientUsers;
    }

    return []; // Return an empty array if no organization is selected and no role is set
  };

  const step = 0;

  return (
    <div className="add-container">
      <Box sx={{ marginBottom: "20px" }}>
        <EditUserMultiStep step={step} />
      </Box>
      <Box
        sx={{
          width: "50%",
          margin: "0px auto",
          padding: "20px",
          borderRadius: "20px",
        }}
        boxShadow={3}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={inputfieldstyle1}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => `${option.label} (${option.value})`}
                renderInput={(params) => (
                  <TextField {...params} label="Select a country" />
                )}
                onChange={handleDropdownChange}
                style={inputfieldstyle}
                value={{ label: selectedCountry, value: code }} // Set default value here
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label && option.value === value.value
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  // style={inputfieldstyle}
                  value={status}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                <InputLabel id="demo-simple-select-label">
                  Organization
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Organization"
                  // onChange={(e) => setOrganization(e.target.value)}
                  // style={inputfieldstyle}
                  // value={o}
                  value={
                    orgnames.some((org) => org.organizationName === o) ? o : ""
                  }
                  disabled
                >
                  {orgnames.map((key, index) => (
                    <MenuItem key={index} value={key.organizationName}>
                      {key.organizationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {user.adminRights === "Manager" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                    <InputLabel id="reporting-to-label">
                      Select Admin to Report to
                    </InputLabel>
                    <Select
                      labelId="reporting-to-label"
                      id="reporting-to"
                      value={reportingTo}
                      label="Select Admin to Report to"
                      onChange={handleManagerReportingTo}
                      renderValue={(selected) => {
                        // Find the manager objects for the selected emails
                        const selectedAdminObjects = allAdmins.filter(
                          (manager) => selected.includes(manager.email)
                        );
                        // Return a string of selected manager names
                        return selectedAdminObjects
                          .map((admin) => admin.userName)
                          .join(", ");
                      }}
                    >
                      {allAdmins?.map((manager, index) => (
                        <MenuItem key={index} value={manager.email}>
                          <Checkbox
                            checked={reportingTo.indexOf(manager.email) > -1}
                          />
                          <ListItemText
                            primary={manager.userName}
                            secondary={manager.email}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                    <InputLabel id="project-label">
                      Selected Projects for Manager
                    </InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      label="Selected Projects for Manager"
                      multiple
                      // style={inputfieldstyle}
                      value={selectedProjects.map((p) => p.orgEmail)}
                      onChange={handleProjectsChange}
                      renderValue={(selected) =>
                        selectedProjects.map((p) => p.orgName).join(", ")
                      }
                    >
                      {projects?.map((project, index) => (
                        <MenuItem key={index} value={project.organizationEmail}>
                          <Checkbox
                            checked={selectedProjects.some(
                              (p) => p.orgEmail === project.organizationEmail
                            )}
                          />
                          <ListItemText
                            primary={project.organizationName}
                            secondary={project.organizationEmail}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {user.adminRights === "Consultant" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                    <InputLabel id="manager-label">
                      Selected Manager to report to
                    </InputLabel>
                    <Select
                      labelId="manager-label"
                      id="manager-label"
                      label="Selected Manager to report to"
                      value={selectedManagers}
                      // style={inputfieldstyle}
                      onChange={handleManagerChange}
                      renderValue={(selected) => {
                        // // console.log("selected", selected);

                        // Find the manager objects for the selected emails
                        const selectedManagerObjects = managers.filter(
                          (manager) => selected === manager.email
                        );

                        // // console.log(
                        //   "selectedManagerObjects",
                        //   selectedManagerObjects
                        // );

                        // Return a string of selected manager names
                        return selectedManagerObjects
                          .map((manager) => manager.userName)
                          .join(", ");
                      }}
                    >
                      {managers.map((manager, index) => (
                        <MenuItem key={index} value={manager.email}>
                          <Checkbox
                            checked={
                              selectedManagers.indexOf(manager.email) > -1
                            }
                          />
                          <ListItemText
                            primary={manager.userName}
                            secondary={manager.email}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ margin: "10px 10px 10px 0px" }}>
                    <InputLabel id="project-label">
                      Selected Client Organzation for Consultant
                    </InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      label="Selected Projects for Consultant"
                      multiple
                      // style={inputfieldstyle}
                      value={selectedProjects.map((p) => p.orgEmail)}
                      onChange={handleProjectsChange}
                      renderValue={(selected) =>
                        selectedProjects.map((p) => p.orgName).join(", ")
                      }
                    >
                      {projects?.map((project, index) => (
                        <MenuItem key={index} value={project.organizationEmail}>
                          <Checkbox
                            checked={selectedProjects.some(
                              (p) => p.orgEmail === project.organizationEmail
                            )}
                          />
                          <ListItemText
                            primary={project.organizationName}
                            secondary={project.organizationEmail}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <InputLabel id="roles-select-label">Roles</InputLabel>
              <Select
                fullWidth
                labelId="roles-select-label"
                id="roles-select"
                value={admin}
                onChange={handleRolesChange}
                disabled
              >
                {!isOrgSelected && !admin ? (
                  <MenuItem value="" disabled>
                    Select organization to display corresponding roles
                  </MenuItem>
                ) : (
                  getUserRoles().map((user, i) => (
                    <MenuItem value={user} key={i}>
                      {user}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Grid>
          </Grid>
          <br />
          <br />
          <Button type="submit" style={buttoncontaindedfixed}>
            Continue
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default EditUserDetails;
