import React, { useEffect, useMemo, useState } from "react";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { inputfieldstyle2, tablehead } from "../../../styles";
import { useSortBy, useTable } from "react-table";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { FaFileArrowDown } from "react-icons/fa6";
import * as XLSX from "xlsx";
import encrypt, { decryptData } from "../../../services/crypto";
import { Loader } from "../../../components/Loader/Loader";
import "../tracker.css";
import { getLoginAttempts, getUserEmail } from "../../../services/loggedIn";
import { getDepartmentCompliance } from "../../services/trackerApis";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import pageguideimg from '../../../navspoc (2).jpg'

const DeptCompTable = () => {
  // const token = getToken();
  const spocEmail = getUserEmail();
  const [compData, setCompData] = useState([]);
  const [currentYear, setCurrentYear] = useState(""); // Default current financial year
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [showNoMonthsMessage, setShowNoMonthsMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const loginAttempts = parseInt(getLoginAttempts());


  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".tracker-dropdowns-container.dept",
      content: "The dropdowns help filtering out departments on basis of frequency.of KPIs",
      disableBeacon: true,
    },
    {
      target: ".dept-tracker-download",
      content: "Download the table data by clicking on this icon",
      disableBeacon: true,
    },
  ];



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const currentDate = new Date();
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };
  const currentYear1 = getFinancialYear(currentDate);
  const currentMonth = currentDate.toLocaleString("en-us", {
    month: "short",
  });
  const slicedYear = currentDate.getFullYear().toString().slice(2);
  const concatedmonth = `${currentMonth}'${slicedYear}`;

  useEffect(() => {
    const fetchDeptCompData = async () => {
      try {
        setLoading(true);

        const encryptData = encrypt({ spocEmail });

        const res = await getDepartmentCompliance(encryptData);

        const decryptingData = decryptData(res.data);

        const pardsedData = JSON.parse(decryptingData);
        // // console.log("parsedData", pardsedData.data);
        setCompData(pardsedData.data);

        // setCompData(res.data); // Assuming response contains data property

        // Extract available years
        const yearsSet = new Set();
        Object.keys(pardsedData.data).forEach((department) => {
          Object.keys(pardsedData.data[department]).forEach((year) => {
            yearsSet.add(year);
          });
        });
        const sortedYears = Array.from(yearsSet).sort();
        setYears(sortedYears);

        setCurrentYear(currentYear1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchDeptCompData();
  }, [spocEmail]);

  useEffect(() => {
    const monthsSet = new Set();
    let hasMonths = false;
    Object.keys(compData).forEach((department) => {
      if (selectedYear && compData[department][selectedYear]) {
        compData[department][selectedYear].months.forEach((monthObj) => {
          const monthKey = Object.keys(monthObj)[0];
          monthsSet.add(monthKey);
          hasMonths = true;
        });
      }
    });

    if (hasMonths) {
      setMonths(Array.from(monthsSet));
      setShowNoMonthsMessage(false);
    } else {
      // setMonths([`No Data stored at a monthly frequency in the year ${selectedYear}`]);
      setMonths([]);
      setShowNoMonthsMessage(true);
    }
  }, [compData, selectedYear, frequency, selectedMonth]);

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
    // setSelectedYear("");
    // setSelectedMonth("");
  };

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleChangeSelectedMonth = (event) => {
    setSelectedMonth(event.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Department",
        accessor: "department",
        canSort: true,
      },
      {
        Header: "Filled",
        accessor: "filled",
        canSort: true,
      },
      {
        Header: "Total",
        accessor: "total",
        canSort: true,
      },
      {
        Header: "Progress",
        id: "progress",
        Cell: ({ row }) => {
          const filledPercentage =
            row.original.total > 0
              ? (row.original.filled / row.original.total) * 100
              : 0;
          let barColor = "#ffbf00"; // Default color: Orange

          if (filledPercentage < 40) {
            barColor = "red"; // Orange
          } else if (filledPercentage >= 40 && filledPercentage < 100) {
            barColor = "#ff9c00"; // Orange
          } else {
            barColor = "#45b653"; // Green
          }

          return (
            <div
              style={{
                width: "100%",
                height: "8px",
                backgroundColor: "#dadada",
                borderRadius: "6px",
                position: "relative",
                cursor: "pointer",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  width: `${filledPercentage}%`,
                  height: "100%",
                  backgroundColor: barColor,
                  transition: "width .2s ease-out",
                  position: "relative",
                  borderRadius: "6px",
                  zIndex: 50,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "100%",
                    transform: "translateX(-50%)",
                    color: barColor,
                    fontSize: "12px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  {filledPercentage.toFixed(0)}%
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Percentage",
        id: "percentage",
        accessor: "percentage",
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    let flatData = [];
    let total = 0;
    let filled = 0;
    Object.keys(compData).forEach((department) => {
      Object.keys(compData[department]).forEach((year) => {
        if (frequency === "annual") {
          if (selectedYear) {
            total =
              compData[department][selectedYear]?.annualFrequency?.total || 0;
            filled =
              compData[department][selectedYear]?.annualFrequency?.filled || 0;
          }
        } else if (frequency === "monthly") {
          if (
            selectedYear &&
            selectedMonth &&
            compData[department][selectedYear]
          ) {
            const monthData = compData[department][selectedYear].months.find(
              (month) => {
                return Object.keys(month)[0] === selectedMonth;
              }
            );
            if (monthData) {
              total = monthData[selectedMonth].total;
              filled = monthData[selectedMonth].filled;
            } else {
              total = 0;
              filled = 0;
            }
          }
        } else {
          // If no specific year or month is selected, display the data for the current fiscal year
          if (currentYear1) {
            const annualFrequency =
              compData[department][currentYear1]?.annualFrequency || {};
            // if (currentYear1) {
            total = annualFrequency?.total || 0;
            filled = annualFrequency?.filled || 0;
            // }

            if (compData[department][currentYear1]?.months) {
              compData[department][currentYear1].months.forEach((monthData) => {
                const monthKey = Object.keys(monthData)[0];
                total += monthData[monthKey].total;
                filled += monthData[monthKey].filled;
              });
            }
          }
        }
      });

      const score = total > 0 ? Math.round((filled / total) * 100) : 0;

      flatData.push({
        department,
        total,
        filled,
        percentage: `${score}%`,
      });
    });
    return flatData;
  }, [compData, frequency, selectedYear, selectedMonth]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTableData = useMemo(() => {
    return tableData.filter((row) =>
      Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [tableData, searchTerm]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: filteredTableData,
        initialState: {
          sortBy: [{ id: "percentage", desc: true }],
        },
      },
      useSortBy
    );

  const sortedRows = rows.map((row) => row.original);

  const excelReportingName = "department_tracker_";

  const handleDownload = () => {
    let finalExcelReportingName = excelReportingName;
    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    if (frequency === "monthly") {
      const newMonthStr = selectedMonth.split("'").join("-");
      finalExcelReportingName += `${newMonthStr}_${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    } else if (frequency === "annual") {
      finalExcelReportingName += `${selectedYear}_${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    } else {
      finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;
    }

    const ws = XLSX.utils.json_to_sheet(sortedRows); // Use filteredData to create worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, finalExcelReportingName);
  };
  const isNumber = (value) => !isNaN(value);
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className="container-user">
          <JoyRide steps={steps} />
          <div className="tracker-page-head">
            <h1 className="frame_heading">Department Tracker</h1>
            <Box className="tracker-dropdowns-container dept">
              {/* Frequency select */}
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="frequency-label">Select Frequency</InputLabel>
                <Select
                  labelId="frequency-label"
                  id="frequency"
                  label="Select Frequency"
                  value={frequency}
                  onChange={handleChangeFrequency}
                >
                  <MenuItem value="">
                    <em>Select Frequency</em>
                  </MenuItem>
                  <MenuItem value="annual">Annual</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>

              {/* Year/month select based on frequency */}
              {frequency === "annual" && (
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="year-label">Select Year</InputLabel>
                  <Select
                    labelId="year-label"
                    id="year"
                    label="Select Year"
                    value={selectedYear}
                    onChange={handleChangeSelectedYear}
                  >
                    <MenuItem value="">
                      <em>Select Year</em>
                    </MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {frequency === "monthly" && (
                <>
                  <Box className="monthly-tracker-dropdown">
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="month-label">Select Month</InputLabel>
                      <Select
                        labelId="month-label"
                        id="month"
                        label="Select Month"
                        value={selectedMonth}
                        onChange={handleChangeSelectedMonth}
                      >
                        <MenuItem value="">
                          <em>Select Month</em>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                        {showNoMonthsMessage && (
                          <MenuItem value="" disabled>
                            {`No Data stored at a monthly frequency in the year ${selectedYear}`}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="year-label">Select Year</InputLabel>
                      <Select
                        labelId="year-label"
                        id="year"
                        value={selectedYear}
                        label="Select Year"
                        onChange={handleChangeSelectedYear}
                      >
                        <MenuItem value="">
                          <em>Select Year</em>
                        </MenuItem>
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </Box>
            <div className="tracker-search-box">
              <TextField
                id="outlined-basic"
                label="Search Department"
                variant="outlined"
                style={inputfieldstyle2}
                onChange={handleSearchTermChange}
              />
              <Tooltip title="Download">
                <IconButton variant="outlined" onClick={handleDownload} className="dept-tracker-download">
                  <FaFileArrowDown />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {frequency === "monthly" && showNoMonthsMessage && (
            <Typography
              variant="h6"
              sx={{ marginLeft: "18px", fontSize: "14px", color: "red" }}
            >
              No Data stored at a monthly frequency in the year {selectedYear}
            </Typography>
          )}
          <TableContainer style={{ overflow: "scroll" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              {...getTableProps()}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                padding: "10px",
              }}
            >
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    sx={tablehead}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                            color: "#3079bd",
                            fontWeight: "bold",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            fontFamily: "Poppins",
                            justifyContent: "center",
                          }}
                        >
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <MdArrowDownward />
                            ) : (
                              <MdArrowUpward />
                            )
                          ) : (
                            ""
                          )}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <TableCell
                          {...cell.getCellProps()}
                          sx={{
                            // textAlign: isNumber(cell.value) ? "center" : "left",
                            textAlign: "center",
                          }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[8, 12, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
    <div style={{ display: "flex",flexDirection:'column', alignItems: "center" }}>
      <span>Would you like a tour of the page?</span>
      <p style={{color:'red'}}>Click on the icon at top-right corner of the page to have a tour anytime</p>
      <img 
        src={pageguideimg} 
        alt="guide"
      />
    </div>
  }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};

export default DeptCompTable;
