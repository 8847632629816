import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { cancelBtn, okBtn } from "../../styles";
import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';




const ConfirmModal = ({
  open,
  onClose,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  selectedCount,
  totalKpiCount,
  unVisitedKpis
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <h5 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
          Confirm Submitting
        </h5> */}
        <IconButton
          edge="end"
          backgroundColor="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "450px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 style={{ textAlign: "center", marginBottom: "12px" }}>{message}</h2>
        {selectedCount &&
          <Box style={{ display: 'flex' }}>
            <Tooltip title="selected KPI's">
              <div style={{ padding: '10px 20px', background: "#ffd1dc", borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'gray', margin: '0 10px' }}>
                <CheckBoxIcon />
                {selectedCount}</div>
            </Tooltip>



            <Tooltip title="Not visited">
              <div style={{ padding: '10px 20px', background: "gray", borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', margin: '0 10px' }}>
                <VisibilityOffIcon />
                {unVisitedKpis}</div>
            </Tooltip>
            <Tooltip title="Total KPI's">
              <div style={{ padding: '10px 20px', background: "#e3f4f4", borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'gray', margin: '0 10px' }}>
                <AssignmentIcon />
                {/* <p style={{margin:"0px"}}>
                    Total KPI's
                    </p> */}
                {totalKpiCount}</div>
            </Tooltip>
          </Box>
        }
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          margin: "20px 0",
        }}
      >
        <Button onClick={onClose} style={cancelBtn}>
          {cancelText}
        </Button>
        <Button type="button" onClick={onConfirm} style={okBtn}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
