import { combineReducers, configureStore } from "@reduxjs/toolkit";
import kpiReducer from "./slice/KpiSlice";
import { preservedInputsReducer } from "./slice/PreservedSlice";
import tourGuideReducer from "./slice/TourGuideSlice"
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  kpi: kpiReducer,
  preservedInputs: preservedInputsReducer,
  tourGuide: tourGuideReducer
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
