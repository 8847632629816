import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseurl";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import "../Organization/AddDept/AddDept.css";
import { backBtnfixed } from "../../styles";
import Hamburger from "../Navbars/Hamburger";
import * as XLSX from "xlsx";
import { decryptData } from "../../services/crypto";

const DepartmentView = () => {
  const [checkedDepartments, setCheckedDepartments] = useState({});
  const [isTransposed, setIsTransposed] = useState(false); // State to manage transposition
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setnames] = useState([]);
  let data = location.state.user;

  let userData;
  let allDepartments;
  let tableData;
  let allPlants;

  if (data.operationalUnits !== "N/A") {
    let data0 = location.state.data;

    let datau = data0.filter(
      (x) => x.organizationEmail === data.organizationEmail
    );


    data = data.operationalUnits;
    userData = JSON.parse(data);
    const operationalUnitsData = JSON.parse(
      JSON.parse(datau[0].operationalUnits)
    );
    tableData = operationalUnitsData;


    // Extracting all unique departments from the provided data
    allDepartments = Array.from(
      new Set(tableData.flatMap((item) => Object.values(item)[0].split(", ")))
    );
    allPlants = tableData.map((item) => Object.keys(item)[0]);
  }

  const uniqueCategories = Array.from(
    new Set(tableData.flatMap((item) => Object.values(item)[0].split(", ")))
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      await axios
        .get(`${baseUrl}/getAllOrganizations`, { withCredentials: true })
        .then((response) => {
          const decryptedData = decryptData(response.data);
          const parsedData = JSON.parse(decryptedData);
          setnames(parsedData.unmarshalledData);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchUsers = () => {
      // Initialize checkedDepartments based on userData.operationalUnits
      if (userData.operationalUnits !== "N/A") {
        const initialCheckedDepartments = {};

        userData.forEach((unit) => {
          Object.entries(unit).forEach(([plant, categoriesArray]) => {
            initialCheckedDepartments[plant] = categoriesArray;
          });
        });

        setCheckedDepartments(initialCheckedDepartments);
      }
    };

    fetchUsers();
  }, []);

  const handleExcelDownload = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      ["Category/Plant", ...allPlants],
      ...uniqueCategories.map((category) => {
        const rowData = [category];
        allPlants.forEach((plant) => {
          const isChecked =
            checkedDepartments[plant]?.includes(category) || false;
          rowData.push(isChecked ? "Checked" : "Not Checked");
        });
        return rowData;
      }),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Departments");
    XLSX.writeFile(wb, "departments.xlsx");
  };

  const handleTranspose = () => {
    setIsTransposed(!isTransposed);
  };

  function handelsubmit() {
    navigate(-1);
  }

  return (
    <div className="add-dept-table-body">
      <Hamburger />
      {/* <FramerScroll /> */}
      {data.operationalUnits !== "N/A" ? (
        <div className="add-dept-table-container">
          <br />
          <Box style={{ display: "flex", margin: "10px 0 20px 0" }}>
            <h3 className="frame_heading">
              Departments and Operational Units Association Matrix (View Only)
            </h3>
          </Box>
          <TableContainer sx={{ maxHeight: "440px" }}>
            <Table style={{ background: "white" }}>
              <TableBody>
                <TableRow
                  sx={{ position: "sticky", top: "0px", zIndex: 1 }}
                  style={{ backgroundColor: "#fff" }}
                >
                  <TableCell style={{ color: "#3079bd" }}>
                    <Tooltip title="Transpose Table">
                      <Button
                        onClick={handleTranspose}
                        sx={{ textTransform: "none", marginLeft: "10px" }}
                        style={{ color: "#3079bd" }}
                      >
                        {!isTransposed ? "OU" : "Depts"}{" "}
                        <SwapHorizontalCircleIcon
                          style={{ margin: "0 5px", fontSize: "2rem" }}
                        />{" "}
                        {!isTransposed ? "Depts" : "OU"}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  {!isTransposed
                    ? allDepartments.map((department, index) => (
                        <TableCell
                          style={{
                            color: "#3079bd",
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                          key={index}
                        >
                          {department}
                        </TableCell>
                      ))
                    : allPlants.map((plant, index) => (
                        <TableCell
                          style={{
                            color: "#3079bd",
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100px",
                          }}
                          key={index}
                        >
                          {plant}
                        </TableCell>
                      ))}
                </TableRow>
                {!isTransposed
                  ? allPlants.map((plant, index) => (
                      <TableRow key={index}>
                        <TableCell>{plant}</TableCell>
                        {allDepartments.map((department, index) => {
                          const isChecked =
                            !!checkedDepartments[plant]?.includes(department);
                          return (
                            <TableCell key={index}>
                              {tableData.find((unit) =>
                                unit[plant]?.includes(department)
                              ) ? (
                                <Checkbox type="checkbox" checked={isChecked} />
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  : allDepartments.map((department, index) => (
                      <TableRow key={index}>
                        <TableCell>{department}</TableCell>
                        {allPlants.map((plant, index) => {
                          const isChecked =
                            !!checkedDepartments[plant]?.includes(department);
                          return (
                            <TableCell key={index}>
                              {tableData.find((unit) =>
                                unit[plant]?.includes(department)
                              ) ? (
                                <Checkbox type="checkbox" checked={isChecked} />
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button style={backBtnfixed} onClick={handelsubmit}>
            back
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DepartmentView;
