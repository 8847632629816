import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash/lang";
import * as d3 from "d3";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { tablehead } from "../../../styles";

const partition = (data) => {
  const root = d3
    .hierarchy(data)
    .sum((d) => d.size)
    .sort((a, b) => b.size - a.size);
  const partition = d3.partition().size([2 * Math.PI, root.height + 1])(root);
  return partition;
};

function usePrevious(size) {
  const ref = useRef();
  useEffect(() => {
    ref.current = size;
  }, [size]);
  return ref.current;
}

function processNodes(data) {
  const dataCopy = JSON.parse(JSON.stringify(data));

  function combineNodes(nodes) {
    const combined = {};

    nodes.forEach((node) => {
      const [key] = node.name.split("|");
      if (!combined[key]) {
        combined[key] = {
          children: [],
          hasFilled: true,
          email: [],
          number: [],
          DU_OU: [],
          frequency: []
        };
      }
      combined[key].children.push(node);

      const userData = node.name.split("|")[1] || "";
      const emailPattern = /\d+_([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})_/;
      const emailMatch = userData.match(emailPattern);
      const email = emailMatch ? emailMatch[1] : "";

      const numberPattern = /\+(\d{12})/;
      const numberMatch = userData.match(numberPattern);
      const number = numberMatch ? numberMatch[0] : "";

      const emailEndIndex = email ? userData.indexOf(email) + email.length : -1;
      const numberStartIndex = userData.indexOf(number);
      const DU_OU =
        numberStartIndex > emailEndIndex
          ? userData
            .slice(emailEndIndex, numberStartIndex)
            .trim()
            .replace(/_/g, " ")
          : "";
      const frequencyPattern = userData.split(":")[1]

      combined[key].email.push(email);
      combined[key].number.push(number);
      combined[key].DU_OU.push(DU_OU);
      combined[key].frequency.push(frequencyPattern)

      if (
        !node.children ||
        !node.children.some((child) => child.name === "Filled")
      ) {
        combined[key].hasFilled = false;
      }
    });

    return Object.keys(combined).map((key) => {
      if (combined[key].hasFilled) {
        return {
          name: key,
          children: [{ name: "Filled", size: 100 }],
        };
      } else {
        return {
          name: key,
          children: combined[key].children.map((node) => ({
            name: node.name,
            size: node.size || 100,
          })),
          email: combined[key].email,
          number: combined[key].number,
          DU_OU: combined[key].DU_OU,
          frequency: combined[key].frequency
        };
      }
    });
  }

  function traverse(node, level = 1) {
    if (node.children) {
      if (level === 4) {
        node.children = combineNodes(node.children);
      } else {
        node.children.forEach((child) => traverse(child, level + 1));
      }
    }
  }

  traverse(dataCopy);
  return dataCopy;
}

const Sunburst = (props) => {
  const svgRef = useRef();
  const tableContainerRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);

  const handleWheel = (event) => {
    const container = tableContainerRef.current;
    // console.log("1", container);
    if (container) {
      // console.log("2");

      // Prevent event propagation to parent elements
      event.stopPropagation();

      // Manual scrolling
      container.scrollTop += event.deltaY;
    }
  };

  const renderTooltip = () => {
    if (tooltipVisible && tooltipData && tooltipData.emails) {
      return (
        <div
          className="tooltip"
          style={{
            display: "block",
            position: "absolute",
            top: tooltipData.y + "px",
            left: tooltipData.x + "px",
            zIndex: "999",
            backgroundColor: "white",
            pointerEvents: "auto",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            fontFamily: "Arial",
            fontSize: "12px",
            maxWidth: "600px",
          }}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => {
            setIsMouseOverTooltip(false);
            setTooltipVisible(false);
          }}
        >
          <span style={{ marginRight: "8px", fontWeight: 'bold', fontSize: '0.8rem' }}>{tooltipData.name}.</span>
          {/* <span style={{ color: "grey", fontWeight: 'bold' }}>Lorem ipsum lorem ipsum, Lorem ipsum lorem ipsum</span> */}
          <TableContainer
            ref={tableContainerRef}
            sx={{ maxHeight: 440 }}
            style={{ overflow: "scroll" }}
            onWheel={handleWheel}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                // padding: "7px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Emails
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Numbers
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Department & OU
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      // padding: "8px",
                      textAlign: "left",
                      color: "#3079bd",
                    }}
                  >
                    Frequency
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tooltipData.emails.map((email, index) => (
                  <TableRow style={tablehead} key={index}>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                        padding: "0px",
                      }}
                    >
                      {email}
                    </TableCell>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      {tooltipData.numbers[index] || ""}
                    </TableCell>
                    <TableCell
                      style={{
                        // fontSize: "1.1rem",
                        fontFamily: "Poppins",
                      }}
                    >
                      {tooltipData.du[index] || ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                      }}
                    >
                      {tooltipData.frequency[index] || ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else if (tooltipVisible && tooltipData) {
      return (
        <div
          className="tooltip"
          style={{
            display: "block",
            position: "absolute",
            top: tooltipData.y + "px",
            left: tooltipData.x + "px",
            zIndex: "999",
            backgroundColor: "white",
            pointerEvents: "none",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            fontFamily: "Arial",
            fontSize: "12px",
          }}
          onMouseEnter={() => setIsMouseOverTooltip(true)}
          onMouseLeave={() => {
            setIsMouseOverTooltip(false);
            setTooltipVisible(false);
          }}
        >
          <div className="label">{tooltipData.name}</div>
        </div>
      );
    }
    return null;
  };

  const arcVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  };

  const handleMouseOver = (event, d) => {
    if (arcVisible(d.current)) {
      if (d.depth === 4) {
        const isFilled =
          d.children &&
          d.children.some((child) => child.data.name === "Filled");
        if (!isFilled) {
          setTooltipData({
            name: d.data.name,
            emails: d.data.email,
            numbers: d.data.number,
            du: d.data.DU_OU, // Assuming DU_OU only contains DU values
            frequency: d.data.frequency,
            x: event.pageX - 10,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        } else {
          setTooltipData({
            name: isFilled && "Filled",
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        }
      } else if (d.depth <= 3) {
        setTooltipData({
          name: d.data.full_form,
          x: event.pageX - 20,
          y: event.pageY - 170,
        });
        setTooltipVisible(true);
      }
    }
  };

  const handleMouseMove = (event, d) => {
    if (isMouseOverTooltip) return;

    if (arcVisible(d.current)) {
      if (d.depth === 4) {
        const isFilled =
          d.children &&
          d.children.some((child) => child.data.name === "Filled");
        if (!isFilled) {
          setTooltipData({
            name: d.data.name,
            emails: d.data.email,
            numbers: d.data.number,
            du: d.data.DU_OU, // Assuming DU_OU only contains DU values
            frequency: d.data.frequency,
            // x: event.pageX - 20,
            // y: event.pageY - 170,
          });
          setTooltipVisible(true);
        } else {
          setTooltipData({
            name: isFilled && "Filled",
            x: event.pageX - 20,
            y: event.pageY - 170,
          });
          setTooltipVisible(true);
        }
      } else if (d.depth <= 3) {
        setTooltipData({
          name: d.data.full_form,
          x: event.pageX - 20,
          y: event.pageY - 170,
        });
        setTooltipVisible(true);
      }
    }
  };

  // const handleMouseOver = (event, d) => {
  //   if (arcVisible(d.current)) {
  //     if (d.depth === 4) {
  //       const isFilled =
  //         d.children &&
  //         d.children.some((child) => child.data.name === "Filled");
  //       setTooltipData({
  //         name: isFilled
  //           ? "Filled"
  //           : `User Emails: ${d.data.email.join(
  //               ", "
  //             )}\nNumber: ${d.data.number.join(
  //               ", "
  //             )}\nDU_OU: ${d.data.DU_OU.join(", ")}`,
  //         value: d.data.size,
  //         x: event.pageX - 90,
  //         y: event.pageY - 70,
  //       });
  //     } else if (d.depth <= 3) {
  //       setTooltipData({
  //         name: d.data.full_form,
  //         value: d.data.size,
  //         x: event.pageX - 90,
  //         y: event.pageY - 70,
  //       });
  //     }
  //     setTooltipVisible(true);
  //   }
  // };

  // const handleMouseMove = (event, d) => {
  //   if (arcVisible(d.current)) {
  //     if (d.depth === 4) {
  //       const isFilled =
  //         d.children &&
  //         d.children.some((child) => child.data.name === "Filled");
  //       setTooltipData({
  //         name: isFilled
  //           ? "Filled"
  //           : `User Emails: ${d.data.email.join(
  //               ", "
  //             )}\nNumber: ${d.data.number.join(
  //               ", "
  //             )}\nDU_OU: ${d.data.DU_OU.join(", ")}`,
  //         x: event.pageX - 100,
  //         y: event.pageY - 90,
  //       });
  //     } else if (d.depth <= 3) {
  //       setTooltipData({
  //         name: d.data.full_form,
  //         value: d.data.size,
  //         x: event.pageX - 100,
  //         y: event.pageY - 90,
  //       });
  //     }
  //     setTooltipVisible(true);
  //   }
  // };

  const handleMouseOut = () => {
    // setTooltipVisible(false);
    if (!isMouseOverTooltip) {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    renderTooltip();
  }, [tooltipVisible, tooltipData]);

  const prevProps = usePrevious(props);

  const processedData = useMemo(() => {
    if (props.data) {
      return processNodes(props.data);
    }
    return null;
  }, [props.data]);

  useEffect(() => {
    if (!isEqual(prevProps, props) && props.data && props.width) {
      renderSunburst();
    }
  }, [props.data, props.width]);

  const renderSunburst = () => {
    const { data, width, height, offsetX, offsetY } = props;
    if (!data || !data.children || data.children.length === 0) {
      return;
    }

    const color = d3
      .scaleOrdinal()
      .domain(["E", "S", "G", "Multiple", "MPD", "GD"])
      .range([
        "#c1f4d3",
        "#acbbf2",
        "#f4dbc1",
        "#cafbfe",
        "#C0F9F4",
        "#FBDBF9",
      ]);
    document.querySelectorAll("g").forEach((node) => {
      node.remove();
    });

    const radius = 260 / 6;
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .style("font", "10px sans-serif");
    const g = svg
      .append("g")
      .attr(
        "transform",
        props.isMobile
          ? `translate(210,130)`
          : `translate(${width / 2 + offsetX},${height / 2 + offsetY})`
      );
    const root = partition(processedData);
    root.each((d) => (d.current = d));

    const path = g
      .append("g")
      .selectAll("path")
      .data(
        root
          .descendants()
          .slice(1)
          .filter((d) => d.depth <= 4)
      )
      .join("path")
      .attr("fill", (d) => {
        // // console.log("Node:", d.data.name, "Depth:", d.depth, "Children:", d.children);
        // if (d.depth === 3) {
        //   const hasGrandchildWithoutFilledName = d.children.some(
        //     (child) =>
        //       child.children &&
        //       child.children.some(
        //         (grandchild) => grandchild.data.name !== "Filled"
        //       )
        //   );
        //   if (hasGrandchildWithoutFilledName) {
        //     return "grey";
        //   }
        // } else if (d.depth === 4) {
        //   const hasChildWithoutFilledName =
        //     d.children &&
        //     d.children.some((child) => child.data.name !== "Filled");
        //   if (hasChildWithoutFilledName) {
        //     return "grey";
        //   }
        // }
        // if (d.depth === 2) {
        //   const hasChildWithGrey = d.children.some(
        //     (child) =>
        //       child.depth === 3 &&
        //       child.children.some(
        //         (grandchild) =>
        //           grandchild.children &&
        //           grandchild.children.some(
        //             (greatGrandchild) => greatGrandchild.data.name !== "Filled"
        //           )
        //       )
        //   );
        //   if (hasChildWithGrey) {
        //     return "grey";
        //   }
        // }
        // if (d.depth === 1) {
        //   // console.log("1", d.depth);

        //   const hasChildWithGrey = d.children.some(
        //     (child) =>
        //       child.depth === 2 &&
        //       child.children.some(
        //         (grandchild) =>
        //           grandchild.children &&
        //           grandchild.children.some(
        //             (greatGrandchild) => greatGrandchild.data.name !== "Filled"
        //           )
        //       )
        //   );

        //   // console.log("hasChildWithGrey", hasChildWithGrey);

        //   if (hasChildWithGrey) {
        //     return "grey";
        //   }
        // }

        // while (d.depth > 1) d = d.parent;
        // return color(d.data.name);
        const isFilled = (node) => {
          if (node.depth === 4) {
            return node.children
              ? node.children.some(child => child.data.name === "Filled")
              : node.data.size !== undefined;
          }
          return node.children && node.children.every(isFilled);
        };

        if (d.depth === 4) {
          if (isFilled(d)) {
            while (d.depth > 1) d = d.parent;
            return color(d.data.name);
          }
          return "grey";
        }

        if (d.depth === 3) {
          if (d.children && d.children.every(isFilled)) {
            while (d.depth > 1) d = d.parent;
            return color(d.data.name);
          }
          return "grey";
        }

        if (d.depth === 2) {
          if (d.children && d.children.every(child =>
            child.children && child.children.every(isFilled))) {
            while (d.depth > 1) d = d.parent;
            return color(d.data.name);
          }
          return "grey";
        }

        if (d.depth === 1) {
          if (d.children && d.children.every(child =>
            child.children && child.children.every(grandchild =>
              grandchild.children && grandchild.children.every(isFilled)))) {
            return color(d.data.name);
          }
          return "grey";
        }

        // Fallback
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 1 : 1) : 0
      )
      .attr("d", (d) => {
        return d3
          .arc()
          .startAngle((d) => d.x0)
          .endAngle((d) => d.x1)
          .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
          .padRadius(radius * 1.5)
          .innerRadius((d) => d.y0 * radius)
          .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))(
            d.current
          );
      });

    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("mousemove", handleMouseMove);

    const label = g
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(
        root
          .descendants()
          .slice(1)
          .filter((d) => d.depth <= 4)
      )
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => d.data.name)
      .style("font-size", "8px");

    const parent = g
      .append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .style("cursor", "pointer")
      .on("click", clicked);

    function clicked(event, p) {
      console.log(p);
      if (p.depth === 4 && p.children) {
        return;
      }

      if (p.depth === 3) {
        p.children.forEach((child) => console.log(child.data.name));
        p.children.forEach((child) => {
          if (child.children) {
            child.children.forEach((grandchild) =>
              console.log(grandchild.data.name)
            );
          }
        });
        return;
      }

      if (p.depth > 2) {
        return;
      }

      parent.datum(p.parent || root);
      g.selectAll(".middle-text").remove();

      if (p.depth !== 0) {
        const backIcon = `
<svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g transform="rotate(250, 12, 12)">
    <path d="M12.207 2.293a1 1 0 0 1 0 1.414L10.914 5H12.5c4.652 0 8.5 3.848 8.5 8.5S17.152 22 12.5 22 4 18.152 4 13.5a1 1 0 1 1 2 0c0 3.548 2.952 6.5 6.5 6.5s6.5-2.952 6.5-6.5S16.048 7 12.5 7h-1.586l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0z" fill="#2f7abd
"/>
  </g>
</svg>        `

        const foreignObject = g
          .append("foreignObject")
          .attr("class", "middle-text")
          .attr("text-anchor", "middle")
          .attr("width", 50)
          .attr("height", 50)
          .attr("x", -13)
          .attr("y", -13)
          .style("cursor", "pointer")
          .html(backIcon);

        // Add click handler to the foreignObject
        foreignObject.on("click", () => {
          clicked(null, p.parent || root);
        });
      }

      root.each(
        (d) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        })
      );

      const t = g.transition().duration(750);

      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 1 : 1) : 0
        )
        .attrTween("d", (d) => () => {
          return d3
            .arc()
            .startAngle((d) => d.x0)
            .endAngle((d) => d.x1)
            .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d) => d.y0 * radius)
            .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))(
              d.current
            );
        });

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.05;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  };
  return (
    <>
      {
        props?.data?.children?.length != 0 ?
          <div id={props.keyId} className="text-center">
            {renderTooltip()}
            <svg ref={svgRef} id={`${props.keyId}-svg`} />
          </div>
          :
          <div id={props.keyId} className="text-center">
            <svg
              viewBox={[0, 0, 430, 260]}
              style={{
                font: "10px sans-serif",
                overflow: "visible",
                transform: props.isMobile ? `translate(210,130)` : `translate(${430 / 2 + 50}px, ${260 / 2 + 0}px)`
              }}
            >
              {
                props?.isMobile ?
                  <text
                    x="50%"
                    y="50%"
                    fontSize="15"
                    fill="black"
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    No Assignment Completed !!
                  </text>
                  :
                  <text x="70" y="50" font-size="10px" fill="black">
                    No Assignment Completed !!
                  </text>
              }
            </svg>
          </div>
      }
    </>
  );
};

export default Sunburst;
