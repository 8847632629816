import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../baseurl";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  buttoncontaindedfixed,
  inputfieldstyle,
  inputfieldstyle1,
} from "../../styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import EditOrgMultiStep from "./EditOrgMultiStep";
import { decryptData } from "../../services/crypto";
import { getOrg, getOrgEmail } from "../../services/loggedIn";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const EditOrgDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state.user;
  // // console.log("user", user);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [organizationName, setorganizationName] = useState(
    user.organizationName
  );
  const [organizationEmail, setOrganizationEmail] = useState(
    user.organizationEmail
  );
  
  const [type, setType] = useState(user.type);
  const [address, setAddress] = useState(user.address);
  const [mobile, setMobile] = useState(user.telephoneNumber);
  const [spocEmail, setSpocEmail] = useState(user.spocEmail);
  const [spocName, setSpocName] = useState(user.spocName);
  const [spocMobile, setSpocMobile] = useState(user.spocMobile);
  const [mobileError, setMobileError] = useState(false);
  const [spocmobileError, setSpocMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [spocemailError, setSpocEmailError] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState(user.country);
  const [countryCode, setCode] = useState(user.countryCode);
  const [allManagers, setAllManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState(
    user.selectedManagers
  );
  const [selectedStartOfReporting, setSelectedStartOfReporting] = useState(
    user.startOfReporting ? parseDate(user.startOfReporting) : null
  );
  const [selectedEndOfReporting, setSelectedEndOfReporting] = useState(
    user?.endOfReporting
      ? parseDate(user.endOfReporting)
      : null
  );
  const [endReportingError, setEndReportingError] = useState("");

  function parseDate(dateString) {
    const [month, year] = dateString.split("'");
    return new Date(
      parseInt(`20${year}`),
      new Date(Date.parse(month + " 1, 2000")).getMonth(),
      1
    );
  }

  const formatDate = (date) => {
    if (!date) return "";
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().substr(-2);

    return `${month}'${year}`;
  };

  const availableManagers = user.selectedManagers;

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setMobileError(!isValid);
    return isValid;
  };
  const validateSpocMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setSpocMobileError(!isValid);
    return isValid;
  };

  const validateEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(!isValid);
    return isValid;
  };

  const validateDomainMatch = (orgEmail, spocEmail) => {
    const orgDomain = orgEmail.split("@")[1];
    const spocDomain = spocEmail.split("@")[1];
    return orgDomain === spocDomain;
  };

  const validateSpocEmail = (value) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const domainMatch = validateDomainMatch(organizationEmail, value);
    setSpocEmailError(!isValid || !domainMatch);
    return isValid && domainMatch;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setMobile(limitedValue);
  };
  const handleSpocMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);

    setSpocMobile(limitedValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setOrganizationEmail(value);
    validateEmail(value);
    validateSpocEmail(spocEmail);
  };
  const handleSpocEmailChange = (e) => {
    const value = e.target.value;
    setSpocEmail(value);
    validateSpocEmail(value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`);
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const getAllEnvintUsers = async () => {
      try {
        const organizationEmail = getOrgEmail();
        // // console.log("orgname", organizationEmail);

        const res = await axios.post(
          `${baseUrl}/getAllManagersAndConsultant`,
          { organizationEmail },
          { withCredentials: true }
        );
        const decryptedData = decryptData(res.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);
        // // console.log("parsedData", parsedData);
        setAllManagers(parsedData.users.Manager);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
    getAllEnvintUsers();
  }, []);
  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
    } else {
      setCountry(""); // Handle case where user clears the selection
      setCode("");
    }
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const { priorityTags, spocPassword, operationalUnits, departments } = user;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(organizationEmail) || !validateSpocEmail(spocEmail)) {
      return; // Stop submission if emails are invalid
    }

    if (spocMobile.length === 10) {
      const orginitialData = {
        organizationName: organizationName,
        organizationEmail: organizationEmail,
        departments,
        operationalUnits,
        mobile,
        countryCode,
        country,
        spocPassword,
        priorityTags,
        type,
        address,
        spocEmail,
        spocMobile,
        spocName,
        selectedManagers,
        startOfReporting: formatDate(selectedStartOfReporting),
        endOfReporting: formatDate(selectedEndOfReporting),
        isAssigned: user.isAssigned,
      };
      navigate("/organization/edit-dept", {
        state: {
          orginitialData: orginitialData,
          skills: location.state.skills,
          parsedDepts: location.state.parsedDepts,
          selectedDept: location.state.selectedDept,
          operationalUnits: location.state.operationalUnits,
          selectedItems: location.state.selectedItems,
          availableManagers: availableManagers,
          deptEdited: location.state.deptEdited,
          ouEdited: location.state.ouEdited,
        },
      });
      //   handleClose();
    } else {
      toast.error("Mobile number should be of 10 digits");
    }
  };
  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
  };

  const step = 0;

  // Function to check if a date is in a later period than the start date
  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) return false;
    return endDate > startDate;
  };

  const getTooltipContent = (projects) => {
    if (projects.length === 0) {
      return "No projects assigned";
    }

    return (
      <div>
        <strong>{projects.length} Project{projects.length !== 1 ? 's' : ''} Assigned:</strong>
        <ul style={{
          margin: '4px 0 0 0',
          paddingLeft: '16px',
          maxHeight: '200px',
          overflow: 'auto'
        }}>
          {projects.map((project, index) => (
            <li key={index} style={{ color: 'white' }}>
              {project.orgName}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="add-container">
      <Box sx={{ marginBottom: "20px" }}>
        <EditOrgMultiStep step={step} />
      </Box>
      <h3 className="frame_heading">Organization Details</h3>
      <Box
        sx={{
          width: "50%",
          margin: "0px auto",
          padding: "20px",
          borderRadius: "20px",
        }}
        boxShadow={3}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="OrganizationEmail"
                variant="outlined"
                type="email"
                value={organizationEmail}
                onChange={handleEmailChange}
                style={inputfieldstyle1}
                required
                disabled
                error={emailError}
                helperText={emailError ? "Enter a valid email address" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                type="text"
                value={organizationName}
                onChange={(e) => setorganizationName(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={inputfieldstyle1}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  defaultValue={type}
                  label="Age"
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"Client"}>Client</MenuItem>
                  <MenuItem value={"Envint"}>Envint</MenuItem>
                  <MenuItem value={"Partner"}>Partner</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                variant="outlined"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                type="tel"
                value={mobile}
                onChange={handleMobileNumberChange}
                style={inputfieldstyle1}
                error={mobileError}
                helperText={mobileError ? "Enter only numbers" : ""}
                required
              />
            </Grid> */}
            <Grid item xs={12}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => `${option.label} (${option.value})`}
                renderInput={(params) => (
                  <TextField {...params} label="Select a country" />
                )}
                onChange={handleDropdownChange}
                style={inputfieldstyle}
                value={{ label: country, value: countryCode }} // Set default value here
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label && option.value === value.value
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="SPOC Email"
                variant="outlined"
                type="tel"
                value={spocEmail}
                onChange={handleSpocEmailChange}
                style={inputfieldstyle1}
                required
                error={spocemailError}
                helperText={
                  spocemailError
                    ? spocEmail.includes("@")
                      ? "SPOC email domain must match organization email domain"
                      : "Enter a valid email address"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="SPOC Name"
                variant="outlined"
                type="tel"
                value={spocName}
                onChange={(e) => setSpocName(e.target.value)}
                style={inputfieldstyle1}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="SPOC Mobile"
                variant="outlined"
                type="tel"
                value={spocMobile}
                onChange={handleSpocMobileNumberChange}
                style={inputfieldstyle1}
                error={spocmobileError}
                helperText={spocmobileError ? "Enter only numbers" : ""}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start of Reporting"
                  views={["year", "month"]}
                  value={selectedStartOfReporting}
                  onChange={(newValue) => {
                    setSelectedStartOfReporting(newValue);
                    // Reset end date if it's no longer valid
                    if (selectedEndOfReporting && !isEndDateValid(newValue, selectedEndOfReporting)) {
                      setSelectedEndOfReporting(null);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End of Reporting"
                  views={["year", "month"]}
                  value={selectedEndOfReporting}
                  onChange={(newValue) => {
                    // Check date validity before setting
                    if (selectedStartOfReporting) {
                      if (newValue <= selectedStartOfReporting) {
                        setEndReportingError("End of Reporting must be after Start of Reporting");
                      } else {
                        setEndReportingError("");
                      }
                    }
                    setSelectedEndOfReporting(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth disabled={!selectedStartOfReporting} error={!!endReportingError}
                      helperText={endReportingError} />
                  )}
                  minDate={selectedStartOfReporting} // Set minimum date to start of reporting
                  // maxDate={new Date()} // Prevent selecting future dates
                  disabled={!selectedStartOfReporting} // Disable picker if no start date
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="manager-label">Select Manager</InputLabel>
                <Select
                  labelId="manager-label"
                  id="manager-label"
                  label="Select Manager"
                  value={selectedManagers}
                  onChange={handleManagerChange}
                  renderValue={(selected) => {
                    // Find the manager object for the selected email
                    const selectedManager = allManagers?.find(
                      (manager) => manager.email === selected
                    );
                    // Return the selected manager name
                    return selectedManager ? selectedManager.userName : "";
                  }}
                >
                  {allManagers?.map((manager, index) =>
                    isMediumScreen ? (
                      <MenuItem key={index} value={manager.email}>
                        <ListItemText
                          primary={manager.userName}
                          secondary={manager.email}
                        />
                      </MenuItem>
                    ) : (
                      <MenuItem key={index} value={manager.email}>
                        <Box width="100%">
                          <Tooltip title={getTooltipContent(manager.projects)}
                            placement="right"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'rgba(0, 0, 0, 0.87)',
                                  '& .MuiTooltip-arrow': {
                                    color: 'rgba(0, 0, 0, 0.87)'
                                  },
                                  maxWidth: 'none'
                                }
                              }
                            }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <ListItemText primary={manager.userName} />
                              </Grid>
                              <Grid item xs={6}>
                                <ListItemText secondary={manager.email} />
                              </Grid>
                            </Grid>
                          </Tooltip>
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <br />
          <Button type="submit" style={buttoncontaindedfixed}>
            Continue
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default EditOrgDetails;
