import React from "react";
import Hamburger from "../Navbars/Hamburger";

const ClientLayout = ({ children }) => {
  return (
    <div>
      <Hamburger />
      {children}
    </div>
  );
};

export default ClientLayout;
