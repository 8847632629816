import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
  } from "@mui/material";
  import React from "react";
  import {
    cancelBtn,
    modalSubmitBtn,
  } from "../../../styles.js";
  import CloseIcon from "@mui/icons-material/Close";
  
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
  
  const AcceptModal = ({
      message,
      closePopup,
    openPopup,
    handleSave,
    approvedCount,
    rejectedCount,
    total,
    handleApprovalRejectionSubmit
  }) => {
    return (
      <Dialog
        onClose={closePopup}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
            Confirmation
          </h4>
          <IconButton
            edge="end"
            backgroundColor="inherit"
            onClick={closePopup}
            aria-label="close"
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent style={{ width: "450px",display:'flex',flexDirection:'column',alignItems:'center' }}>
        <p>Are you sure you want to submit the data ? This action cannot be undone</p>
          <Box style={{display:'flex'}}>
                  <Tooltip title="Rejected">
                  <div style={{padding:'10px 20px',background:"#ffd1dc",borderRadius:'15px',display:'flex',flexDirection:'column',alignItems:'center',color:'gray',margin:'0 10px'}}>
                  <ThumbDownIcon/>
                  {rejectedCount}</div>
                  </Tooltip>

                  <Tooltip title="Approved">
                  <div style={{padding:'10px 20px',background:"#e3f4f4",borderRadius:'15px',display:'flex',flexDirection:'column',alignItems:'center',color:'gray',margin:'0 10px'}}>
                  <ThumbUpIcon/>
                  {approvedCount}</div>
                  </Tooltip>

                  <Tooltip title="Not visited">
                  <div style={{padding:'10px 20px',background:"gray",borderRadius:'15px',display:'flex',flexDirection:'column',alignItems:'center',color:'white',margin:'0 10px'}}>
                  <NotInterestedIcon/>
                  {total-approvedCount-rejectedCount}</div>
                  </Tooltip>
                </Box>
        </DialogContent>
        <br />
        <DialogActions sx={{ padding: " 10px 20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            
              <Button type="button" style={cancelBtn} onClick={closePopup}>
                Cancel
              </Button>
           
            <Button type="submit"  style={modalSubmitBtn} onClick={handleApprovalRejectionSubmit}>
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default AcceptModal;
  