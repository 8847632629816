import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { updatePreservedInput, clearPreservedInputs } from '../../../redux/slice/PreservedSlice';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  backBtnfixed,
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../../styles";
import "../clientdashboard.css";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GeneralTables } from "./GeneralTables";
import Slide from "@mui/material/Slide";
import { FrequencyFilter } from "../../../components/Filter/FrequencyFilter";
import { handleSaveDraft, handleSubmit } from "../ExtraFiles/formHandler";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import {
  countryCodes,
  getAssignmentKPIData,
} from "../../../services/ClientSideApis";
import BasicModal from "../../../components/PopUpDialog/BasicModal";
import SimpleModal from "../../../components/PopUpDialog/SimpleModal";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import LinearProgressBar from "./ProgressBar/LinearProgressBar";
import { Loader } from "../../../components/Loader/Loader";
import { getUserEmail } from "../../../services/loggedIn";
import {
  MdNotifications,
} from "react-icons/md";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThankyouModal from "../../../components/PopUpDialog/ThankyouModal";
import { getLoginAttempts } from "../../../services/loggedIn";
import { JoyRide } from "../../../Joyride";
import tabless from "../../../assets/images/tablearrow.png";
import inputss from "../../../assets/images/inputarrow.png";
import CommentIcon from '@mui/icons-material/Comment';
import { CommentPopUp } from "../../../dashboard/pages/SPOCDataReview/CommentPopUp";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import pageguideimg from '../../../navspoc (2).jpg'


export const DataAccordionNew = () => {
  const [fdata, setData] = useState(null);
  const [monthData, setMonthData] = useState(null);
  const [yearData, setYearData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const {
    savedCount,
    totalCount,
    orgplt,
    orgdept,
    approvedCount,
    rejectedCount,
  } = data;
  const [kpiinputdata, setKpiinputdata] = useState();
  const [tableRendering, setTableRendering] = useState();
  const [tableDraftRendering, setTableDraftRendering] = useState();
  const [formData, setFormData] = useState({});
  const [formDataWithKpiStatus, setFormDataWithKpiStatus] = useState({});
  const [reviewedStatus, setReviewedStatus] = useState({})
  const [currentYears, setCurrentYears] = useState({});
  const [currentMonths, setCurrentMonths] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupProps, setPopupProps] = useState({});
  const [statusdata, setStatusdata] = useState({});
  const [editedFields, setEditedFields] = useState([]);
  const [popupload, setPopupLoad] = useState(false);
  const [filterOption, setFilterOption] = useState("all");
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [code, setCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [kpiStatus, setKPIStatus] = useState({});
  const [openDesModal, setOpenDesModal] = useState(false);
  const [singalKpiData, setSingalKpiData] = useState();
  const [kpiIndex, setKpiIndex] = useState("");
  const [isReviewed, setIsReviewed] = useState();
  const [reviewedBy, setReviwedBy] = useState("");
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [kpiCodeForComments, setKpiCodeForComments] = useState("")


  const loginAttempts = getLoginAttempts();
  const dispatch = useDispatch();
  const preservedInputs = useSelector((state) => state.preservedInputs);
  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Initialize the component with the current year's data
    if (fdata && typeof fdata === 'object') {
      Object.keys(fdata).forEach(theme => {
        fdata[theme].forEach(item => {
          const kpiCode = item.KPICode;
          const uniqueKey = `${kpiCode}-${orgdept}-${orgplt}`;
          const currentYear = new Date().getFullYear();
          const currentYearRange = `${currentYear}-${currentYear + 1}`;

          // Check if the key matches in preservedInputs
          if (preservedInputs[uniqueKey]) {
            // Check if the year matches
            if (preservedInputs[uniqueKey][currentYearRange] !== undefined) {
              setFormData(prevData => ({
                ...prevData,
                [kpiCode]: preservedInputs[uniqueKey][currentYearRange]
              }));
            } else {
              setFormData(prevData => ({
                ...prevData,
                [kpiCode]: item.KPIInput
              }));
            }
          } else {
            setFormData(prevData => ({
              ...prevData,
              [kpiCode]: item.KPIInput
            }));
          }


          // setCurrentYears(prevYears => ({
          //   ...prevYears,
          //   [kpiCode]: currentYears[kpiCode]
          //   // [kpiCode]: currentYearRange
          // }));
          // setCurrentMonths(prevMonths => ({
          //   ...prevMonths,
          //   [kpiCode]: currentMonths[kpiCode]
          //   // ...currentMonths[kpiCode]
          // }))
          setFormDataWithKpiStatus(prevStatus => ({
            ...prevStatus,
            [kpiCode]: item.KPIStatus
          }));
          setReviewedStatus(prevReviewed => ({
            ...prevReviewed,
            [kpiCode]: item.isReviewed
          }));
        });
      })
    }
  }, [fdata, orgdept, orgplt]);


  const handleCommentBoxOpen = (detail, index) => {
    setKpiCodeForComments(detail?.KPICode)
    setCommentBoxOpen(true);
  };

  const handleCommentBoxClose = () => {
    setCommentBoxOpen(false);
  };


  const countryCurrency = JSON.parse(decryptData(localStorage.getItem("countryCurrency")))

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const steps = [
    {
      target: ".frequency-filter-rd",
      content: "Filter KPIs based on annual and monthly frequency",
      disableBeacon: true,
    },
    {
      target: ".data-count",
      content: "Filled to Total Assigned KPIs count with progress bar",
    },
    {
      target: ".notif",
      content: "Click on the icon to view rejected/approved KPIs by the SPOC",
    },
    {
      target: ".accord-client",
      content: (
        <div>
          <p>Fill respones and click Next button to draft/submit data.</p>
          <p>
            In case of tabular inputs, hit the save button before final
            submission,else the data will not be saved
          </p>
          <img
            src={tabless}
            style={{ width: "100%", height: "100%" }}
            alt="TAbles"
          />
          <img
            src={inputss}
            style={{ width: "100%", height: "100%" }}
            alt="Input fields"
          />
          <p>
            Previously filled data can be accessed by clicking on the Year/Month
            sliders just below the input fields
          </p>
        </div>
      ),
      styles: {
        tooltip: {
          width: "50vw",
          height: "50vh",
          overflow: "auto",
        },
      },
    },
  ];

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  // useEffect(() => {
  //   // Clear preservedInputs when the component mounts
  //   dispatch(clearPreservedInputs());

  //   // Optional: If you want to clear inputs based on a dependency change,
  //   // add the dependencies in the dependency array below (e.g., someVariable).
  // }, [dispatch]);

  const handleOpenDecsModal = (KPIData, kpiIndex) => {
    setOpenDesModal(true);
    setSingalKpiData(KPIData);
    setKpiIndex(kpiIndex);
  };

  const handleAccordionOpenChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();

      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        fetchData();
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await countryCodes();
        setCountryData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  const updateDrafTableData = [];
  const mergeFormDataWithPreservedInputs = () => {
    // const mergedData = { ...formData };
    const mergedData = Object.entries(formData).reduce((acc, [key, value]) => {
      if (!Array.isArray(value)) {
        acc[key] = value;
      }
      return acc;
    }, {});
    const currentDeptOU = `-${data.orgdept}-${data.orgplt}`;

    Object.keys(preservedInputs).forEach((kpiCode) => {
      // Only process preserved inputs for the current department and OU
      // console.log("kpiCode", kpiCode);

      if (!kpiCode.endsWith(currentDeptOU)) {
        return;
      }

      // Extract the base KPICode
      const baseKpiCode = kpiCode.split('-')[0]; // Keeps only the part before the first '-'

      // Ensure mergedData[baseKpiCode] is an extensible object
      if (typeof mergedData[baseKpiCode] !== 'object' || mergedData[baseKpiCode] === null) {
        mergedData[baseKpiCode] = Object.create(null); // Create a new, extensible object
      } else if (!Object.isExtensible(mergedData[baseKpiCode])) {
        // If the object exists but is not extensible, create a new extensible copy
        mergedData[baseKpiCode] = Object.assign(Object.create(null), mergedData[baseKpiCode]);
      }

      // Add preserved inputs for each year/month into mergedData
      Object.keys(preservedInputs[kpiCode]).forEach((period) => {
        mergedData[baseKpiCode][period] = preservedInputs[kpiCode][period];
      });
    });

    return mergedData;
  };

  // The handleClick and handleClickDraft functions can remain the same
  const handleClick = () => {
    try {
      const mergedFormData = mergeFormDataWithPreservedInputs();
      const returnValue = handleSubmit(
        dispatch,
        updateDrafTableData,
        mergedFormData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        editedFields,
        code,
        setError
      );
      return returnValue;
    } catch (error) {
      setError(error.message)
    }

  };


  const handleClickDraft = () => {
    const mergedFormData = mergeFormDataWithPreservedInputs();
    const returnValue = handleSaveDraft(
      dispatch,
      updateDrafTableData,
      mergedFormData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      setIsSavingDraft,
      editedFields,
      code,
      true
    );
    return returnValue;
  };


  const closePopup = () => {
    setPopupOpen(false);
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    fetchData();
  }, [data.orgdept, data.orgplt]);


  const fetchData = async () => {
    try {
      setLoading(true);
      const res = {
        operationalUnit: data.orgplt,
        department: data.orgdept,
        userEmail: getUserEmail(),
      };

      const encryptData = encrypt({ res });

      const response = await getAssignmentKPIData(encryptData);

      const decryptedData = decryptData(response.data.KPIData);
      const parsedData = JSON.parse(decryptedData);

      const decryptedMonthData = decryptData(response.data.KPIMonthData);
      const parsedMonthData = JSON.parse(decryptedMonthData);

      const decryptedYearData = decryptData(response.data.KPIAnnualData);
      const parsedYearData = JSON.parse(decryptedYearData);

      setData(parsedData.themeBasedGrouped);
      setMonthData(parsedMonthData.monthDataGrouped);
      setYearData(parsedYearData.annualDataGrouped);

      const initialFormData = {};
      const initialFormDataWithKpiStatus = {};
      const initialFormDataWithReviwedStatus = {};
      const initialFormDataWithComments = {};
      const initialCurrentYears = {};
      const initialCurrentMonths = {};
      const initialKPIStatus = {};
      const transformedData = {};

      // Process the fetched data
      Object.keys(parsedData.themeBasedGrouped).forEach((theme) => {
        parsedData.themeBasedGrouped[theme].forEach((entry) => {
          const kpiCode = entry.KPICode;
          const monthYear = entry.Month ? entry.Month : entry.Year;
          const status = entry.KPIStatus;

          if (!transformedData[kpiCode]) {
            transformedData[kpiCode] = [];
          }

          transformedData[kpiCode].push({ [monthYear]: status });
          initialKPIStatus[kpiCode] = status; // Store the KPI status
        });
      });
      setStatusdata(transformedData);

      Object.keys(parsedData.themeBasedGrouped).forEach((theme) => {
        parsedData.themeBasedGrouped[theme].forEach((detail) => {
          if (detail.KPICode) {
            if (
              (detail.KPIStatus === "saved" ||
                detail.KPIStatus === "approved") &&
              detail.Format.includes("Table")
            ) {
              setTableRendering(detail.KPIInput);
            }

            if (
              detail.KPIStatus === "drafted" &&
              detail.Format.includes("Table")
            ) {
              setTableDraftRendering(detail.KPIInput);
            }
            if (detail.Year) {
              initialCurrentYears[detail.KPICode] = detail.Year;
            } else if (detail.Month) {
              initialCurrentMonths[detail.KPICode] = detail.Month;
            }
            initialFormData[detail.KPICode] = detail.KPIInput;
            initialFormDataWithKpiStatus[detail.KPICode] = detail.KPIStatus;
            initialFormDataWithReviwedStatus[detail.KPICode] = detail.isReviewed;
            initialFormDataWithComments[detail.KPICode] = detail.comments;
          }
          if (detail.isReviewed === true) {
            setIsReviewed(detail.isReviewed);
            setReviwedBy(detail.reviewedBy);
          }
        });
      });


      // Set the state with the fetched data
      setFormData(initialFormData);
      setFormDataWithKpiStatus(initialFormDataWithKpiStatus);
      setReviewedStatus(initialFormDataWithReviwedStatus);
      setComments(initialFormDataWithComments)
      setCurrentYears(initialCurrentYears);
      setCurrentMonths(initialCurrentMonths);
      setKPIStatus(initialKPIStatus);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: "100%", height: '100vh' }}>Something Went Wrong! or Please check your internet connection and try again later</div>
  }


  if (kpiinputdata) {

    Object.entries(kpiinputdata).forEach(([key, value]) => {
      const [tableFrequency, tableKPICode] = key.split("-");
      const tableKPIInput = value;

      Object.values(fdata).forEach((themeArray) => {
        themeArray.forEach((detail) => {
          if (
            detail.KPICode === tableKPICode &&
            (detail.Year === tableFrequency || detail.Month === tableFrequency)
          ) {
            if (detail.Year) {
              currentYears[detail.KPICode] = detail.Year;
            } else if (detail.Month) {
              currentMonths[detail.KPICode] = detail.Month;
            }
            formData[detail.KPICode] = tableKPIInput;
            // console.log(" formData[detail.KPICode]", formData[detail.KPICode]);

          }
        });
      });
    });
  }


  const getCurrentPeriod = (kpiCode) => {
    return currentYears[kpiCode] || currentMonths[kpiCode];
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("telephone+")) {
      const kpiCode = name.split("+")[1];
      const newValue = {
        countryCode: code,
        number: value,
      };
      const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;

      setFormData((prevState) => ({
        ...prevState,
        [kpiCode]: newValue,
      }));


      // Update Redux state
      dispatch(updatePreservedInput({
        kpiCode: uniqueKey, // Use the composite key here
        period: getCurrentPeriod(kpiCode),
        value: newValue,
        department: data.orgdept,
        operationalUnit: data.orgplt
      }));
    } else if (name.includes("+")) {
      const [type, kpiCode] = name.split("+");

      if (type === "x" || type === "y") {
        const ratioObject = formData[kpiCode] || { x: "", y: "" };
        const newValue = { ...ratioObject, [type]: value };
        const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;

        setFormData((prevState) => ({
          ...prevState,
          [kpiCode]: newValue,
        }));

        dispatch(updatePreservedInput({
          kpiCode: uniqueKey, // Use the composite key here
          period: getCurrentPeriod(kpiCode),
          value: newValue,
          department: data.orgdept,
          operationalUnit: data.orgplt
        }));
      }
    } else {
      const newValue = value;
      const uniqueKey = `${name}-${data.orgdept}-${data.orgplt}`;

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));


      dispatch(updatePreservedInput({
        kpiCode: uniqueKey, // Use the composite key here
        period: getCurrentPeriod(name),
        value: newValue,
        department: data.orgdept,
        operationalUnit: data.orgplt
      }));
    }
  };


  const isFieldEdited = (kpiCode) => {
    return editedFields.includes(kpiCode);
  };

  const handleInputChange = (e, kpiCode) => {
    if (!isFieldEdited(kpiCode)) {
      setEditedFields([...editedFields, kpiCode]);
    }
    handleChange(e);
  };

  const handleInputBlur = (e, kpiCode) => {
    const { name, value } = e.target;

    let newValue;

    if (name.includes("+")) {
      const [type] = name.split("+");

      if (type === "x" || type === "y") {
        const ratioObject = formData[kpiCode] || { x: "", y: "" };
        newValue = { ...ratioObject, [type]: value };
      } else {
        newValue = value;
      }
    } else {
      newValue = value;
    }

    setFormData((prevData) => ({
      ...prevData,
      [kpiCode]: newValue,
    }));

    setEditedFields((prevFields) => {
      if (!prevFields.includes(kpiCode)) {
        return [...prevFields, kpiCode];
      }
      return prevFields;
    });
  };

  // Handle next year
  const handleNextYear = (theme, kpiCode) => {
    const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;
    const currentYearRange = currentYears[kpiCode].split("-");
    const startYear = parseInt(currentYearRange[0]);
    const endYear = parseInt(currentYearRange[1]);
    const nextStartYear = startYear + 1;
    const nextEndYear = endYear + 1;
    const nextYearRange = `${nextStartYear}-${nextEndYear}`;

    const nextYearData = fdata[theme].find(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === nextStartYear
    );

    if (nextYearData) {
      const newCurrentYears = { ...currentYears, [kpiCode]: nextYearRange };
      setCurrentYears(newCurrentYears);

      // Retrieve preserved value using the unique key
      const preservedValue = preservedInputs[uniqueKey]?.[nextYearRange];
      const newValue = preservedValue !== undefined ? preservedValue : nextYearData.KPIInput;

      const newData = { ...formData, [kpiCode]: newValue };
      const newDataWithKpiStatus = {
        ...formDataWithKpiStatus,
        [kpiCode]: nextYearData.KPIStatus,
      };
      const newDataWithKpiReviewedStatus = {
        ...reviewedStatus,
        [kpiCode]: nextYearData.isReviewed,
      };
      const newDataWithComments = {
        ...comments,
        [kpiCode]: nextYearData.comments
      }

      if (Array.isArray(nextYearData.KPIInput) && nextYearData.KPIInput.length > 0) {
        setTableRendering(nextYearData.KPIInput);
      }

      setFormData(newData);
      setFormDataWithKpiStatus(newDataWithKpiStatus);
      setReviewedStatus(newDataWithKpiReviewedStatus);
      setComments(newDataWithComments)
    } else {
      showAlert(`No next year data available for ${kpiCode}.`);
    }
  };

  // Handle previous year
  const handlePreviousYear = (theme, kpiCode) => {
    const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;
    const currentYearRange = currentYears[kpiCode].split("-");
    const startYear = parseInt(currentYearRange[0]);
    const endYear = parseInt(currentYearRange[1]);
    const prevStartYear = startYear - 1;
    const prevEndYear = endYear - 1;
    const prevYearRange = `${prevStartYear}-${prevEndYear}`;

    const prevYearData = fdata[theme].find(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === prevStartYear
    );

    if (prevYearData) {
      const newCurrentYears = { ...currentYears, [kpiCode]: prevYearRange };
      setCurrentYears(newCurrentYears);

      // Retrieve preserved value using the unique key
      const preservedValue = preservedInputs[uniqueKey]?.[prevYearRange];
      const newValue = preservedValue !== undefined ? preservedValue : prevYearData.KPIInput;

      const newData = { ...formData, [kpiCode]: newValue };
      const newDataWithKpiStatus = {
        ...formDataWithKpiStatus,
        [kpiCode]: prevYearData.KPIStatus,
      };
      const newDataWithKpiReviewedStatus = {
        ...reviewedStatus,
        [kpiCode]: prevYearData.isReviewed,
      };
      const newDataWithComments = {
        ...comments,
        [kpiCode]: prevYearData.comments
      }

      if (Array.isArray(prevYearData.KPIInput) && prevYearData.KPIInput.length > 0) {
        setTableRendering(prevYearData.KPIInput);
      }

      setFormData(newData);
      setFormDataWithKpiStatus(newDataWithKpiStatus);
      setReviewedStatus(newDataWithKpiReviewedStatus);
      setComments(newDataWithComments)
    } else {
      showAlert(`No previous year data available for ${kpiCode}.`);
    }
  };

  const getNextMonth = (currentMonth) => {
    const [monthAbbreviation, year] = currentMonth.split("'");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthIndex = monthNames.indexOf(monthAbbreviation);
    let nextYear = parseInt(year);

    monthIndex++; // Move to the next month

    if (monthIndex === 12) {
      // If next month is December, increment the year
      monthIndex = 0; // January
      nextYear++;
    }

    const nextMonthAbbreviation = monthNames[monthIndex];
    return `${nextMonthAbbreviation}'${nextYear.toString().slice(-2)}`;
  };

  const getPreviousMonth = (currentMonth) => {
    const [monthAbbreviation, year] = currentMonth.split("'");
    let monthIndex = monthNames.indexOf(monthAbbreviation);
    let prevYear = parseInt(year);

    monthIndex--; // Move to the previous month

    if (monthIndex === -1) {
      // If previous month is December, decrement the year
      monthIndex = 11; // December
      prevYear--;
    }

    const prevMonthAbbreviation = monthNames[monthIndex];
    return `${prevMonthAbbreviation}'${prevYear.toString().slice(-2)}`;
  };

  const hasPreviousMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const [currentMonthAbbreviation, currentYear] = currentMonth.split("'");
    const currentMonthIndex = monthNames.indexOf(currentMonthAbbreviation);
    let prevYear = parseInt(currentYear);
    let prevMonthIndex = currentMonthIndex - 1;

    // If the current month is January, decrement the year
    if (prevMonthIndex === -1) {
      prevMonthIndex = 11; // December
      prevYear--;
    }

    const prevMonthAbbreviation = monthNames[prevMonthIndex];
    const prevMonth = `${prevMonthAbbreviation}'${prevYear
      .toString()
      .slice(-2)}`;

    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month === prevMonth
    );
  };

  // Function to check if there is data available for the next month
  const hasNextMonth = (theme, kpiCode) => {
    const currentMonth = currentMonths[kpiCode];
    const [currentMonthAbbreviation, currentYear] = currentMonth.split("'");
    const currentMonthIndex = monthNames.indexOf(currentMonthAbbreviation);
    let nextYear = parseInt(currentYear);
    let nextMonthIndex = currentMonthIndex + 1;

    // If the current month is December, increment the year
    if (nextMonthIndex === 12) {
      nextMonthIndex = 0; // January
      nextYear++;
    }

    const nextMonthAbbreviation = monthNames[nextMonthIndex];
    const nextMonth = `${nextMonthAbbreviation}'${nextYear
      .toString()
      .slice(-2)}`;

    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month === nextMonth
    );
  };

  const handleNextMonth = (theme, kpiCode) => {
    const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;
    const currentMonth = currentMonths[kpiCode];
    const nextMonth = getNextMonth(currentMonth);
    const nextMonthData = fdata[theme].find(
      (item) => item.KPICode === kpiCode && item.Month === nextMonth
    );

    if (nextMonthData) {
      const newCurrentMonths = { ...currentMonths, [kpiCode]: nextMonth };
      setCurrentMonths(newCurrentMonths);

      // Use preserved input if available, otherwise use nextMonthData.KPIInput
      const preservedValue = preservedInputs[uniqueKey]?.[nextMonth];
      const newValue = preservedValue !== undefined ? preservedValue : nextMonthData.KPIInput;

      const newData = { ...formData, [kpiCode]: newValue };
      const newDataWithKpiStatus = {
        ...formDataWithKpiStatus,
        [kpiCode]: nextMonthData.KPIStatus,
      };
      const newDataWithKpiReviewedStatus = {
        ...reviewedStatus,
        [kpiCode]: nextMonthData.isReviewed,
      };
      const newDataWithComments = {
        ...comments,
        [kpiCode]: nextMonthData.comments
      }

      setFormData(newData);
      setFormDataWithKpiStatus(newDataWithKpiStatus);
      setReviewedStatus(newDataWithKpiReviewedStatus);
      setComments(newDataWithComments)

      // Optional: Set table rendering if necessary
      if (Array.isArray(nextMonthData.KPIInput) && nextMonthData.KPIInput.length > 0) {
        setTableRendering(nextMonthData.KPIInput);
      }
    } else {
      showAlert(`No next month data available for ${kpiCode}.`);
    }
  };

  const handlePreviousMonth = (theme, kpiCode) => {
    const uniqueKey = `${kpiCode}-${data.orgdept}-${data.orgplt}`;
    const currentMonth = currentMonths[kpiCode];
    const prevMonth = getPreviousMonth(currentMonth);
    const prevMonthData = fdata[theme].find(
      (item) => item.KPICode === kpiCode && item.Month === prevMonth
    );

    if (prevMonthData) {
      const newCurrentMonths = { ...currentMonths, [kpiCode]: prevMonth };
      setCurrentMonths(newCurrentMonths);

      // Use preserved input if available, otherwise use prevMonthData.KPIInput
      const preservedValue = preservedInputs[uniqueKey]?.[prevMonth];
      const newValue = preservedValue !== undefined ? preservedValue : prevMonthData.KPIInput;

      const newData = { ...formData, [kpiCode]: newValue };
      const newDataWithKpiStatus = {
        ...formDataWithKpiStatus,
        [kpiCode]: prevMonthData.KPIStatus,
      };
      const newDataWithKpiReviewedStatus = {
        ...reviewedStatus,
        [kpiCode]: prevMonthData.isReviewed,
      };
      const newDataWithComments = {
        ...comments,
        [kpiCode]: prevMonthData.comments
      }

      setFormData(newData);
      setFormDataWithKpiStatus(newDataWithKpiStatus);
      setReviewedStatus(newDataWithKpiReviewedStatus);
      setComments(newDataWithComments);

      // Optional: Set table rendering if necessary
      if (Array.isArray(prevMonthData.KPIInput) && prevMonthData.KPIInput.length > 0) {
        setTableRendering(prevMonthData.KPIInput);
      }
    } else {
      showAlert(`No previous month data available for ${kpiCode}.`);
    }
  };


  const showAlert = (message) => {
    toast.info(message);
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!fdata) {
    return null;
  }

  const hasMonthData = (theme, kpiCode) => {
    return fdata[theme].some(
      (item) => item.KPICode === kpiCode && item.Month !== undefined
    );
  };

  const hasYearData = (theme, code) => {
    return fdata[theme].some(
      (item) => item.KPICode === code && item.Year !== undefined
    );
  };

  const themes = Object.keys(fdata);

  const getYearRange = (year) => {
    return `${year}`;
  };

  const hasPreviousYear = (theme, kpiCode) => {

    const currentYearRange = currentYears[kpiCode]?.split("-");
    const startYear = parseInt(currentYearRange[0]);
    const prevStartYear = startYear - 1;
    return fdata[theme].some(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[0]) === prevStartYear
    );
  };

  // Function to check if there is data available for the next year
  const hasNextYear = (theme, kpiCode) => {
    const currentYearRange = currentYears[kpiCode].split("-");
    const endYear = parseInt(currentYearRange[1]);
    const nextEndYear = endYear + 1;
    return fdata[theme].some(
      (item) =>
        item.KPICode === kpiCode &&
        item.Year &&
        parseInt(item.Year.split("-")[1]) === nextEndYear
    );
  };

  const getPreviousYearRange = (yearRange) => {
    if (!yearRange) return ""; // Return an empty string or handle it as per your requirement
    const [startYear, endYear] = yearRange.split("-");
    const prevStartYear = parseInt(startYear) - 1;
    const prevEndYear = parseInt(endYear) - 1;
    return `${prevStartYear}-${prevEndYear}`;
  };

  const getNextYearRange = (yearRange) => {
    if (!yearRange) return ""; // Return an empty string or handle it as per your requirement
    const [startYear, endYear] = yearRange.split("-");
    const nextStartYear = parseInt(startYear) + 1;
    const nextEndYear = parseInt(endYear) + 1;
    return `${nextStartYear}-${nextEndYear}`;
  };

  const getMonthYear = (month) => {
    const [monthName, year] = month.split("'");
    return { month: monthName, year: parseInt(year) };
  };

  const getPrevMonth = (currentMonth) => {
    const { month, year } = getMonthYear(currentMonth);
    const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();
    let prevYear = year; // Initialize previous year with the current year

    // If the current month is January, decrement the year
    if (monthIndex === 0) {
      prevYear -= 1;
    }

    const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1;
    const prevMonth = new Date(prevYear, prevMonthIndex).toLocaleDateString(
      "default",
      { month: "short" }
    );
    return `${prevMonth}'${prevYear.toString().slice(-2)}`;
  };

  const getMonth = (currentMonth) => {
    const { month, year } = getMonthYear(currentMonth);
    const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();
    let nextYear = year; // Initialize next year with the current year

    if (monthIndex === 11) {
      nextYear += 1;
    }

    const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1;
    const nextMonth = new Date(nextYear, nextMonthIndex).toLocaleDateString(
      "default",
      { month: "short" }
    );
    return `${nextMonth}'${nextYear.toString().slice(-2)}`;
  };


  const handleSavedTable = async (incommingTableForamt) => {

    let filter = [];
    filledTable.map((table) => {
      if (
        table.Format === incommingTableForamt &&
        (table.KPIStatus === "saved" || table.KPIStatus === "approved")
      ) {
        filter.push(table);
      }
    });

    if (filter.length > 0) {
      navigate("/reporting/saved-drafted", {
        state: {
          orgplt: orgplt,
          orgdept: orgdept,
          responseSaved: filter,
          tdata: incommingTableForamt,
        },
      });
    } else {
      toast.warning("No Saved Data Present");
    }
  };

  const handleDraftTable = async (incommingTableForamt) => {
    let filterDrated = [];
    draftedTable.map((table, index) => {
      if (
        table.Format === incommingTableForamt &&
        table.KPIStatus === "drafted"
      ) {
        filterDrated.push(table);
      }
    });

    if (filterDrated.length > 0) {
      navigate("/reporting/saved-drafted", {
        state: {
          orgplt: orgplt,
          orgdept: orgdept,
          responseDrafted: filterDrated,
          tdata: incommingTableForamt,
          fdata: fdata
        },
      });
    } else {
      toast.warning("No Draft Data Present");
    }
  };

  let filledTable = [];
  let unfilledTables = [];
  let draftedTable = [];
  const processedEntries = new Set();

  const uniqueKPICodes = new Set();
  themes.forEach((theme) => {
    if (fdata[theme]) {
      fdata[theme].forEach((detail, i) => {
        if (detail.Format.includes("Table")) {
          const uniqueKey = `${detail.KPICode}_${detail.Year || detail.Month}`;
          if (!processedEntries.has(uniqueKey)) {
            processedEntries.add(uniqueKey);

            switch (detail.KPIStatus) {
              case "saved":
              case "approved":
                filledTable.push(detail);
                break;
              case "drafted":
                draftedTable.push(detail);
                break;
              case "unfilled":
                unfilledTables.push(detail);
                break;
              default:
                console.warn(`Unexpected KPIStatus: ${detail.KPIStatus} for KPI: ${detail.KPICode}`);
                break;
            }
          }
          // if (detail.KPIStatus === "saved" || detail.KPIStatus === "approved") {
          //   filledTable.push(detail);
          // }
          // if (detail.KPIStatus === "drafted") {
          //   draftedTable.push(detail);
          // }
          // if (detail.KPIStatus === "unfilled") {

          //   unfilledTables.push(detail);
          // }
        }
      });
    }
  });

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterOption(selectedFilter);
  };

  let dataToMap;
  const draft = (detail) => {
    const status = currentMonths[detail.KPICode]
      ? statusdata[detail.KPICode].find((entry) =>
        entry.hasOwnProperty(currentMonths[detail.KPICode])
      )?.[currentMonths[detail.KPICode]]
      : statusdata[detail.KPICode].find((entry) =>
        entry.hasOwnProperty(currentYears[detail.KPICode])
      )?.[currentYears[detail.KPICode]];

    return status === "drafted";
  };

  const saved = (detail) => {
    if (currentMonths[detail.KPICode]) {
      const status = statusdata[detail.KPICode].find((entry) =>
        entry.hasOwnProperty(currentMonths[detail.KPICode])
      )?.[currentMonths[detail.KPICode]];
      return status === "saved" || status === "approved";
    } else {
      const status = statusdata[detail.KPICode].find((entry) =>
        entry.hasOwnProperty(currentYears[detail.KPICode])
      )?.[currentYears[detail.KPICode]];
      return status === "saved" || status === "approved";
    }
  };

  const renderNumbers = (KpiData) => {
    const arrayLength = KpiData?.length;
    const savedCount = KpiData?.filter(
      (item) => item.KPIStatus === "saved" || item.KPIStatus === "approved"
    ).length;
    return (
      <h3
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 10px",
        }}
      >
        {savedCount ? savedCount : 0}/
        <span style={{ fontSize: "14px" }}>
          {arrayLength ? arrayLength : 0}
        </span>{" "}
      </h3>
    );
  };

  const renderWarningIcon = (KpiData) => {
    const isReviewedTrue = KpiData?.filter((singleKpi, index) => {
      return singleKpi.isReviewed === true && singleKpi.KPIStatus === "drafted";
    });

    return isReviewedTrue?.length > 0 ? (
      <Tooltip
        title="Contains Rejected Response"
        placement="bottom"
        PopperProps={{
          style: { zIndex: 1500 },
        }}
      >
        <ErrorOutlineIcon
          style={{
            color: "#ff9c00",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    ) : null;
  };

  const renderTables = (detail) => {
    return unfilledTables
      .filter(table => table.Format === detail.Format && table.KPICode === detail.KPICode)
      .map((table, key) => (
        <React.Fragment key={key}>
          <br />
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            {table.Month ? `Month: ${table.Month}` : `Year: ${table.Year}`}
            <br />
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}>
              <Button
                onClick={() => handleSavedTable(detail.Format)}
                style={{ border: "1px solid #3079bd" }}
                className="view-previous-tab"
              >
                View Previous Tables
              </Button>
              <Button
                onClick={() => handleDraftTable(detail.Format)}
                style={{ border: "1px solid #3079bd" }}
                className="draft-previous-tab"
              >
                Draft Tables
              </Button>
            </div>
          </div>
          <GeneralTables
            orgplt={orgplt}
            orgdept={orgdept}
            setTableFromat={table.Format}
            frequency={table?.Month || table?.Year}
            setKpiinputdata={setKpiinputdata}
            tableRendering={tableRendering}
            status={table.KPIStatus}
            kpicode={table.KPICode}
          />
        </React.Fragment>
      ));
  };


  return (
    <div style={{ padding: "20px" }}>
      <ClientLayout>
        <JoyRide
          steps={steps}
        />
        <form
          onSubmit={(e) => e.preventDefault()}
          className="client-dashboard-container"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="frame_heading">Fill KPI's for :</h1>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgdept}`}</span>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgplt}`}</span>
            </div>
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <FrequencyFilter
                filterOption={filterOption}
                handleFilterChange={handleFilterChange}
              />
              <div
                className={
                  isReviewed === true &&
                  (approvedCount > 0 || rejectedCount > 0) &&
                  "profile-icon"
                }
              >
                <MdNotifications
                  style={{
                    fontSize: "28px",
                    color:
                      isReviewed === true &&
                        (approvedCount > 0 || rejectedCount > 0)
                        ? "#3079bd"
                        : "#000000",
                  }}
                  className="notif"
                />
                <div className="notification-icon">
                  {isReviewed === true &&
                    (approvedCount > 0 || rejectedCount > 0) && (
                      <div className="notify"></div>
                    )}
                </div>
                <div
                  style={{ width: "max-content" }}
                  className="profile-dropdown"
                >
                  <Box
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Approved">
                      <div
                        style={{
                          padding: "10px 20px",
                          background: "#e3f4f4",
                          borderRadius: "15px",
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          color: "gray",
                          margin: "0 10px",
                        }}
                      >
                        <ThumbUpIcon />
                        {approvedCount}
                        {/* 1 */}
                      </div>
                    </Tooltip>
                    <Tooltip title="Rejected">
                      <div
                        style={{
                          padding: "10px 20px",
                          background: "#ffd1dc",
                          borderRadius: "15px",
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          color: "gray",
                          margin: "0 10px",
                        }}
                      >
                        <ThumbDownIcon />
                        {rejectedCount}
                        {/* 2 */}
                      </div>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "self-start",
                      justifyContent: "center",
                      gap: "12px",
                    }}
                  >
                    <span style={{ color: "#8C8D91" }}>Reviewd By: </span>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "self-start",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        {reviewedBy.spocName}
                      </span>
                      <span style={{ color: "#8C8D91", fontSize: "14px" }}>
                        {reviewedBy.spocEmail}
                      </span>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </Box>
          <Divider />
          <br />
          {themes.map((theme, key) => (
            <Accordion
              sx={{ position: "relative", zIndex: 0 }}
              key={key}
              className="accordion accord-client"
              expanded={expanded === key}
              onChange={handleAccordionOpenChange(key)}
            >
              <AccordionSummary
                sx={{ zIndex: 0 }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4>{theme.replaceAll("_", " ")}</h4>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      gap: "20px",
                    }}
                  >
                    {renderWarningIcon(
                      filterOption === "annual"
                        ? yearData[theme]
                        : filterOption === "monthly"
                          ? monthData[theme]
                          : fdata[theme]
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "20px",
                        width: "285px",
                      }}
                      className="data-count"
                    >
                      {
                        <LinearProgressBar
                          KpiData={
                            filterOption === "annual"
                              ? yearData[theme]
                              : filterOption === "monthly"
                                ? monthData[theme]
                                : fdata[theme]
                          }
                        />
                      }
                      {renderNumbers(
                        filterOption === "annual"
                          ? yearData[theme]
                          : filterOption === "monthly"
                            ? monthData[theme]
                            : fdata[theme]
                      )}
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              {(() => {
                const dataToMap = filterOption === "annual"
                  ? yearData[theme]
                  : filterOption === "monthly"
                    ? monthData[theme]
                    : fdata[theme];

                return dataToMap && dataToMap.length > 0 ? (
                  dataToMap.map((detail, i) => {
                    const uniqueIdentifier = detail.KPICode;

                    if (!uniqueKPICodes.has(uniqueIdentifier)) {
                      uniqueKPICodes.add(uniqueIdentifier);

                      return (
                        // detail.isReviewed === true
                        <>
                          <AccordionDetails
                            sx={{
                              borderRadius: "20px",
                              border: reviewedStatus[detail.KPICode] === true
                                &&
                                formDataWithKpiStatus[detail.KPICode] ===
                                "approved" ? "1px solid #45b653" : reviewedStatus[detail.KPICode] === true &&
                                  formDataWithKpiStatus[detail.KPICode] ===
                                  "drafted" ? "1px solid red" : "",
                              backgroundColor:
                                reviewedStatus[detail.KPICode] === true &&
                                  formDataWithKpiStatus[detail.KPICode] ===
                                  "approved"
                                  ? "#e3f4f4"
                                  : reviewedStatus[detail.KPICode] === true &&
                                    formDataWithKpiStatus[detail.KPICode] ===
                                    "drafted"
                                    ?
                                    "#ffd1dc"
                                    : "transparent",
                              margin: "12px 0px",
                              // display: 'flex',
                              // alignItems: "center",
                              // justifyContent: "space-between",

                            }}
                            key={i}
                          >
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                  justifyContent: 'space-between'
                                }}
                              >
                                <div style={
                                  {
                                    display: "flex",
                                    gap: "16px",
                                    alignItems: "center",
                                    justifyContent: 'center'
                                  }
                                }>
                                  <p style={{ padding: "30px 0px", margin: "0px" }}>
                                    {i + 1}.{detail.KPIdata}{" "}
                                  </p>
                                  {reviewedStatus[detail.KPICode] === true &&
                                    formDataWithKpiStatus[detail.KPICode] ===
                                    "drafted" &&
                                    renderWarningIcon(
                                      filterOption === "annual"
                                        ? yearData[theme]
                                        : filterOption === "monthly"
                                          ? monthData[theme]
                                          : fdata[theme]
                                    )}
                                </div>
                                {!detail.Format.includes("Table") &&
                                  <div style={{
                                    display: "flex",
                                    gap: "16px",
                                    alignItems: "center",
                                    justifyContent: 'center'
                                  }}>
                                    <Tooltip title="Guide">
                                      <IconButton
                                        disabled={detail.Description === undefined}
                                        className="thumbdown"
                                        onClick={() =>
                                          handleOpenDecsModal(detail, i)
                                        }
                                      >
                                        <MenuBookIcon style={{ color: "rgb(69, 182, 83)" }}
                                        />{" "}
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Comment">
                                      <span>
                                        <IconButton
                                          className="thumbdown"
                                          onClick={() => handleCommentBoxOpen(detail, i)}
                                          disabled={(formDataWithKpiStatus[detail.KPICode] ===
                                            "approved") || (formDataWithKpiStatus[detail.KPICode] ===
                                              "unfilled") || (formDataWithKpiStatus[detail.KPICode] ===
                                                "saved")}
                                        >
                                          <CommentIcon style={{ color: "#3079bd" }} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </div>
                                }
                              </div>
                              {detail.KPICode &&
                                hasYearData(theme, detail.KPICode) &&
                                !detail.Format.includes("Table") && (
                                  <p>
                                    Year:{" "}
                                    {getYearRange(currentYears[detail.KPICode])}
                                  </p>
                                )}
                              {detail.KPICode &&
                                hasMonthData(theme, detail.KPICode) &&
                                !detail.Format.includes("Table") && (
                                  <p>Month: {currentMonths[detail.KPICode]}</p>
                                )}
                            </>

                            {/* {detail.Format.includes("Table") &&
                            // // // console.log("detail", detail)} */}
                            {detail.Format.includes("Table") && renderTables(detail)}
                            {detail.Format.includes("Table") && (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                marginTop="10px"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleSavedTable(detail.Format)
                                    }
                                    style={{
                                      border: "1px solid #3079bd",
                                    }}
                                  >
                                    View Previous Tables
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleDraftTable(detail.Format)
                                    }
                                    style={{
                                      border: "1px solid #3079bd",
                                    }}
                                  >
                                    Draft Tables
                                  </Button>
                                </div>
                              </Box>
                            )}
                            {(() => {
                              const validateNumberInput = (value) => {
                                const regex =
                                  /^(^$)|(^(0(\.\d{0,4})?|([1-9]\d{0,12})(\.\d{0,4})?))$/;
                                return regex.test(value);
                              };
                              const handleNumChange = (e) => {
                                const { name, value } = e.target;
                                if (validateNumberInput(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  const validationToastId = 'invalid-percent-format';
                                  if (!toast.isActive(validationToastId)) {
                                    toast.error(
                                      "Please enter a valid number which is not more that 13 of length and less then four decimal places.",
                                      { toastId: validationToastId }
                                    );
                                  }
                                }
                              };
                              const validatePercentage = (value) => {
                                const regex =
                                  /^$|^(?:100(?:\.0{1,2})?|\d{0,2}(?:\.\d{0,2})?)$/;
                                return regex.test(value);
                              };

                              const validatePhoneNumber = (number) => {
                                const phoneRegex = /^[+\d]+(?:[\s.-]*\d){10,}$/;
                                return phoneRegex.test(number);
                              };

                              const handlePhoneChange = (e) => {
                                const { name, value } = e.target;

                                if (validatePh(value)) {
                                  handleChange({
                                    target: {
                                      name,
                                      value,
                                    },
                                  });
                                } else {
                                  toast.error("Please enter a valid number.");
                                }
                              };

                              function validatePh(e) {
                                const phoneRegex = /^\d{0,10}$/;
                                return phoneRegex.test(e);
                              }

                              // Function to handle percentage input change
                              const handlePercentageChange = (e) => {
                                const { name, value } = e.target;
                                if (validatePercentage(value)) {
                                  handleChange(e, detail.KPICode);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  // Show error dynamically
                                  const validationToastId = 'invalid-percent-format';
                                  if (!toast.isActive(validationToastId)) {
                                    toast.error(
                                      "Please enter a valid percentage (greater than 0 and less than or equal to 100).", {
                                      toastId: validationToastId
                                    }
                                    );
                                  }
                                }
                              };
                              const validateURL = (value) => {
                                // const validateURL = (value) => {
                                const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                                const isValid = regex.test(value);
                                if (!isValid) {
                                  toast.error("Invalid URL format!");
                                }
                                return isValid;
                              };

                              const handleUrlChange = (e) => {
                                const { name, value } = e.target;
                                if (validateURL(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error("Please enter a valid URL.");
                                }
                              };

                              function validateWholeNumber(value) {
                                // Check if the entered value is a positive whole number
                                const isWholeNumber = /^\d{0, 13}$/.test(value);

                                return isWholeNumber;
                              }

                              const handleWholeNumChange = (e) => {
                                const { name, value } = e.target;
                                if (validateWholeNumber(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error(
                                    "Please enter a valid whole number."
                                  );
                                }
                              };

                              const validateSpace = (value) => {
                                const noLeadingSpace =
                                  value === "" || /^[^\s].*/.test(value);
                                return noLeadingSpace;
                              };

                              const handleOpenTextChange = (e) => {
                                const { name, value } = e.target;
                                if (validateSpace(value)) {
                                  handleChange(e);
                                  handleInputChange(e, detail.KPICode);
                                } else {
                                  toast.error("No Leading Space Allowed.");
                                }
                              };

                              if (detail.Format.includes("Table")) {
                              } else if (detail.Format.includes("Ratio (")) {
                                const n = detail.Format.split(/[()|]/);
                                const nue = n[1].trim();
                                const d = n[2].trim();
                                return (
                                  <>
                                    {Object.keys(
                                      formDataWithKpiStatus
                                    ).includes(detail.KPICode) &&
                                      formDataWithKpiStatus[detail.KPICode] !==
                                      "unfilled" && (
                                        <h5
                                          style={{
                                            opacity: "0.5",
                                          }}
                                        >
                                          {
                                            formDataWithKpiStatus[
                                            detail.KPICode
                                            ]
                                          }
                                        </h5>
                                      )}
                                    <input
                                      type="number"
                                      value={formData[detail.KPICode]?.x || ""}
                                      style={{
                                        width: "16%",
                                        backgroundColor: draft(detail)
                                          ? "#CBD5C0"
                                          : saved(detail)
                                            ? "#EEEAEA"
                                            : "",
                                      }}
                                      onChange={(e) => {
                                        handleNumChange(e);
                                      }}
                                      placeholder={nue}
                                      name={`x+${detail.KPICode}`}
                                      disabled={saved(detail)}
                                    />
                                    <span> : </span>
                                    <input
                                      type="number"
                                      value={formData[detail.KPICode]?.y || ""}
                                      style={{
                                        width: "16%",
                                        backgroundColor: draft(detail)
                                          ? "#CBD5C0"
                                          : "white",
                                      }}
                                      onChange={(e) => {
                                        handleNumChange(e);
                                      }}
                                      placeholder={d}
                                      name={`y+${detail.KPICode}`}
                                      disabled={saved(detail)}
                                    />
                                  </>
                                );
                              } else {
                                switch (detail.Format) {
                                  case "Binary":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "20px",
                                          }}
                                        >
                                          <label
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              name={detail.KPICode}
                                              value="yes"
                                              checked={
                                                formData[detail.KPICode] ===
                                                "yes"
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e,
                                                  detail.KPICode
                                                );
                                              }}
                                              style={{
                                                backgroundcolor: draft(detail)
                                                  ? "#CBD5C0"
                                                  : saved(detail)
                                                    ? "#EEEAEA"
                                                    : "",
                                              }}
                                              disabled={saved(detail)}
                                              onBlur={(e) =>
                                                handleInputBlur(
                                                  e,
                                                  detail.KPICode
                                                )
                                              }
                                            />
                                            <span
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              Yes
                                            </span>
                                          </label>
                                          <label
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              value="no"
                                              name={detail.KPICode}
                                              checked={
                                                formData[detail.KPICode] ===
                                                "no"
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e,
                                                  detail.KPICode
                                                );
                                              }}
                                              style={{
                                                backgroundColor: draft(detail)
                                                  ? "#CBD5C0"
                                                  : saved(detail)
                                                    ? "#EEEAEA"
                                                    : "",
                                              }}
                                              disabled={saved(detail)}
                                              onBlur={(e) =>
                                                handleInputBlur(
                                                  e,
                                                  detail.KPICode
                                                )
                                              }
                                            />
                                            <span
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              No
                                            </span>
                                          </label>
                                        </div>
                                      </>
                                    );
                                  case "Number":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={
                                            formData[detail.KPICode]
                                              ? formData[detail.KPICode]
                                              : ""
                                          }
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "Currency":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={formData[detail.KPICode]}
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            marginRight: '10px',
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                        <span>
                                          {countryCurrency}{" "}
                                        </span>
                                      </>
                                    );
                                  case "MCQ":
                                    return (
                                      <div>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                          }}
                                        >
                                          {detail.Remarks
                                            ? detail.Remarks.split(",").map(
                                              (option, index) => (
                                                <label
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "3px",
                                                  }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="radio"
                                                    name={detail.KPICode}
                                                    value={option}
                                                    checked={
                                                      formData[
                                                      detail.KPICode
                                                      ] === option
                                                    }
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      handleInputChange(
                                                        e,
                                                        detail.KPICode
                                                      );
                                                    }}
                                                    disabled={saved(detail)}
                                                    style={{
                                                      width: "16%",
                                                      marginRight: "5px",
                                                      backgroundColor: draft(
                                                        detail
                                                      )
                                                        ? "#CBD5C0"
                                                        : saved(detail)
                                                          ? "#EEEAEA"
                                                          : "",
                                                    }}
                                                    onBlur={(e) =>
                                                      handleInputBlur(
                                                        e,
                                                        detail.KPICode
                                                      )
                                                    }
                                                  />
                                                  {option}
                                                </label>
                                              )
                                            )
                                            : "No remark from Backend"}
                                        </div>
                                      </div>
                                    );
                                  case "Percent":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="percent"
                                          name={detail.KPICode}
                                          value={
                                            formData[detail.KPICode]
                                              ? formData[
                                                detail.KPICode
                                              ].replace("%", "")
                                              : ""
                                          }
                                          disabled={saved(detail)}
                                          placeholder={detail.Format}
                                          style={{
                                            width: "8%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          min="0"
                                          max="100"
                                          onChange={(e) => {
                                            handlePercentageChange(e);
                                          }}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />{" "}
                                        %
                                      </>
                                    );
                                  case "Ratio":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="number"
                                          value={
                                            formData[detail.KPICode]?.x || ""
                                          }
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          placeholder="Numerator"
                                          name={`x+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                        <span> : </span>
                                        <input
                                          type="number"
                                          value={
                                            formData[detail.KPICode]?.y || ""
                                          }
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          onChange={(e) => {
                                            handleNumChange(e);
                                          }}
                                          placeholder="Denominator"
                                          name={`y+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "Telephone":
                                    let parsedData;
                                    if (
                                      formData[detail.KPICode] !== undefined &&
                                      formData[detail.KPICode] !== null
                                    ) {
                                      try {
                                        parsedData = JSON.parse(
                                          formData[detail.KPICode]
                                        );
                                      } catch (error) {
                                        console.error(
                                          "Failed to parse JSON:",
                                          error
                                        );
                                        parsedData = {};
                                      }
                                    } else {
                                      parsedData = {};
                                    }
                                    const number = parsedData?.number || "";
                                    const countryCode =
                                      parsedData?.countryCode || "";
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <span>
                                          <select
                                            value={countryCode || code}
                                            onChange={(e) =>
                                              setCode(e.target.value)
                                            }
                                            style={{
                                              width: "180px",
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                  ? "#EEEAEA"
                                                  : "",
                                            }}
                                            disabled={saved(detail)}
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          >
                                            <option value="">
                                              Select country
                                            </option>
                                            {Object.entries(countryData).map(
                                              ([country, code]) => (
                                                <option
                                                  onBlur={(e) =>
                                                    handleInputBlur(
                                                      e,
                                                      detail.KPICode
                                                    )
                                                  }
                                                  key={country}
                                                  value={code}
                                                >
                                                  {country} {"(" + code + ")"}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {"  "}
                                          <input
                                            type="text"
                                            name={detail.KPICode}
                                            value={
                                              number
                                                ? number
                                                : formData[detail.KPICode]
                                            }
                                            style={{
                                              width: "16%",
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                  ? "#EEEAEA"
                                                  : "",
                                            }}
                                            placeholder="Telephone"
                                            disabled={saved(detail)}
                                            onChange={(e) =>
                                              handlePhoneChange(e)
                                            }
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          />
                                        </span>
                                      </>
                                    );

                                  case "url":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="text"
                                          value={
                                            formData[detail.KPICode]?.y || ""
                                          }
                                          placeholder="Enter URL"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            if (validateURL(value)) {
                                              handleUrlChange(e);
                                            }
                                          }}
                                          name={`y+${detail.KPICode}`}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  case "wholeNumber":
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <input
                                          type="text"
                                          name={detail.KPICode}
                                          value={formData[detail.KPICode]}
                                          placeholder={detail.Format}
                                          className="client-form-input"
                                          style={{
                                            width: "16%",
                                            backgroundColor: draft(detail)
                                              ? "#CBD5C0"
                                              : saved(detail)
                                                ? "#EEEAEA"
                                                : "",
                                          }}
                                          step="0.01"
                                          onChange={(e) => {
                                            handleWholeNumChange(e);
                                          }}
                                          disabled={saved(detail)}
                                          onBlur={(e) =>
                                            handleInputBlur(e, detail.KPICode)
                                          }
                                        />
                                      </>
                                    );
                                  default:
                                    return (
                                      <>
                                        {Object.keys(
                                          formDataWithKpiStatus
                                        ).includes(detail.KPICode) &&
                                          formDataWithKpiStatus[
                                          detail.KPICode
                                          ] !== "unfilled" && (
                                            <h5
                                              style={{
                                                opacity: "0.5",
                                                paddingBottom: "8px",
                                              }}
                                            >
                                              {
                                                formDataWithKpiStatus[
                                                detail.KPICode
                                                ]
                                              }
                                            </h5>
                                          )}
                                        <div>
                                          <textarea
                                            name={detail.KPICode}
                                            onChange={(e) => {
                                              handleOpenTextChange(e);
                                            }}
                                            placeholder={"Enter the response"}
                                            value={
                                              formData[detail.KPICode] || ""
                                            }
                                            rows="8"
                                            // cols="230"
                                            style={{
                                              backgroundColor: draft(detail)
                                                ? "#CBD5C0"
                                                : saved(detail)
                                                  ? "#EEEAEA"
                                                  : "",
                                              maxWidth: "100%",
                                              minWidth: "100%",
                                            }}
                                            disabled={saved(detail)}
                                            onBlur={(e) =>
                                              handleInputBlur(e, detail.KPICode)
                                            }
                                          />
                                        </div>
                                      </>
                                    );
                                }
                              }
                            })()}
                            <div className="year-month-sliders">
                              {!detail.Format.includes("Table") &&
                                hasYearData(theme, detail.KPICode) && (
                                  <div>
                                    <Button
                                      onClick={() =>
                                        handlePreviousYear(
                                          theme,
                                          detail.KPICode
                                        )
                                      }
                                      disabled={
                                        !hasPreviousYear(theme, detail.KPICode)
                                      }
                                    >
                                      {getPreviousYearRange(
                                        currentYears[detail.KPICode]
                                      )}
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleNextYear(theme, detail.KPICode)
                                      }
                                      disabled={
                                        !hasNextYear(theme, detail.KPICode)
                                      }
                                    >
                                      {getNextYearRange(
                                        currentYears[detail.KPICode]
                                      )}
                                    </Button>
                                  </div>
                                )}
                              <div>
                                {!detail.Format.includes("Table") &&
                                  hasMonthData(theme, detail.KPICode) && (
                                    <>
                                      <Button
                                        onClick={() =>
                                          handlePreviousMonth(
                                            theme,
                                            detail.KPICode
                                          )
                                        }
                                        disabled={
                                          !hasPreviousMonth(
                                            theme,
                                            detail.KPICode
                                          )
                                        }
                                      >
                                        {getPrevMonth(
                                          currentMonths[detail.KPICode]
                                        )}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleNextMonth(theme, detail.KPICode)
                                        }
                                        disabled={
                                          !hasNextMonth(theme, detail.KPICode)
                                        }
                                      >
                                        {getMonth(
                                          currentMonths[detail.KPICode]
                                        )}
                                      </Button>
                                    </>
                                  )}

                              </div>
                            </div>

                          </AccordionDetails>
                        </>
                      );

                    }
                    return null;
                  })
                ) : (
                  <p style={{
                    backgroundColor: "#FF6969",
                    borderRadius: "8px",
                    margin: "20px",
                    padding: "10px",
                    color: "white",
                  }}>
                    No data available for {filterOption} filter
                  </p>
                );
              })()}
            </Accordion>
          ))}
          {error && <div>{error}</div>}
          <Button
            type="button"
            onClick={() => navigate(-1)}
            style={backBtnfixed}
          >
            Back
          </Button>

          {savedCount !== totalCount && (
            <Button
              type="button"
              onClick={openPopup}
              style={
                savedCount === totalCount
                  ? buttoncontaindedfixeddisabled
                  : buttoncontaindedfixed
              }
              disabled={savedCount === totalCount}
            >
              Next
            </Button>
          )}
        </form>
        <Loader loading={loading} />

        {showModal && (
          <BasicModal
            message="Please select the action to be performed."
            closePopup={closePopup}
            openPopup={openPopup}
            handleNavigate={handleNavigate}
            confirm={confirm}
            setConfirm={setConfirm}
            handleClickDraft={handleClickDraft}
            handleNavigateSaveDraft={handleNavigateSaveDraft}
          />
        )}
        {secondModal && (
          <SimpleModal
            openPopup={secondModal}
            message={`Good Job! You have filled ${filledKpis} response`}
            draftMessage={`Good Job! You have Draft ${draftedKpis} response`}
            closeSimplePopup={closeSimplePopup}
            closePopup={closePopup}
          />
        )}

        {showConfirmModal && (
          <ConfirmModal
            open={showConfirmModal}
            title={"Confirm Submitting"}
            onClose={closePopup}
            message={"Draft Data will also we saved after confirming"}
            confirmText={"confirm"}
            cancelText={"cancel"}
            onConfirm={handleNavigate}
          />
        )}

        {openDesModal && (
          <ThankyouModal
            closePopup={() => setOpenDesModal(false)}
            openPopup={handleOpenDecsModal}
            handleNavigate={() => setOpenDesModal(false)}
            message={""}
            title={""}
            confirmText={""}
            singalKpiData={singalKpiData}
            kpiIndex={kpiIndex}
          />
        )}
        {commentBoxOpen &&
          <CommentPopUp
            open={commentBoxOpen}
            handleClose={handleCommentBoxClose}
            // onSubmitComment={handleAddComment}
            // submittedComments={submittedComments}
            // draftComment={draftComment}
            // setDraftComment={setDraftComment}
            // onDeleteComment={handleDeleteComment}
            // onEditComment={handleEditComment}
            reviewedBy={reviewedBy}
            commentsArray={comments[kpiCodeForComments]}
          />}

        {showTourModal &&
          <ConfirmModal
            open={showTourModal}
            onClose={handleCancelTour}
            title={"Page Tour"}
            message={
              <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
                <span>Would you like a tour of the page?</span>
                <p style={{ color: 'red' }}>Click on the icon at top-right corner of the page to have a tour anytime</p>
                <img
                  src={pageguideimg}
                  alt="guide"
                />
              </div>
            }
            confirmText={"Yes, show me around"}
            cancelText={"No, thanks"}
            onConfirm={handleRunTour}
          />
        }
      </ClientLayout>
    </div>
  );
};