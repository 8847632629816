import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import "./AddDept.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  backBtnfixed,
  btnMapDept,
  buttoncontainded,
  buttoncontaindedfixed,
  disabledbuttoncontainded,
} from "../../../styles";
import { toast } from "react-toastify";
import Hamburger from "../../Navbars/Hamburger";
import { getAllDepartments } from "../../../services/OrganizationManagemnetApi";
import MultiStep from "../../../pages/MultiStepAddOrg/MultiStep";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import { getLoginAttempts } from "../../../services/loggedIn";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../PopUpDialog/ConfirmModal";

export const AddDept = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orgData = location.state.orgData;
  const deptData = location.state;
  const [skills, setSkills] = useState(location.state?.skills || []);
  const [newSkill, setNewSkill] = useState("");
  const [operationalUnits, setOperationalUnits] = useState(
    location.state?.operationalUnits || []
  );
  const [newUnit, setNewUnit] = useState("");
  const [modalshow, setModalshow] = useState(false);
  const [selectedDept, setSelectedDept] = useState({});
  const [allDept, setAllDept] = useState([]);
  const [selectedDepartmentsAll, setSelectedDepartmentsAll] = useState([]);
  const selectedItems = location.state?.selectedItems;
  const loginAttempts = getLoginAttempts();
  const [inputDeptError, setInputDeptError] = useState("");
  const [inputOuError, setInputOuError] = useState("");

  const dispatch = useDispatch()

  const { showTourModal } = useSelector((state) => state.tourGuide);


  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".add-dept-input",
      content:
        "Type the department name and press Enter,the department will be added",
      disableBeacon: true,
    },
    {
      target: ".dept-chip-box",
      content: "Added departments would be visible here",
    },
    {
      target: ".map-depts",
      content: "Click here to map departments",
    },
    {
      target: ".ou-input",
      content:
        "Type the operational unit name and press Enter,the unit will be added",
      disableBeacon: true,
    },
    {
      target: ".org-continue-btn",
      content: "Click to submit and continue to next page",
      disableBeacon: true,
    },
  ];

  const allowedCharRegex = /^[a-zA-Z0-9.,\-\'& ]*$/;
  const handleDeptInputChange = (e) => {
    const value = e.target.value;

    // Check for special characters
    if (allowedCharRegex.test(value)) {
      setNewSkill(value);
    }
  };
  const handleOuInputChange = (e) => {
    const value = e.target.value;

    if (allowedCharRegex.test(value)) {
      setNewUnit(value);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (location.state?.skillsWithDepartments !== undefined) {
      const loadedDepts = {};

      location.state.skillsWithDepartments?.forEach((item, index) => {
        const key = Object.keys(item)[0];
        const value = item[key];

        // Handle null or empty values
        if (value) {
          loadedDepts[index] = value.split(",").map((dept) => dept.replace(/_/g, " "));
        } else {
          loadedDepts[index] = [] // Set empty array for null/undefined values
        }
      });

      setSelectedDept(loadedDepts);

      // Create set of all unique departments
      const allLoadedDepts = new Set();
      Object.values(loadedDepts).forEach((depts) => {
        depts.forEach((dept) => allLoadedDepts.add(dept));
      });
      setSelectedDepartmentsAll(Array.from(allLoadedDepts));

    } else {
      // Initialize with empty objects if no data
      setSelectedDept({});
      setSelectedDepartmentsAll([]);
    }
  }, [location.state]);

  useEffect(() => {
    try {
      const fetchAllDepts = async () => {
        const res = await getAllDepartments();
        const data = res.data.departments;
        const allDept = data.map((dept) => dept.replace(/_/g, " "));
        setAllDept(allDept);
      };

      fetchAllDepts();
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  const handleAddUnit = () => {
    const trimmedNewUnit = newUnit.trim().toLowerCase();

    if (
      trimmedNewUnit !== "" &&
      !operationalUnits.some((unit) => unit.toLowerCase() === trimmedNewUnit)
    ) {
      setOperationalUnits([...operationalUnits, newUnit.trim()]);
      setNewUnit("");
    } else {
      toast.error("Operational Unit already entered");
    }
  };

  const handleDeleteUnit = (index) => {
    const updatedUnits = [...operationalUnits];
    updatedUnits.splice(index, 1);
    setOperationalUnits(updatedUnits);
  };

  const areAllDepartmentsMapped = () => {
    const mappedDepartments = new Set();
    Object.values(selectedDept).forEach((departments) => {
      departments.forEach((dept) => mappedDepartments.add(dept));
    });
    return mappedDepartments.size === allDept.length;
  };

  const handleAddSkill = () => {
    const trimmedNewSkill = newSkill.trim().toLowerCase();

    if (areAllDepartmentsMapped()) {
      toast.error(
        "All departments are already mapped. Please edit existing departments."
      );
      return;
    }

    if (
      trimmedNewSkill !== "" &&
      !skills.some((skill) => skill.toLowerCase() === trimmedNewSkill)
    ) {
      setSkills([...skills, newSkill.trim()]);
      setNewSkill("");
      setModalshow(true);
    } else {
      toast.error("Department already entered");
    }
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);

    const updatedSelectedDept = { ...selectedDept };
    delete updatedSelectedDept[index];

    const reIndexedSelectedDept = {};
    Object.keys(updatedSelectedDept).forEach((key) => {
      const newIndex = key > index ? key - 1 : key;
      reIndexedSelectedDept[newIndex] = updatedSelectedDept[key];
    });

    setSelectedDept(reIndexedSelectedDept);

    const updatedSelectedDepartmentsAll = new Set();
    Object.values(reIndexedSelectedDept).forEach((depts) => {
      depts.forEach((dept) => updatedSelectedDepartmentsAll.add(dept));
    });

    setSelectedDepartmentsAll(Array.from(updatedSelectedDepartmentsAll));
  };

  const skillsWithDepartments = skills.map((skill, index) => {
    const selectedDepartmentsForSkill = selectedDept[index] || [];

    // const deptString = selectedDepartmentsForSkill.join(",").replace(/ /g, "_");
    const deptString = selectedDepartmentsForSkill.length > 0
      ? selectedDepartmentsForSkill.join(",").replace(/ /g, "_")
      : null;

    return { [skill]: deptString };
  });


  const handleSubmit = () => {
    if (!hasAtLeastOneDepartmentSelected()) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }
    if (skills.length === 0) {
      return toast.error("At least one department is required.");
    }
    if (operationalUnits.length === 0) {
      return toast.error("At least one operational unit is required.");
    }

    navigate("/organization/add-dept-table", {
      state: {
        skills: skills,
        operationalUnits: operationalUnits,
        orgData: orgData,
        skillsWithDepartments: skillsWithDepartments,
        selectedItems: selectedItems,
        selectedManagers: location.state.selectedManagers,
      },
    });
  };
  const handleClose = () => {
    setModalshow(!modalshow);
  };

  const handleDeptChange = (event, skillIndex) => {
    const { value } = event.target;
    const newSelectedDepts = value;

    const updatedSelectedDept = {
      ...selectedDept,
      [skillIndex]: newSelectedDepts,
    };
    setSelectedDept(updatedSelectedDept);

    const updatedSelectedDepartmentsAll = new Set();
    skills.forEach((_, index) => {
      const selectedDeptsForSkill = updatedSelectedDept[index] || [];
      selectedDeptsForSkill.forEach((dept) =>
        updatedSelectedDepartmentsAll.add(dept)
      );
    });

    setSelectedDepartmentsAll(Array.from(updatedSelectedDepartmentsAll));
  };

  const isOptionDisabled = (dept, skillIndex) => {
    const isSelectedInCurrentDropdown = (
      selectedDept[skillIndex] || []
    ).includes(dept);
    const isSelectedInAnyOtherDropdown =
      selectedDepartmentsAll.includes(dept) && !isSelectedInCurrentDropdown;

    return isSelectedInAnyOtherDropdown;
  };

  const handleContinue = () => {
    if (!hasAtLeastOneDepartmentSelected()) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }
    setModalshow(false);
  };

  const handleOpenModal = () => {
    setModalshow(true);
  };

  const hasAtLeastOneDepartmentSelected = () => {
    const newSkillIndex = skills.length - 1;
    if (newSkillIndex < 0) return false;
    const newSkillDepartments = selectedDept[newSkillIndex];

    // console.log("newSkillDepartments", newSkillDepartments, newSkillDepartments.length > 0);

    // return Array.isArray(newSkillDepartments) && newSkillDepartments.length > 0;
    return Boolean(newSkillDepartments?.length);
  };

  const handleBack = () => {
    // if (!hasAtLeastOneDepartmentSelected()) {
    //   return toast.error(
    //     "Add atleast one or more Departments in the newly added Department"
    //   );
    // }
    navigate("/organization/add-org", {
      state: {
        orgData: location.state.orgData,
        skills: skills,
        operationalUnits: operationalUnits,
        skillsWithDepartments: skillsWithDepartments,
        selectedItems: selectedItems,
        selectedManagers: location.state.selectedManagers,
      },
    });
  };

  const step = 1;

  return (
    <>
      <Hamburger />
      <JoyRide
        steps={steps}
      />
      <div className="add-dept-body">
        <div className="add-container">
          <Box sx={{ marginBottom: "20px" }}>
            <MultiStep step={step} />
          </Box>
          <div open={true} style={{ minWidth: "100%", minHeight: "50%" }}>
            <h3 className="frame_heading">
              Add Departments and Operation Units
            </h3>
            <div className="add-dept-container">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BsInfoCircle />
                &nbsp; Type and press enter to add departments and units
              </p>
              <h4 className="dept-head">Add All Departments</h4>
              <TextField
                className="add-dept-input"
                variant="outlined"
                required
                value={newSkill}
                autoCapitalize={newSkill}
                onChange={handleDeptInputChange}
                onKeyPress={(e) => e.key === "Enter" && handleAddSkill()}
              />
              <div className="dept-chip-box" style={{ position: "relative" }}>
                {skills.length === 0 && (
                  <div style={{ color: "red", margin: "20px" }}>
                    No departments added. (Add at least one department){" "}
                  </div>
                )}
                {skills.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    onDelete={() => handleDeleteSkill(index)}
                    style={{ margin: "5px" }}
                    className="chip"
                  />
                ))}
                <button
                  style={btnMapDept}
                  onClick={handleOpenModal}
                  className="map-depts"
                >
                  Map Departments
                </button>
              </div>
              <br />
              <Divider />
              <h4 className="dept-head">Add All Operational Units</h4>
              <TextField
                variant="outlined"
                value={newUnit}
                required
                onChange={handleOuInputChange}
                onKeyPress={(e) => e.key === "Enter" && handleAddUnit()}
                className="add-dept-input ou-input"
              />
              <div className="dept-chip-box">
                {operationalUnits.length === 0 && (
                  <div style={{ color: "red", margin: "20px" }}>
                    No Operational Units added. (Add at least one Operational Unit){" "}
                  </div>
                )}
                {operationalUnits.map((unit, index) => (
                  <Chip
                    key={index}
                    label={unit}
                    onDelete={() => handleDeleteUnit(index)}
                    style={{ margin: "5px" }}
                    className="chip"
                  />
                ))}
              </div>
              <br />
            </div>
            <Button onClick={handleBack} style={backBtnfixed}>
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              style={buttoncontaindedfixed}
              className="org-continue-btn"
            >
              Continue
            </Button>
          </div>
        </div>
        {modalshow && (
          <Dialog open={modalshow} onClose={handleClose} disableEscapeKeyDown>
            <DialogTitle>
              Mark departments
              <Typography
                variant="body1"
                sx={{ color: "red", fontWeight: "600" }}
              >
                Add atleast one or more Departments.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Table className="add-dept-table">
                <TableBody>
                  {skills?.map((skill, skillIndex) => (
                    <TableRow key={skillIndex}>
                      <TableCell>{skill}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id="depts">
                            Select Default Functions
                          </InputLabel>

                          <Select
                            id="depts"
                            multiple
                            value={selectedDept[skillIndex] || []}
                            onChange={(event) =>
                              handleDeptChange(event, skillIndex)
                            }
                            renderValue={(selected) => selected.join(", ")}
                            label="Select Default Functions"
                            sx={{ width: "400px" }}
                          >
                            {allDept &&
                              allDept?.map((unit, unitIndex) => (
                                <MenuItem
                                  key={unitIndex}
                                  value={unit}
                                  disabled={isOptionDisabled(unit, skillIndex)}
                                >
                                  <Checkbox
                                    checked={(
                                      selectedDept[skillIndex] || []
                                    ).includes(unit)}
                                  />
                                  <ListItemText primary={unit} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                className="add-dept-clrbtn"
                style={
                  !hasAtLeastOneDepartmentSelected()
                    ? disabledbuttoncontainded
                    : buttoncontainded
                }
                onClick={handleContinue}
                disabled={!hasAtLeastOneDepartmentSelected()}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {showTourModal &&
          <ConfirmModal
            open={showTourModal}
            onClose={handleCancelTour}
            title={"Page Tour"}
            message={"Would you like a tour of the page?"}
            confirmText={"Yes, show me around"}
            cancelText={"No, thanks"}
            onConfirm={handleRunTour}
          />
        }
      </div>
    </>
  );
};
