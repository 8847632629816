import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import "./EditDept.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  backBtnfixed,
  btnMapDept,
  buttoncontainded,
  buttoncontaindedfixed,
  disabledbuttoncontainded,
} from "../../../styles";
import { toast } from "react-toastify";
import Hamburger from "../../Navbars/Hamburger";
import { getAllDepartments } from "../../../services/OrganizationManagemnetApi";
import EditOrgMultiStep from "../../../pages/MultiStepEditOrg/EditOrgMultiStep";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

export const EditDept = (props) => {
  const location = useLocation();
  const isAssigned = location.state?.orginitialData?.isAssigned;

  const navigate = useNavigate();
  const [orgData, setOrgData] = useState(location.state.orginitialData);
  const [parsedDepts, setParsedDepts] = useState(
    location.state.parsedDepts || JSON.parse(orgData.departments)
  );

  const [parsedOperationalUnits, setParsedOperationalUnits] = useState(
    JSON.parse(JSON.parse(orgData.operationalUnits))
  );

  // Extract department values and operational unit keys
  const departmentsSet = new Set();
  const ous = [];

  parsedOperationalUnits.forEach((unit) => {
    const key = Object.keys(unit)[0];
    const values = unit[key].split(",").map((value) => value.trim());

    ous.push(key);
    values.forEach((department) => departmentsSet.add(department));
  });
  const departments = [...departmentsSet];
  const [skills, setSkills] = useState(
    location.state.skills || [...departments]
  );
  const [newSkill, setNewSkill] = useState("");

  const [operationalUnits, setOperationalUnits] = useState(
    location.state.operationalUnits || [...ous]
  );
  const [newUnit, setNewUnit] = useState("");
  const [modalshow, setModalshow] = useState(false);
  const [selectedDept, setSelectedDept] = useState(
    location.state.selectedDept || {}
  );
  const [allDept, setAllDept] = useState([]);
  const [selectedDepartmentsAll, setSelectedDepartmentsAll] = useState([]);
  const [editingChip, setEditingChip] = useState(null);
  const [editingChipValue, setEditingChipValue] = useState("");
  const [editingUnit, setEditingUnit] = useState(null);
  const [editingUnitValue, setEditingUnitValue] = useState("");
  const [hasAtLeastOneDepartmentSelected, setHasAtLeastOneDepartmentSelected] =
    useState(false);
  const [deptEdited, setDeptEdited] = useState(false);
  const [ouEdited, setOuEdited] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    try {
      const fetchAllDepts = async () => {
        const res = await getAllDepartments();

        const data = res.data.departments;
        const allDept = data.map((dept) => dept.replace(/_/g, " "));
        setAllDept(allDept);

        // Only set selectedDept if it's empty
        if (Object.keys(selectedDept).length === 0) {
          const initialSelectedDept = parsedDepts.reduce((acc, obj) => {
            const key = Object.keys(obj)[0];
            const departments = obj[key]
              .split(",")
              .map((dept) => dept.trim().replace(/_/g, " "))
              .filter((dept) => dept !== "");
            acc[key] = departments;
            return acc;
          }, {});
          setSelectedDept(initialSelectedDept);
        }
      };

      fetchAllDepts();
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  const deptwithUnderscore = Object.entries(selectedDept).map(
    ([key, value]) => ({
      [key]: value.map((dept) => dept.replace(/ /g, "_")).join(","),
    })
  );

  const handleAddUnit = () => {
    const trimmedNewUnit = newUnit.trim().toLowerCase(); // Convert to lowercase for case-insensitive comparison

    if (
      trimmedNewUnit !== "" &&
      !operationalUnits.some((unit) => unit.toLowerCase() === trimmedNewUnit)
    ) {
      setOperationalUnits([...operationalUnits, newUnit.trim()]);
      setNewUnit("");
    } else {
      toast.error("Operational Unit already entered");
    }
  };

  const handleDeleteUnit = (index) => {
    const updatedUnits = [...operationalUnits];
    updatedUnits.splice(index, 1);
    setOperationalUnits(updatedUnits);
  };

  const areAllDepartmentsMapped = () => {
    const mappedDepartments = new Set(Object.values(selectedDept).flat());
    return mappedDepartments.size === allDept.length;
  };

  const handleAddSkill = () => {
    const trimmedNewSkill = newSkill.trim();

    if (trimmedNewSkill !== "") {
      // Convert trimmedNewSkill and existing keys to lowercase for case-insensitive comparison
      const lowercaseNewSkill = trimmedNewSkill.toLowerCase();

      // Check if the new skill already exists in parsedDepts
      const skillAlreadyExists = parsedDepts.some(
        (deptObj) =>
          Object.keys(deptObj)[0].toLowerCase() === lowercaseNewSkill ||
          deptObj[Object.keys(deptObj)[0]]
            .split(",")
            .map((dept) => dept.trim().toLowerCase())
            .includes(lowercaseNewSkill)
      );

      if (!skillAlreadyExists) {
        if (areAllDepartmentsMapped()) {
          toast.error(
            "All departments are already mapped. Please edit existing departments."
          );
        } else {
          const newDeptObj = { [trimmedNewSkill]: "" };
          const updatedParsedDepts = [...parsedDepts, newDeptObj];
          setParsedDepts(updatedParsedDepts);
          setSkills([...skills, trimmedNewSkill]);
          setSelectedDept({ ...selectedDept, [trimmedNewSkill]: [] });
          setNewSkill("");
          setModalshow(true);
        }
      } else {
        toast.error("Department already entered");
      }
    } else {
      toast.error("Please enter a valid department name");
    }
  };

  const handleDeleteSkill = (indexToDelete, key) => {
    const updatedParsedDepts = parsedDepts.filter(
      (_, index) => index !== indexToDelete
    );
    setParsedDepts(updatedParsedDepts);

    // Update selectedDept by removing the deleted department
    const updatedSelectedDept = { ...selectedDept };
    delete updatedSelectedDept[key];

    setSelectedDept(updatedSelectedDept);

    // Update parsedOperationalUnits
    const updatedParsedOperationalUnits = parsedOperationalUnits.map((unit) => {
      const unitKey = Object.keys(unit)[0];
      const departments = unit[unitKey]
        .split(",")
        .filter((dept) => dept.trim() !== key);
      return { [unitKey]: departments.join(",") };
    });
    setParsedOperationalUnits(updatedParsedOperationalUnits);

    // Update orgData
    const updatedOrgData = {
      ...orgData,
      departments: JSON.stringify(updatedParsedDepts),
      operationalUnits: JSON.stringify(
        JSON.stringify(updatedParsedOperationalUnits)
      ),
    };
    setOrgData(updatedOrgData);

    // Update deptwithUnderscore based on updatedSelectedDept
    const updatedDeptwithUnderscore = Object.entries(updatedSelectedDept).map(
      ([deptKey, deptValue]) => ({
        [deptKey]: deptValue.map((dept) => dept.replace(/ /g, "_")).join(","),
      })
    );

    // Update deptwithUnderscore directly
    deptwithUnderscore.splice(
      0,
      deptwithUnderscore.length,
      ...updatedDeptwithUnderscore
    );
  };

  const handleSubmit = () => {
    if (!hasAtLeastOneDepartmentSelected) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }

    const updatedOrgData = {
      ...orgData,
      operationalUnits: JSON.stringify(JSON.stringify(parsedOperationalUnits)),
    };

    if (skills.length === 0) {
      toast.error("At least one department is required.");
    } else if (operationalUnits.length === 0) {
      toast.error("At least one operational unit is required.");
    } else {
      navigate("/organization/edit-dept-table", {
        state: {
          skills: skills,
          departments: deptwithUnderscore,
          operationalUnits: operationalUnits,
          organizationData: updatedOrgData,
          parsedDepts: parsedDepts,
          selectedDept: selectedDept,
          selectedItems: location.state.selectedItems,
          deptEdited: deptEdited,
          ouEdited: ouEdited,
        },
      });
    }
  };

  const handleDeptChange = (event, skill) => {
    const { value } = event.target;

    const newSelectedDepts = value;

    // Update selectedDept
    const updatedSelectedDept = {
      ...selectedDept,
      [skill]: newSelectedDepts,
    };

    setSelectedDept(updatedSelectedDept);

    // Update selectedDepartmentsAll
    const updatedSelectedDepartmentsAll = new Set();
    Object.values(updatedSelectedDept).forEach((depts) => {
      depts.forEach((dept) => updatedSelectedDepartmentsAll.add(dept));
    });
    setSelectedDepartmentsAll(Array.from(updatedSelectedDepartmentsAll));
  };

  const isOptionDisabled = (dept, skill) => {
    const isDeptSelectedInOtherDropdowns = Object.keys(selectedDept).some(
      (otherSkill) =>
        otherSkill !== skill && (selectedDept[otherSkill] || []).includes(dept)
    );

    return isDeptSelectedInOtherDropdowns;
  };

  const handleContinue = () => {
    setModalshow(false);
  };

  const handleOpenModal = () => {
    setModalshow(true);
  };
  const handleClose = () => {
    setModalshow(!modalshow);
  };

  // const hasAtLeastOneDepartmentSelected = () => {
  useEffect(() => {
    const newSkillKey = parsedDepts[parsedDepts.length - 1];

    if (!newSkillKey) {
      setHasAtLeastOneDepartmentSelected(false);
      return;
    } // No new skill added

    const newSkillName = Object.keys(newSkillKey)[0];
    const newSkillDepartments = selectedDept[newSkillName];

    setHasAtLeastOneDepartmentSelected(newSkillDepartments?.length > 0);
    // return newSkillDepartments?.length > 0;
  }, [parsedDepts, selectedDept]);
  // };

  const handleBack = async () => {
    if (!hasAtLeastOneDepartmentSelected) {
      return toast.error(
        "Add atleast one or more Departments in the newly added Department"
      );
    }
    navigate("/organization/update-org", {
      state: {
        user: orgData,
        skills: skills,
        parsedDepts: parsedDepts,
        selectedDept: selectedDept,
        operationalUnits: operationalUnits,
        selectedItems: location.state.selectedItems,
        availableManagers: location.state.availableManagers,
        deptEdited: deptEdited,
        ouEdited: ouEdited,
      },
    });
  };

  const step = 1;

  const handleEditClick = (index, i, key) => {
    setEditingChip(`${index}-${i}`);
    setEditingChipValue(key);
  };
  const allowedCharRegex = /^[a-zA-Z0-9.,\-\'& ]*$/;
  const handleEditSkill = (index, oldKey, newKey) => {
    // If the new key is empty or unchanged, just cancel the edit
    if (newKey.trim() === "" || newKey.trim() === oldKey) {
      setEditingChip(null);
      setEditingChipValue("");
      return;
    }

    // Check if the new name already exists
    if (
      parsedDepts.some(
        (dept, idx) =>
          idx !== index &&
          Object.keys(dept)[0].toLowerCase() === newKey.trim().toLowerCase()
      )
    ) {
      toast.error("Department name already exists");
      setEditingChip(null);
      setEditingChipValue("");
      return;
    }

    const updatedParsedDepts = parsedDepts.map((dept, idx) => {
      if (idx === index) {
        return { [newKey.trim()]: dept[oldKey] };
      }
      return dept;
    });

    // Update selectedDept while preserving OU associations
    const updatedSelectedDept = { ...selectedDept };
    updatedSelectedDept[newKey.trim()] = updatedSelectedDept[oldKey];
    delete updatedSelectedDept[oldKey];

    // Update parsedOperationalUnits
    const updatedParsedOperationalUnits = parsedOperationalUnits.map((unit) => {
      const unitKey = Object.keys(unit)[0];
      const departments = unit[unitKey]
        .split(",")
        .map((dept) => (dept.trim() === oldKey ? newKey.trim() : dept));
      return { [unitKey]: departments.join(",") };
    });

    setParsedDepts(updatedParsedDepts);
    setSelectedDept(updatedSelectedDept);
    setParsedOperationalUnits(updatedParsedOperationalUnits);
    setEditingChip(null);
    setEditingChipValue("");
    setDeptEdited(true);

    // Update skills array
    const updatedSkills = skills.map((skill) =>
      skill === oldKey ? newKey.trim() : skill
    );
    setSkills(updatedSkills);

    // Update orgData
    const updatedOrgData = {
      ...orgData,
      departments: JSON.stringify(updatedParsedDepts),
      operationalUnits: JSON.stringify(
        JSON.stringify(updatedParsedOperationalUnits)
      ),
    };
    setOrgData(updatedOrgData);
  };

  const handleEditUnit = (index, oldUnit, newUnit) => {
    if (newUnit.trim() === "" || newUnit.trim() === oldUnit) {
      setEditingUnit(null);
      setEditingUnitValue("");
      return;
    }

    // Check if the new name already exists
    if (
      operationalUnits.some(
        (unit, idx) =>
          idx !== index && unit.toLowerCase() === newUnit.trim().toLowerCase()
      )
    ) {
      toast.error("Operational Unit name already exists");
      setEditingUnit(null);
      setEditingUnitValue("");
      return;
    }

    const updatedOperationalUnits = operationalUnits.map((unit, idx) =>
      idx === index ? newUnit.trim() : unit
    );

    setOperationalUnits(updatedOperationalUnits);
    setEditingUnit(null);
    setEditingUnitValue("");

    // Update parsedOperationalUnits
    const updatedParsedOperationalUnits = parsedOperationalUnits.map((unit) => {
      const unitKey = Object.keys(unit)[0];
      if (unitKey === oldUnit) {
        return { [newUnit.trim()]: unit[unitKey] };
      }
      return unit;
    });
    setParsedOperationalUnits(updatedParsedOperationalUnits);
    setOuEdited(true);

    // Update orgData
    const updatedOrgData = {
      ...orgData,
      operationalUnits: JSON.stringify(
        JSON.stringify(updatedParsedOperationalUnits)
      ),
    };
    setOrgData(updatedOrgData);
  };

  const handleDeptInputChange = (e) => {
    const value = e.target.value;

    // Check for special characters
    if (allowedCharRegex.test(value)) {
      setNewSkill(value);
    }
  };
  const handleOuInputChange = (e) => {
    const value = e.target.value;

    if (allowedCharRegex.test(value)) {
      setNewUnit(value);
    }
  };

  return (
    <div className="add-dept-body">
      <Hamburger />
      <div className="add-container">
        <div>
          <Box sx={{ marginBottom: "20px" }}>
            <EditOrgMultiStep step={step} />
          </Box>
          <h3 className="frame_heading">Edit Organization</h3>
          <br />
          <Divider />
          <div className="add-dept-container">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
                fontWeight: "600",
              }}
            >
              <BsInfoCircle />
              &nbsp; Type and press enter to add departments and operation units
            </p>
            <h3 className="dept-head">Edit Departments</h3>
            <TextField
              className="add-dept-input"
              variant="outlined"
              required
              value={newSkill}
              onChange={handleDeptInputChange}
              onKeyPress={(e) => e.key === "Enter" && handleAddSkill()}
              disabled={isAssigned === true}
            />
            <div className="dept-chip-box" style={{ position: "relative" }}>
              <h5 style={{ color: "red", paddingLeft: "10px" }}>
                {isAssigned === true ? "Can not add or edit Department(s) after assignement is done." : ""}{" "}
              </h5>
              {parsedDepts && parsedDepts?.length > 0 ? (
                parsedDepts?.map((obj, index) =>
                  Object.keys(obj).map((key, i) => (
                    <>
                      <Chip
                        key={`${index}-${i}`}
                        label={
                          editingChip === `${index}-${i}` ? (
                            <InputBase
                              value={editingChipValue}
                              onChange={(e) =>
                                setEditingChipValue(e.target.value)
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleEditSkill(index, key, editingChipValue);
                                }
                              }}
                              onBlur={() =>
                                handleEditSkill(index, key, editingChipValue)
                              }
                              onClick={(e) => e.stopPropagation()}
                              autoFocus
                              inputProps={{
                                style: {
                                  padding: 0,
                                  height: "24px",
                                  fontSize: "0.8125rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  textAlign: "center",
                                },
                              }}
                              style={{
                                width: `${editingChipValue.length * 8 + 16}px`,
                                minWidth: "50px",
                                maxWidth: "150px",
                              }}
                            />
                          ) : (
                            key
                          )
                        }
                        onDelete={() => handleDeleteSkill(index, key)}
                        disabled={isAssigned === true}
                        style={{ margin: "5px" }}
                        className="chip"
                        onClick={() => {
                          if (editingChip !== `${index}-${i}`) {
                            handleEditClick(index, i, key);
                          }
                        }}
                        deleteIcon={
                          <Box>
                            {editingChip !== `${index}-${i}` && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(index, i, key);
                                }}
                                size="small"
                              // disabled={isAssigned === true}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        }
                      />
                    </>
                  ))
                )
              ) : (
                <div style={{ color: "red", margin: "20px" }}>
                  No departments found
                </div>
              )}
              <button style={btnMapDept} onClick={handleOpenModal}>
                Edit Departments
              </button>
            </div>
            <br />
            <Divider />
            <h3 className="dept-head">Edit Operational Units</h3>
            <TextField
              variant="outlined"
              value={newUnit}
              required
              onChange={handleOuInputChange}
              onKeyPress={(e) => e.key === "Enter" && handleAddUnit()}
              className="add-dept-input"
              disabled={isAssigned === true}

            />
            <div className="dept-chip-box">
              <h5 style={{ color: "red", paddingLeft: "10px" }}>
                {isAssigned === true ? "Can not add or edit Operational Unit(s) after assignement is done." : ""}{" "}
              </h5>
              {operationalUnits.length === 0 && (
                <div style={{ color: "red", margin: "20px" }}>
                  No departments added{" "}
                </div>
              )}
              {operationalUnits.map((unit, index) => (
                <Chip
                  key={index}
                  label={
                    editingUnit === index ? (
                      <InputBase
                        value={editingUnitValue}
                        onChange={(e) => setEditingUnitValue(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleEditUnit(index, unit, editingUnitValue);
                          }
                        }}
                        onBlur={() =>
                          handleEditUnit(index, unit, editingUnitValue)
                        }
                        onClick={(e) => e.stopPropagation()}
                        autoFocus
                        inputProps={{
                          style: {
                            padding: 0,
                            height: "24px",
                            fontSize: "0.8125rem",
                            fontWeight: 400,
                            lineHeight: "1.5",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          width: `${editingUnitValue.length * 8 + 16}px`,
                          minWidth: "50px",
                          maxWidth: "150px",
                        }}
                      />
                    ) : (
                      unit
                    )
                  }
                  onDelete={() => handleDeleteUnit(index)}
                  style={{ margin: "5px" }}
                  className="chip"
                  disabled={isAssigned === true}
                  onClick={() => {
                    if (editingUnit !== index) {
                      setEditingUnit(index);
                      setEditingUnitValue(unit);
                    }
                  }}
                  deleteIcon={
                    <Box>
                      {editingUnit !== index && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingUnit(index);
                            setEditingUnitValue(unit);
                          }}
                          size="small"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  }
                />
              ))}
            </div>
            <br />
          </div>
          <Button
            onClick={handleBack}
            disabled={!hasAtLeastOneDepartmentSelected}
            style={backBtnfixed}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            // disabled={!hasAtLeastOneDepartmentSelected}
            style={buttoncontaindedfixed}
          >
            Continue
          </Button>
        </div>
      </div>
      {modalshow && (
        <Dialog open={modalshow} onClose={handleClose} disableEscapeKeyDown>
          <DialogTitle>
            Mark departments
            <Typography
              variant="body1"
              sx={{ color: "red", fontWeight: "600" }}
            >
              Add atleast one or more Departments.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Table className="add-dept-table">
              <TableBody>
                {parsedDepts && parsedDepts?.map((item, itemIndex) => (
                  <TableRow key={itemIndex}>
                    <TableCell>{Object.keys(item)[0]}</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id={`depts-${itemIndex}`}>
                          Select Default Functions
                        </InputLabel>
                        <Select
                          id={`depts-${itemIndex}`}
                          multiple
                          value={selectedDept[Object.keys(item)[0]] || []}
                          onChange={(event) =>
                            handleDeptChange(event, Object.keys(item)[0])
                          }
                          renderValue={(selected) => selected.join(", ")}
                          label="Select Default Functions"
                          sx={{ width: "400px" }}
                          disabled={isAssigned === true}
                        >
                          {allDept &&
                            allDept.map((unit, unitIndex) => {
                              return (
                                <MenuItem
                                  key={unitIndex}
                                  value={unit}
                                  disabled={isOptionDisabled(
                                    unit,
                                    Object.keys(item)[0]
                                  )}
                                >
                                  <Checkbox
                                    checked={(
                                      selectedDept[Object.keys(item)[0]] || []
                                    ).includes(unit)}
                                  />
                                  <ListItemText primary={unit} />
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              className="add-dept-clrbtn"
              style={
                !hasAtLeastOneDepartmentSelected
                  ? disabledbuttoncontainded
                  : buttoncontainded
              }
              onClick={handleContinue}
              disabled={!hasAtLeastOneDepartmentSelected}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
