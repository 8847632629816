import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export const OrgDetails = ({ user, handleClose }) => {
  const oudata = JSON.parse(JSON.parse(user.operationalUnits));
  const operationalUnitNames = oudata.map((unit) => Object.keys(unit)[0]);
  const operationalUnitsString = operationalUnitNames.join(", ");
  const departmentsData = JSON.parse(user.departments);
  const departmentNames = [];
  departmentsData.forEach((dept) => {
    const deptName = Object.keys(dept)[0];
    departmentNames.push(deptName);
  });
  const formattedDepartments = departmentNames.join(", ");

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          &nbsp;{user.organizationName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ></IconButton>
        <DialogContent
          style={{
            height: "450px",
            width: "450px",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            justifyContent: "center",
            alignItems: "center",
          }}
          dividers
        >
          <img
            src={user.image}
            alt="logo"
            style={{ height: "100px", width: "150px", margin: "0 20px" }}
          />
          <br />
          <div
            variant="outlined"
            style={{
              padding: "0.5rem",
              // border: "1px solid #eee",
              overflow: "scroll",
              margin: "10px 0",
            }}
          >
            <Typography gutterBottom>
              <strong>SPOC Name: </strong> {user.spocName}
            </Typography>
            <Typography gutterBottom>
              <strong>SPOC Mobile Number :</strong> {user.spocMobile}
            </Typography>
            <Typography gutterBottom>
              <strong>SPOC Password : </strong> First 4 letters of SPOC
              Name(first letter capitalize) "+" @ "+" First 4 numbers of SPOC
              Mobile
            </Typography>

            <br />
            <Typography gutterBottom>
              <strong>Departments : </strong>
              {formattedDepartments}
            </Typography>
            <Typography gutterBottom>
              <strong>OperationalUnits : </strong>
              {operationalUnitsString}
            </Typography>
            <br />
            <Typography gutterBottom>
              <strong>Organization Email : </strong>
              {user.organizationEmail}
            </Typography>
            <Typography gutterBottom>
              <strong>Organization Mobile : </strong>
              {user.countryCode} {user.mobile}
            </Typography>
            <Typography gutterBottom>
              <strong>Address : </strong>
              {user.address}
            </Typography>
            <Typography gutterBottom>
              <strong>Country : </strong>
              {user.country}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
