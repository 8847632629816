import React, { useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useSortBy, useTable } from "react-table";
import { tablehead } from "../../../../styles";
import CircularProgress from "@mui/material/CircularProgress";
import { FaFileArrowDown } from "react-icons/fa6";
import * as XLSX from "xlsx";
import { getUserEmail } from "../../../../services/loggedIn";

const DescribedTable = ({ themeTopicDataFormat }) => {
  // // console.log("themeTopicDataFormat", themeTopicDataFormat);
  const [pillarThemeData, setPillarThemeData] = useState(themeTopicDataFormat);
  const [loading, setLoading] = useState(false);
  const spocEmail = getUserEmail();

  const currentDate = new Date();
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };

  const currentYear = getFinancialYear(currentDate);
  const currentMonth = new Date().toLocaleString("en-us", { month: "short" });
  const slicedYear = currentDate.getFullYear().toString().slice(2);
  const concatedmonth = `${currentMonth}'${slicedYear}`;

  const columns = React.useMemo(
    () => [
      {
        Header: "Themes",
        accessor: "themes",
      },
      {
        Header: "Topic",
        accessor: "topic.name",
      },
      {
        Header: "Filled KPI",
        accessor: "topic.filled",
      },
      {
        Header: "Total KPI",
        accessor: "topic.total",
      },
    ],
    []
  );

  const tableData = React.useMemo(
    () =>
      pillarThemeData && typeof pillarThemeData === "object"
        ? Object.entries(pillarThemeData && pillarThemeData)?.flatMap(
          ([theme, topicData]) =>
            Object.entries(topicData).map(([topic, values]) => {
              // themes: theme,
              // topic: {
              //   name: topic,
              //   filled:
              //     (values[currentYear]?.annualFrequency?.filled || 0) +
              //     (values[currentYear]?.monthFrequency?.reduce(
              //       (acc, curr) => acc + (curr[concatedmonth]?.filled || 0),
              //       0
              //     ) || 0),
              //   total:
              //     (values[currentYear]?.annualFrequency?.total || 0) +
              //     (values[currentYear]?.monthFrequency?.reduce(
              //       (acc, curr) => acc + (curr[concatedmonth]?.total || 0),
              //       0
              //     ) || 0),
              // },
              const yearData = values[currentYear];
              let filledKPI = 0;
              let totalKPI = 0;

              if (yearData) {
                // Add annual frequency
                filledKPI += yearData.annualFrequency?.filled || 0;
                totalKPI += yearData.annualFrequency?.total || 0;

                // Add all months' frequency
                if (yearData.monthFrequency) {
                  yearData.monthFrequency.forEach((monthObj) => {
                    const monthData = Object.values(monthObj)[0];
                    filledKPI += monthData?.filled || 0;
                    totalKPI += monthData?.total || 0;
                  });
                }
              }

              return {
                themes: theme,
                topic: {
                  name: topic,
                  filled: filledKPI,
                  total: totalKPI,
                },
              };
            })
        )
        : [],
    [pillarThemeData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: {
          sortBy: [{ id: "themes", desc: false }],
        },
      },
      useSortBy
    );

  const sortedRows = rows.map((row) => row.original);

  const excelReportingName = "described_table_";

  const handleDownload = () => {
    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;

    const flattenedData = sortedRows.map(({ themes, topic }) => ({
      Theme: themes,
      "Topic Name": topic.name,
      "Filled KPI": topic.filled,
      "Total KPI": topic.total,
    }));

    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, finalExcelReportingName);
  };

  return (
    <>
      <TableContainer
        sx={{ maxHeight: 440, position: "relative" }}
        style={{ overflow: "scroll", background: "white" }}
      >
        <IconButton
          sx={{ position: "absolute", right: "10px", zIndex: 10 }}
          aria-label="download"
          onClick={handleDownload}
        >
          <FaFileArrowDown />
        </IconButton>
        {loading ? (
          <CircularProgress />
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow sx={tablehead} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      sx={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                        width: "25%",
                      }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span style={{ display: "flex", alignItems: "center" , justifyContent: "center"}}>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <MdArrowDownward />
                          ) : (
                            <MdArrowUpward />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()} 
                      sx = {
                        {
                          textAlign: "center",
                        }
                      }>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default DescribedTable;
