import React from "react";
import { useNavigate } from "react-router-dom";
import { getOrg, getUserRole } from "./loggedIn";
import ClientProtectedRoute from "./ClientProtectedRoute";
import DashProtectedRoute from "./DashProtectedRoute";
import MainProtectedRoute from "./MainProtectedRoute";

const CommonProtectedRoute = ({ element }) => {
  // // console.log("element", element);

  const userRole = getUserRole();
  const org = getOrg();
  // // console.log("org", org);

  if ((userRole === "SPOC" || userRole === "Management") && org !== "Envint") {
    return <MainProtectedRoute element={element} routeType={"clientSpoc"} />;
  } else if (org === "Envint") {
    return <MainProtectedRoute element={element} routeType={"envintUser"} />;
  } else {
    return <MainProtectedRoute element={element} routeType={"clientUser"} />;
  }
};

export default CommonProtectedRoute;
