import DashboardIcon from "@mui/icons-material/Dashboard";
import React from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import { Analytics, CloudDownload, Summarize } from "@mui/icons-material";

const clientNavList = [
  {
    id: 1,
    name: "Reporting Dashboard",
    navigateTo: "/reporting/dashboard",
    icon: <DashboardIcon className="naviconstyle" />,
  },
  // {
  //   id: 2,
  //   name: "Download Report",
  //   navigateTo: "/download_report",
  //   icon: <CloudDownload />,
  // },
  {
    id: 2,
    name: "History",
    navigateTo: "/reporting/history/overview",
    icon: <AssignmentOutlinedIcon className="naviconstyle" />,
  },
  {
    id: 3,
    name: "Contact",
    navigateTo: "https://envintglobal.com/connect/",
    icon: <CorporateFareIcon className="naviconstyle" />,
  },
];
const envintNavList = [
  {
    id: 1,
    name: "Home",
    navigateTo: "/home",
    icon: <HomeIcon className="naviconstyle" />,
  },
  {
    id: 2,
    name: "Organization Management",
    navigateTo: "/organization/management",
    icon: <CorporateFareIcon className="naviconstyle" />,
  },
  {
    id: 3,
    name: "User Management",
    navigateTo: "/user/management",
    icon: <GroupIcon className="naviconstyle" />,
  },
  {
    id: 4,
    name: "Assignment Manager",
    navigateTo: "/assignment/select-organization",
    icon: <AssignmentOutlinedIcon className="naviconstyle" />,
  },
];
const spocNavList = [
  {
    id: 1,
    name: "Home",
    navigateTo: "/dashboard/home",
    icon: <HomeIcon className="naviconstyle" />,
  },
  {
    id: 2,
    name: "Tracker",
    icon: <Analytics className="naviconstyle" />,
    navigateTo: "/dashboard/tracker",
    dropMenu: [
      {
        id: 1,
        name: "ESG & KPI Monitoring",
        icon: <DashboardIcon className="naviconstyle" />,
        navigateTo: "/dashboard/tracker/kpi-monitoring",
      },
      {
        id: 2,
        name: "Department Tracker",
        icon: <AssignmentOutlinedIcon className="naviconstyle" />,
        navigateTo: "/dashboard/tracker/department",
      },
      {
        id: 3,
        name: "Operational Unit Tracker",
        icon: <CorporateFareIcon className="naviconstyle" />,
        navigateTo: "/dashboard/tracker/operation-unit",
      },
      {
        id: 4,
        name: "User Adoption",
        icon: <GroupIcon className="naviconstyle" />,
        navigateTo: "/dashboard/tracker/user-adoption",
      },
    ],
  },
  {
    id: 3,
    name: "Download Report",
    navigateTo: "/download_report",
    icon: <CloudDownload className="naviconstyle" />,
  },
  {
    id: 4,
    name: "Review Data",
    navigateTo: "/dashboard/spoc-review",
    icon: <Summarize className="naviconstyle" />,
  },
];

export { clientNavList, envintNavList, spocNavList };
