// Navbar.js
import React, { useState } from "react";
import "./Hstyle.css";
import { Link, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaEarthAsia, FaXTwitter } from "react-icons/fa6";
import GroupIcon from "@mui/icons-material/Group";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ProfileIcon } from "../../../components/Navbars/ProfileIcon";
import CustomNavLink from "../../../components/Navbars/CustomNavLink";
import { clearData } from "../../../services/loggedIn";

const DashHamburger = ({ orgImgUrl }) => {
  const activeStyle = {
    color: "#2f7abd",
    fontWeight: "bold",
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Use state to manage the menu open or close
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  function hadelLog(event) {
    event.preventDefault();
    clearData();

    navigate("/");
  }

  return (
    <nav id="navbar" className="navbar">
      <Link to="/dashboard">
        <img src={orgImgUrl} alt="Logo" className="nav-envint-logo" />
      </Link>

      {isMobile ? (
        <>
          <Tooltip title="Menu">
            <Button id="hamburger-menu" onClick={toggleMenu}>
              <MoreVertIcon className="menu-icon" />
            </Button>
          </Tooltip>
          <Drawer anchor="right" open={isOpen} onClose={toggleMenu}>
            <IconButton
              style={{
                fontSize: "2rem",
              }}
            >
              <CloseIcon
                onClick={toggleMenu}
                fontSize="large"
                className="close-btn"
              />
            </IconButton>
            <List style={{ margin: "40px 0" }}>
              <ListItem>
                <CustomNavLink to={"/dashboard/home"}>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Home"></ListItemText>
                  </ListItemButton>
                </CustomNavLink>
              </ListItem>
              <div style={{ paddingLeft: "30px" }}>
                <ListItem>
                  <CustomNavLink
                    to="/dashboard/tracker/kpi-monitoring"
                    className="nav-list-item"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DashboardIcon className="naviconstyle" />
                      </ListItemIcon>
                      <ListItemText primary="ESG/KPI Monitoring"></ListItemText>
                    </ListItemButton>
                  </CustomNavLink>
                </ListItem>
                <ListItem>
                  <Link
                    to="/dashboard/tracker/department"
                    className="nav-list-item"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AssignmentOutlinedIcon
                          // style={{ fontSize: "20px", fontWeight: "bold" }}
                          className="naviconstyle"
                        />
                      </ListItemIcon>
                      <ListItemText primary="Department Tracker"></ListItemText>
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/dashboard/tracker/operation-unit"
                    className="nav-list-item"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <CorporateFareIcon className="naviconstyle" />
                      </ListItemIcon>
                      <ListItemText primary="Operation Unit Tracker"></ListItemText>
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to="/dashboard/tracker/user-adoption"
                    className="nav-list-item"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <GroupIcon className="naviconstyle" />
                      </ListItemIcon>
                      <ListItemText primary="User Adoption"></ListItemText>
                    </ListItemButton>
                  </Link>
                </ListItem>
              </div>

              <Divider />
              <ListItem>
                <button onClick={(e) => hadelLog(e)} className="nav-list-item">
                  <ListItemButton>
                    <ListItemIcon>
                      <LogoutIcon className="naviconstyle" />
                    </ListItemIcon>
                    <ListItemText primary="Logout">
                      <button onClick={(event) => hadelLog(event)}></button>
                    </ListItemText>
                  </ListItemButton>
                </button>
              </ListItem>
            </List>
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                margin: "10px 0",
                // position: "absolute",
                // bottom: "0",
              }}
            >
              {/* <p>Connect to us via </p> */}
              <div className="social-container">
                <a
                  href="https://www.linkedin.com/company/envintglobal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://envintglobal.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaEarthAsia />
                </a>
                <a
                  href="https://twitter.com/EnvintGlobal"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
              <img
                src="https://th.bing.com/th/id/R.a5e9f01afee8913f5a8453f007c184f4?rik=cldADQb%2fWtTlIA&riu=http%3a%2f%2fenvintglobal.com%2fwp-content%2fuploads%2f2019%2f11%2flogo-Final.png&ehk=yuheQLOvPvjbtAgWd4f1XxyZFe0aL%2f9cEdvpwyUcttM%3d&risl=&pid=ImgRaw&r=0"
                alt="logo"
                className="nav-envint-logo"
              />
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <ul
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              textDecoration: "none",
              backgroundColor: "#f9f8f9",
              padding: "12px 16px",
              borderRadius: "20px",
              width: "70%",
            }}
          >
            <li
              style={{
                minWidth: "0px",
                padding: "0px",
                textDecoration: "none",
                listStyle: "none",
              }}
            >
              <CustomNavLink to="/dashboard/home">
                <h3>Home</h3>
              </CustomNavLink>
            </li>

            <li
              style={{
                minWidth: "0px",
                padding: "0px",
                textDecoration: "none",
                listStyle: "none",
                position: "relative",
              }}
            >
              <div className="tracker-icon">
                {/* <MdOutlineInsights style={{ fontSize: "1.5rem" }} /> */}
                {/* <ListItemText primary="Tracker" /> */}

                <CustomNavLink
                  to={"/dashboard/tracker"}
                  isSpocNavbar={true}
                  isClickable={false}
                  isTrackerMenu={true}
                >
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "default",
                    }}
                  >
                    Tracker{" "}
                    <MdKeyboardArrowDown
                      className="tracker-arrow"
                      style={{ fontSize: "1.5rem" }}
                    />{" "}
                  </h3>
                </CustomNavLink>
                <div className="tracker-dropdown">
                  <div className="tracker-row">
                    <CustomNavLink
                      to="/dashboard/tracker/kpi-monitoring"
                      isSpocNavbar={true}
                    >
                      <DashboardIcon style={{ fontSize: "1.5rem" }} />
                      <h4>ESG/KPI Monitoring</h4>
                    </CustomNavLink>
                    <CustomNavLink
                      to="/dashboard/tracker/department"
                      isSpocNavbar={true}
                    >
                      <AssignmentOutlinedIcon style={{ fontSize: "1.5rem" }} />
                      <h4>Department Tracker</h4>
                    </CustomNavLink>
                  </div>
                  <div className="tracker-row">
                    <CustomNavLink
                      to="/dashboard/tracker/operation-unit"
                      isSpocNavbar={true}
                    >
                      <AssignmentOutlinedIcon style={{ fontSize: "1.5rem" }} />
                      <h4>Operation Unit Tracker</h4>
                    </CustomNavLink>
                    <CustomNavLink
                      to="/dashboard/tracker/user-adoption"
                      isSpocNavbar={true}
                    >
                      <GroupIcon style={{ fontSize: "1.5rem" }} />
                      <h4>User Adoption</h4>
                    </CustomNavLink>
                  </div>
                </div>
              </div>
            </li>

            <li
              style={{
                minWidth: "0px",
                padding: "0px",
                textDecoration: "none",
                listStyle: "none",
              }}
            >
              <CustomNavLink
                to="/download_report"
                style={({ isActive }) => ({
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "grey",
                  justifyContent: "center",
                  ...(isActive ? activeStyle : {}),
                })}
              >
                <h3>Download Report </h3>
              </CustomNavLink>
            </li>

            <li
              style={{
                minWidth: "0px",
                padding: "0px",
                textDecoration: "none",
                listStyle: "none",
              }}
            >
              <CustomNavLink to="/dashboard/spoc-review">
                <h3>Review Data </h3>
              </CustomNavLink>
            </li>
          </ul>
          <li className="profile-icon">
            <ProfileIcon handleLogout={hadelLog} />
          </li>
        </>
      )}
    </nav>
  );
};

export default DashHamburger;
