import React, {useState} from "react";
import {
   Box,
   Button,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {Loader} from "../../components/Loader/Loader.jsx";
import {backBtnfixed} from "../../styles";
import {useLocation, useNavigate} from "react-router-dom";
import DetailsCard from "../../components/Cards/DetailsCard";
import OperationalUnitsTableCard from "../../components/Cards/OUDCard";
import NameCard from "../../components/Cards/NameCard";


const ViewOrganization = () => {
   const [loading, setLoading] = useState(false);
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const location = useLocation();
   const navigate = useNavigate();

   const {user: orgDetails} = location.state;

   const containerStyle = {
      maxWidth: isSmallScreen ? "90vw" : "50vw",
      margin: isSmallScreen ? "5rem auto" : "7.5rem auto",
      padding: "2rem",
      backgroundColor: "#f9f8f9",
      borderRadius: "35px",
   };

   const orgDetailsCleaned = {
      Organization: orgDetails.organizationName,
      Email: orgDetails.organizationEmail,
      Status: orgDetails.organizationStatus ? "Active" : "Inactive",
      Country: orgDetails.country,
      Address: orgDetails.address,
   };

   const spocDetailsCleaned = {
      name: orgDetails.spocName,
      email: orgDetails.spocEmail,
      mobile: `${orgDetails.countryCode} ${orgDetails.spocMobile}`,
   };

   const handleBack = () => {
      window.history.back();
   };

  const handleUpdate = () => {
    navigate("/organization/update-org", {
      state: {
        user: orgDetails,
      },
    });
  };

   return (
      <>
         <Hamburger/>
         <Box sx={containerStyle}>
            <NameCard details={orgDetails}/>
            <DetailsCard
              details = {{
                 organization : orgDetailsCleaned.Organization,
                 email : orgDetailsCleaned.Email,
                 status : orgDetailsCleaned.Status,
                 country : orgDetailsCleaned.Country,
                 address : orgDetailsCleaned.Address,
              }}
              isSmallScreen={isSmallScreen}
            />

            {/* Card 3: SPOC Details */}
            <DetailsCard
               details={spocDetailsCleaned}
               heading={"SPOC Details"}
               isSmallScreen={isSmallScreen}
            />

            {/* Card 4: Operational Units Table */}
            <OperationalUnitsTableCard
               operationalUnits={JSON.parse(JSON.parse(orgDetails.operationalUnits)).map(item => {
                  return {
                     [Object.keys(item)[0]]: Object.values(item)[0].split(", ")
                  }
               })}
               isSmallScreen={isSmallScreen}
            />
         </Box>
         <Button onClick={handleBack} style={backBtnfixed}>Back</Button>
        <Button onClick={handleUpdate} style={{...backBtnfixed, right: "5px", left: undefined}}>Edit Organization</Button>
         <Loader loading={loading}/>
      </>
   );
};

export default ViewOrganization;
