import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tablehead } from "../../styles";

export const PriorityTable = ({
  data,
  jsonData,
  selectedOption,
  handleOptionChange,
}) => {
  const tableData = [
    { label: data[0].A, key: "A", total: jsonData.A.A },
    {
      label: "All BRSR (including overlap with GRI)",
      key: "B",
      total: jsonData.B.B,
    },
    { label: data[1].B[0].BC, key: "BC", total: jsonData.BC.BC },
    { label: data[1].B[1].BE, key: "BE", total: jsonData.BE.BE },
    { label: data[1].B[2].BL, key: "BL", total: jsonData.BL.BL },
    { label: data[2].C, key: "C", total: jsonData.C.C },
    { label: data[3].D, key: "D", total: jsonData.D.D },
    { label: data[4].E, key: "E", total: jsonData.E.E },
    { label: data[5].S, key: "S", total: jsonData.S.S },
  ];

  return (
    <TableContainer sx={{ maxWidth: "800px", margin: "auto" }} className='priority-box'>
      <Table
        sx={{
          backgroundColor: "#fff",
        }}
        style={{borderRadius:"25px"}}
      >
        <TableHead>
          <TableRow sx={tablehead}>
            <TableCell
              sx={{
                fontSize: "1rem",
                color: "#3079bd",
                fontWeight: "bold",
                padding: "15px",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              Priorities
            </TableCell>
            <TableCell
              sx={{
                fontSize: "1rem",
                color: "#3079bd",
                fontWeight: "bold",
                padding: "15px",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              Total
            </TableCell>
            <TableCell
              sx={{
                fontSize: "1rem",
                color: "#3079bd",
                fontWeight: "bold",
                padding: "15px",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
            >
              Filtered based on selection
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  fontSize: "1rem",
                  color: "#3079bd",
                  fontWeight: "bold",
                  padding: "0px 20px",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <div key={index}>
                      {selectedOption === "B" &&
                      (row.key === "BC" ||
                        row.key === "BE" ||
                        row.key === "BL") ? (
                        <FormControlLabel
                          value={row.key}
                          control={
                            <Checkbox
                              sx={{ marginLeft: "30px" }}
                              size="small"
                              checked
                            />
                          }
                          label={`${row.key}: ${row.label}`}
                        />
                      ) : row.key === "BC" ||
                        row.key === "BE" ||
                        row.key === "BL" ? (
                        <Typography
                          sx={{
                            color: "#3079bd",
                            padding: "5px",
                            marginLeft: "30px",
                          }}
                        >
                          {" "}
                          {row.key}: {row.label}{" "}
                        </Typography>
                      ) : (
                        <FormControlLabel
                          value={row.key}
                          control={<Radio />}
                          label={`${row.key}: ${row.label}`}
                        />
                      )}
                    </div>
                  </RadioGroup>
                </FormControl>
                {/* {row.label} */}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                {row.total}
              </TableCell>
              <TableCell
                sx={{ padding: "0px", textAlign: "center", fontSize: "1rem" }}
              >
                {selectedOption === row.key
                  ? jsonData[selectedOption][selectedOption]
                  : jsonData[row.key][selectedOption] || 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
