import React, { useEffect, useRef, useState } from 'react';
import './ProgressBar.css'; // Import CSS for styling
import { Tooltip } from '@mui/material';

const ProgressBar = ({ currentScore, totalScore, approvedCount, dashboard, inView }) => {

  const [animate, setAnimate] = useState(false);
  const [percentageFilled, setPercentageFilled] = useState(0);
  const [animationSpeed, setAnimationSpeed] = useState(1.5); // Adjust animation speed here (in seconds)

  // console.log("inView", typeof inView);


  const progressRef = useRef(null);

  useEffect(() => {
    setAnimate(true);
    const calcualteScore = () => {
      let score
      if (dashboard === "spocReviewDashboard") {
        score = (approvedCount / (approvedCount + currentScore)) * 100
      } else {
        score = ((currentScore + approvedCount) / totalScore) * 100
      }
      if (isNaN(score)) {
        return 0
      }
      return score
    }
    setPercentageFilled(calcualteScore);
  }, [currentScore, totalScore, approvedCount]);

  // Calculate the dash offset based on the percentage filled
  const dashOffset = 283 - (283 * percentageFilled) / 100; // 283 is the circumference of a circle with radius 45

  useEffect(() => {
    if (animate) {
      const progressRing = progressRef.current;
      progressRing.style.transition = `stroke-dashoffset ${animationSpeed}s ease-out`;
      progressRing.style.strokeDashoffset = dashOffset;
    }
  }, [animate, animationSpeed, dashOffset]);

  // Determine color based on percentageFilled
  let color;
  if (percentageFilled >= 0 && percentageFilled < 30) {
    color = 'red';
  } else if (percentageFilled >= 30 && percentageFilled < 80) {
    color = '#ff9c00';
  } else if (percentageFilled >= 80 && percentageFilled <= 100) {
    color = '#45b653'; // Light green
  } else {
    color = '#45b653'; // Dark green
  }

  const reviewAndApprovedText = () => {
    if (dashboard === "spocReviewDashboard") {
      if (inView !== "2") {
        const yetToApproveCount = (approvedCount + currentScore) - approvedCount
        return yetToApproveCount === 0 ? "No Data Available" : `${yetToApproveCount} yet to approve`
      } else {
        return `${approvedCount} approved`
      }
    }
  }


  // const approvedCount =

  return (
    <Tooltip arrow title={dashboard === "spocReviewDashboard" && reviewAndApprovedText()}>
      <div className="round-progress-bar">
        <svg className="progress-ring" width="100" height="100">
          {/* Background circle */}
          <circle
            className="progress-ring-circle-back"
            stroke="#e0e0e0"
            strokeWidth="4" // Adjust thickness here
            fill="transparent"
            r="45"
            cx="50"
            cy="50"
          />
          {/* Progress circle */}
          <circle
            ref={progressRef}
            className="progress-ring-circle"
            stroke={color}
            strokeWidth="4"
            fill="transparent"
            r="45"
            cx="50"
            cy="50"
            style={{
              strokeDasharray: 283,
              strokeDashoffset: 283
            }}
          />
        </svg>

        <div className="progress-text">
          <div className="progress-count-round">
            <span style={{ color: "#3079bd", fontSize: '25px' }}>{dashboard === "spocReviewDashboard" ? approvedCount : currentScore + approvedCount}</span>/{dashboard === "spocReviewDashboard" ? approvedCount + currentScore : totalScore}
          </div>
          <div className="progress-percent">{percentageFilled.toFixed(1)}%</div>
        </div>
      </div>
    </Tooltip>

  );
};

export default ProgressBar;
