import React from 'react';
import { Button, Tooltip } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { usereditbutton, userviewbutton } from '../../styles';

const StatusButton = ({
    departmentStats,
    department,
    plant,
    transposed,
    handleEntryClick,
    buttonType = 'default'
}) => {
    const stats = departmentStats[!transposed ? plant : department];
    const savedCount = stats.saved || 0;
    const approvedCount = stats.approved || 0;
    const totalCount = stats.total || 0;

    // console.log("----->", savedCount, approvedCount, totalCount, buttonType);


    const renderButtons = () => {
        switch (buttonType) {
            case 'default':
                const completedCount = savedCount + approvedCount;
                const isComplete = completedCount === totalCount;

                return !isComplete ? (
                    <Tooltip
                        sx={{ marginTop: "20px" }}
                        title="Fill data"
                    >
                        <Button
                            onClick={() =>
                                handleEntryClick(
                                    !transposed ? department : plant,
                                    !transposed ? plant : department
                                )
                            }
                            style={userviewbutton}
                            className="fill-data"
                        >
                            <EditNoteIcon />
                            Fill data
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip
                        sx={{ marginTop: "20px" }}
                        title="View data"
                    >
                        <Button
                            onClick={() =>
                                handleEntryClick(
                                    !transposed ? department : plant,
                                    !transposed ? plant : department
                                )
                            }
                            style={usereditbutton}
                        >
                            <VisibilityIcon fontSize='small' sx={{ marginRight: "5px" }} />
                            View
                        </Button>
                    </Tooltip>
                );

            case 'review':
                return (
                    <>
                        {approvedCount !== savedCount + approvedCount && savedCount > 0 && (
                            <Tooltip
                                sx={{ marginTop: "20px" }}
                                title="Review Data"
                            >
                                <Button
                                    onClick={() =>
                                        handleEntryClick(
                                            !transposed ? department : plant,
                                            !transposed ? plant : department
                                        )
                                    }
                                    style={userviewbutton}
                                    className="spoc-review-btn"
                                >
                                    <VisibilityIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                    Review
                                </Button>
                            </Tooltip>
                        )}

                        {approvedCount === savedCount + approvedCount && savedCount > 0 && (
                            <Tooltip
                                sx={{ marginTop: "20px" }}
                                title="Review data"
                            >
                                <Button
                                    onClick={() =>
                                        handleEntryClick(
                                            !transposed ? department : plant,
                                            !transposed ? plant : department
                                        )
                                    }
                                    style={usereditbutton}
                                >
                                    <VisibilityIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                    Review
                                </Button>
                            </Tooltip>
                        )}
                    </>
                );

            case 'view':
                return (
                    <>
                        {approvedCount !== savedCount + approvedCount && approvedCount > 0 && (
                            <Tooltip
                                sx={{ marginTop: "20px" }}
                                title="View Approved Data"
                            >
                                <Button
                                    onClick={() =>
                                        handleEntryClick(
                                            !transposed ? department : plant,
                                            !transposed ? plant : department
                                        )
                                    }
                                    style={userviewbutton}
                                    className="spoc-review-btn"
                                >
                                    <VisibilityIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                    View
                                </Button>
                            </Tooltip>
                        )}

                        {approvedCount === savedCount + approvedCount && approvedCount > 0 && (
                            <Tooltip
                                sx={{ marginTop: "20px" }}
                                title="View data"
                            >
                                <Button
                                    onClick={() =>
                                        handleEntryClick(
                                            !transposed ? department : plant,
                                            !transposed ? plant : department
                                        )
                                    }
                                    style={usereditbutton}
                                >
                                    <VisibilityIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                    View
                                </Button>
                            </Tooltip>
                        )}
                    </>
                );

            default:
                return null;
        }
    };

    return renderButtons();
};

export default StatusButton;