import { useNavigate } from "react-router-dom";
import { React, useState } from "react";
import "../../styles.css";
import { Button } from "@mui/material";
import { buttoncontainded } from "../../styles";
import { toast } from "react-toastify";
import { newPassword } from "../../services/NewPasswordApi";
import {getUserEmail} from "../../services/loggedIn";

function NewPassword(prop) {
  const navigate = useNavigate();

  const responseData = getUserEmail();
  const [otp, setOtp] = useState("");
  const [password, setNewPassword] = useState("");

  const [confirmPassword, setconfirmPassword] = useState("");
  const [error, seterror] = useState("");
  async function handleSubmitOtp(e) {
    // Prevent the default browser behavior
    e.preventDefault();
    if (password === confirmPassword) {
      const json = {
        userKey: responseData,
        otp,
        password,
      };

      try {
        const response = await newPassword(json);
        if (response.data.status === "approved") {
          toast.success("Your password has been reset successfully");

          navigate("/");
        } else {
          // If the OTP or new password are invalid, show an error message

          toast.success(response.data.message);

          otp("");
          password("");
          navigate("/forgotpassword");
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Password not same as Confirm Password");
    }
  }
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    // Password validation conditions
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    // Constructing the error message dynamically
    let errorMessage = " ";
    if (!hasUpperCase) errorMessage += " one uppercase, ";
    if (!hasLowerCase) errorMessage += " one lowercase ,";
    if (!hasNumber) errorMessage += "  one number, ";
    if (!hasSpecialChar) errorMessage += "  one special CHAR, ";
    if (!isLengthValid)
      errorMessage += "Password must be at least 8 CHAR long. ";

    // Update the error state and password state
    seterror(errorMessage);
    if (errorMessage === " ") {
      seterror("");
    }
    setNewPassword(newPassword);
  };
  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleSubmitOtp} className="form-l">
        <h3 className="heading">Reset Password</h3>
        <input
          type="text"
          id="otp"
          name="otp"
          value={otp}
          onChange={handleOtpChange}
          placeholder="OTP"
          required
        />
        <input
          type="password"
          id="Password0"
          name="newPassword"
          value={password}
          onChange={handlePasswordChange}
          placeholder="NewPassword"
          // pattern="(?=.\d)(?=.[a-z])(?=.*[A-Z]).{8,}"
          required
        />
        {error}
        <input
          type="password"
          id="Password"
          name="newPassword"
          value={confirmPassword}
          onChange={(e) => setconfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          required
        />
        <Button style={buttoncontainded} type="submit" id="submitbtn0">
          Reset
        </Button>
      </form>
    </div>
  );
}
export default NewPassword;
