import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import {
  buttoncontaindeddraft,
  cancelBtn,
  modalDraftBtn,
  modalSubmitBtn,
} from "../../styles";
import CloseIcon from "@mui/icons-material/Close";

const BasicModal = ({
  openPopup,
  closePopup,
  handleNavigate,
  message,
  confirm,
  setConfirm,
  handleClickDraft,
  handleNavigateSaveDraft,
}) => {
  return (
    <Dialog
      //   TransitionComponent={Transition}
      onClose={closePopup}
      aria-labelledby="customized-dialog-title"
      open={openPopup}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <h5 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
          Submit or Draft the data
        </h5> */}
        <IconButton
          edge="end"
          backgroundColor="inherit"
          onClick={closePopup}
          aria-label="close"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "450px" }}>
        <h2 style={{ textAlign: "center" }}>{message}</h2>
      </DialogContent>
      <DialogActions sx={{ padding: " 10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          {!confirm && (
            <Button type="button" style={cancelBtn} onClick={closePopup}>
              Cancel
            </Button>
          )}
          <Button
            onClick={handleNavigateSaveDraft}
            type="submit"
            style={modalDraftBtn}
          >
            Save Draft
          </Button>

          <Button type="submit" style={modalSubmitBtn} onClick={handleNavigate}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BasicModal;
