import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import React from "react";

const PillarScoreCard = ({ scorecard }) => {
  let topicslength = {};
  const totalLengths = {};

  return (
    <Card style={{ background: "#f9f8f9" }} >
      <Table
        style={{
          width: "125px",
          position: "fixed",
          right: "5px",
          bottom: "12%",
          background: "#eee",
          boxShadow:
            " rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
        className="score-card"
      >
        <TableHead style={{ background: "transparent" }}>
          <TableRow>
            <TableCell></TableCell>
            {Object.keys(scorecard).map((pillar) => (
              <TableCell
                key={pillar}
                style={{ padding: "5px" }}
                className={
                  pillar === "Governance"
                    ? "pink"
                    : pillar === "Environment"
                    ? "env-bg"
                    : pillar === "Multiple"
                    ? "esg"
                    : pillar === "General Disclosures"
                    ? "orange1"
                    : pillar === "Management and Process Disclosures"
                    ? "teal"
                    : "blue1"
                }
              >
                {pillar.slice(0, 1)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell
              style={{
                padding: "5px",
              }}
            >
              Themes
            </TableCell>
            {Object.entries(scorecard).map(([pillar, counts]) => (
              <TableCell key={pillar} style={{ padding: "5px" }}>
                {counts.themes === topicslength[pillar] ? (
                  <>
                    <CheckCircleIcon
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  </>
                ) : (
                  counts.themes
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                padding: "5px",
              }}
            >
              Topics
            </TableCell>
            {Object.keys(scorecard).map((pillar) => (
              <TableCell key={pillar} style={{ padding: "5px" }}>
                {scorecard[pillar].topics === totalLengths[pillar] ? (
                  <>
                    <CheckCircleIcon
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  </>
                ) : (
                  <span>{scorecard[pillar].topics}</span>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default PillarScoreCard;
