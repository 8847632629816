import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnfixed, buttoncontaindedfixed } from "../../styles.js";
import { baseUrl } from "../../baseurl.js";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import Hamburger from "../Navbars/Hamburger.js";
import { FramerScroll } from "../FramerScroll/FramerScroll.jsx";
import { toast } from "react-toastify";
import ReviewUserTable from "../AssignmentManager/ReviewUserTable.jsx";
import encrypt, { decryptData } from "../../services/crypto.js";
import { assignKpisToUsers } from "../../services/FormManagerApi.js";
import { BsInfoCircle } from "react-icons/bs";
import ThankyouModal from "../PopUpDialog/ThankyouModal.jsx";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import ConfirmModal from "../PopUpDialog/ConfirmModal.jsx";
import ErrorModal from "../PopUpDialog/ErrorModal.jsx";
import { Loader } from "../Loader/Loader.jsx";
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../services/loggedIn.js";
import { JoyRide } from "../../Joyride.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice.js";
import pageguideimg from '../../navspoc (2).jpg'

const TableReview = () => {
  const location = useLocation();


  const selectedCount = location.state?.selectedCount;
  const totalKpiCount = location.state?.totalKpiCount;
  const unVisitedKpis = totalKpiCount - selectedCount;

  const organizationEmail = location.state.data;

  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    location.state?.selectedUsers || {}
  );
  // // // console.log("selectedUser", selectedUsers);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ous = [...new Set(data.map((item) => item.OU))];
  const tableData = data.reduce((acc, item) => {
    const { Department, OU, Users } = item;
    acc[Department] = { ...acc[Department], [OU]: Users };
    return acc;
  }, {});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const loginAttempts = getLoginAttempts();

  const dispatch = useDispatch();

  const { showTourModal, tourCompleted } = useSelector((state) => state.tourGuide);

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".review-table-ride",
      content:
        "Select users under departments/operational Units from the dropdowns to assign KPI",
      disableBeacon: true,
    },
    {
      target: ".final-assignment-btn",
      content:
        "The selected KPIs would be assigned to selected users on submission",
    },
  ];


  const handleUserChange = (department, ou, event) => {
    const newSelectedUsers = {
      ...selectedUsers,
      [`${department}-${ou}`]: event.target.value,
    };
    setSelectedUsers(newSelectedUsers);
  };
  // // // console.log("state", location.state);
  const data0 = location.state.data0;
  const org_email = location.state.data;


  const modifiedData0 = data.map((item) => {
    const { OU, Department, Users, Data, priorityTag } = item;

    const ouKey = `${Department}-${OU}`;

    // Get the selected user for the current OU and Department combination
    const selectedUser = selectedUsers[ouKey];
    if (Array.isArray(Users) && Users.length !== 0) {
      // Determine the user to include based on selection or use the original Users array
      const updatedUsers = selectedUser
        ? [
          {
            // Include all properties from the original Users array for the selected user
            ...Users.find((user) => user.userName === selectedUser),
            // Add additional properties from the selected user if needed
          },
        ]
        : [];

      // Transform the Data structure
      const transformedData = Object.keys(Data).reduce((acc, frequency) => {
        acc[frequency] = Data[frequency].map(kpiCode => {
          const kpiInfo = location.state.responseData.find(item => item.KPICode === kpiCode);
          return { [kpiCode]: kpiInfo ? kpiInfo.Format : null };
        });
        return acc;
      }, {});

      return {
        OU,
        Department,
        Users: updatedUsers,
        Data: transformedData,
        priorityTag,
      };
    } else {
      return null;
    }
  });



  const handleSubmit = async () => {
    if (!Object.keys(selectedUsers).length) {
      return toast.error("Select at least one user");
    }
    try {
      setLoading(true);

      // Create an array to store the modified data
      const modifiedData0 = data.map((item) => {
        const { OU, Department, Users, Data, priorityTag } = item;
        // // console.log("DAta", Data);

        const ouKey = `${Department}-${OU}`;

        // Get the selected user for the current OU and Department combination
        const selectedUser = selectedUsers[ouKey];
        if (Array.isArray(Users) && Users.length !== 0) {
          // Determine the user to include based on selection or use the original Users array
          const updatedUsers = selectedUser
            ? [
              {
                // Include all properties from the original Users array for the selected user
                ...Users.find((user) => user.userName === selectedUser),
                // Add additional properties from the selected user if needed
              },
            ]
            : [];

          return {
            OU,
            Department,
            Users: updatedUsers,
            Data,
            priorityTag,
          };
        } else {
          return null;
        }
      });
      const modifiedData = modifiedData0.filter((item) => item !== null);
      const encryptedAssignData = encrypt({ modifiedData });
      await assignKpisToUsers(encryptedAssignData, organizationEmail)
        .then((response) => {
          setLoading(true);
          toast.success(response.data.message);
          setShowModal(true);
          setLoading(false);
        })
        .catch((error) => {
          // alert(error.message);
          toast.error(error.message);
        });

      setLoading(false);
      setSelectedUsers({});
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      const json = {
        KPICodes: data0,
        organizationEmail: org_email,
      };

      const encryptData = encrypt({ json });

      axios
        .post(
          `${baseUrl}/getMappedTable`,
          { encryptedData: encryptData },
          { withCredentials: true }
        )
        .then((response) => {
          setLoading(false);

          const decryptedData = decryptData(response.data);

          const parsedData = JSON.parse(decryptedData);
          // // console.log("parsedData.finalMappedTable", parsedData.finalMappedTable);

          setData(parsedData.finalMappedTable);
          const allUsersEmpty = parsedData.finalMappedTable.every(
            (item) => item.Users.length === 0
          );

          if (parsedData.finalMappedTable.length === 0 || allUsersEmpty) {
            setShowErrorModal(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchOrganizations();
  }, []);

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
  };

  const closePopup = () => {
    setShowModal(false);
    setShowConfirmModal(false);
  };

  const openShowConfirmModal = () => {
    if (!Object.keys(selectedUsers).length) {
      return toast.error("Select at least one user");
    }
    setShowConfirmModal(true);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const step = 4;

  const handleBack = () => {
    navigate("/assignment/select-kpis", {
      state: {
        org: location.state?.org,
        isAssigned: location.state?.isAssigned,
        prioritiesJson: location.state?.prioritiesJson,
        selectedData: location.state?.selectedData,
        selectedCategories: location.state?.selectedCategories,
        selectedSubcategories: location.state?.selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: location.state?.responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        totalKpiCount: location.state?.totalKpiCount,
        selectedUsers: selectedUsers,
      },
    });
  };
  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <Hamburger />
      <FramerScroll />
      <JoyRide
        steps={steps}

      />
      {showErrorModal ? (
        <ErrorModal
          openPopup={showErrorModal}
          closePopup={handleErrorModalClose}
          handleBack={handleBack}
        />
      ) : (
        <div
          style={{
            marginTop: "5%",
            padding: "2rem",
            borderRadius: "20px",
            backgroundColor: "#f9f8f9",
          }}
        >
          {/* <h4 className="frame_heading">
          Review User Assocation with OU-Department Matrix
        </h4> */}
          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <MultiStepAssignManager step={step} />
          </Box>
          <h4 className="frame_heading">Select User To Assign KPI's</h4>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsInfoCircle />
            &nbsp; Select User based on particular department and operation unit
          </p>
          <ReviewUserTable
            ous={ous}
            tableData={tableData}
            selectedUsers={selectedUsers}
            handleUserChange={handleUserChange}
          />

          <form>
            <Button
              disabled={loading}
              onClick={openShowConfirmModal}
              style={buttoncontaindedfixed}
              className="final-assignment-btn"
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </form>
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
        </div>
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Are you sure you want to continue submitting assignment?"}
          confirmText={"continue"}
          cancelText={"cancel"}
          onConfirm={handleSubmit}
          selectedCount={selectedCount}
          totalKpiCount={totalKpiCount}
          unVisitedKpis={unVisitedKpis}
        />
      )}

      {showModal && (
        <Backdrop>
          <ThankyouModal
            openPopup={openPopup}
            closePopup={closePopup}
            handleNavigate={handleNavigate}
            message={"Thank You for the Assignment!"}
            title={"Assignment Completed"}
            confirmText={"OK"}
          />
        </Backdrop>
      )}

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={
    <div style={{ display: "flex",flexDirection:'column', alignItems: "center" }}>
      <span>Would you like a tour of the page?</span>
      <p style={{color:'red'}}>Click on the icon at top-right corner of the page to have a tour anytime</p>
      <img 
        src={pageguideimg} 
        alt="guide"
      />
    </div>
  }
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};
export default TableReview;
