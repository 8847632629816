import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getCountryCurrency, getUserRole } from "../../services/loggedIn";
import { BsInfoCircle } from "react-icons/bs";
import {
  buttoncontaindedfixed,
  buttoncontaindedfixeddisabled,
} from "../../styles";
import { useNavigate } from "react-router-dom";
import BasicModal from "../PopUpDialog/BasicModal";
import ConfirmModal from "../PopUpDialog/ConfirmModal";
import SimpleModal from "../PopUpDialog/SimpleModal";
import {
  handleSaveDraft,
  handleSubmit,
} from "../../pages/ClientSide/ExtraFiles/formHandler";
import { getUserEmail } from "../../services/loggedIn";
import {
  resetMatrixKPIState,
  setMatrixKpiInputdata,
} from "../../redux/slice/KpiSlice";
import { useDispatch, useSelector } from "react-redux";
import { updatePreservedInput } from "../../redux/slice/PreservedSlice";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CommentIcon from '@mui/icons-material/Comment';
import { CommentPopUp } from "../../dashboard/pages/SPOCDataReview/CommentPopUp";
import { getInitialValue, initializeItemWithDefaults } from "../../services/helpingFunctions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const MatrixTable = ({
  orgplt,
  orgdept,
  setTableFromat,
  tableData,
  setKpiinputdata = () => { },
  tableRendering,
  tableDraftRendering,
  status,
  frequency,
  kpicode,
  reviewedBy,
  isreviewed,
  comments
}) => {

  // console.log("tableDraftRendering", tableDraftRendering);


  const { matrixKpiInputdata } = useSelector((state) => state.kpi);
  const preservedInputs = useSelector((state) => state.preservedInputs);


  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInView, setModalInView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [updatedTableData, setUpdatedTableData] = useState();
  const [editableInput, setEditiableInput] = useState();
  const [filledKpis, setFilledKpis] = useState(0);
  const [draftedKpis, setDraftedKpis] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);


  const formData = "";
  let fdata = "";
  const currentYears = "";
  const currentMonths = "";
  const data = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentKpiData = matrixKpiInputdata.find(item => Object.keys(item)[0] === `${frequency}~${kpicode}~${orgdept}~${orgplt}`);

  // console.log("currentKpiData", currentKpiData);

  // useEffect(() => {
  //   // Initialize the table data with empty values while maintaining the structure
  //   const initialData = tableData.map(item => {
  //     const newItem = { ...item };
  //     // For each field that has a format, initialize an empty value
  //     Object.keys(newItem).forEach(key => {
  //       if (newItem[key]?.format?.includes("input")) {
  //         if (newItem[key]?.format?.includes("input-decimal") || newItem[key]?.format?.includes("input-wholeNumber") || newItem[key]?.format?.includes("input-number") || newItem[key]?.format?.includes("input-percent") || newItem[key]?.format?.includes("input-currency")) {
  //           newItem[key] = {
  //             ...newItem[key],
  //             value: '0'  // Initialize default value
  //           };
  //         } else if (newItem[key]?.format?.includes("input?srishti") || newItem[key]?.format?.includes("input-openText") || newItem[key]?.format?.includes("input-Binary") || newItem[key]?.format?.includes("input-dropDown(") || newItem[key]?.format?.includes("input-eMail") || newItem[key]?.format?.includes("input-url")) {
  //           newItem[key] = {
  //             ...newItem[key],
  //             value: 'Not Filled'  // Initialize default value
  //           };
  //         } else {
  //           console.log("0");
  //         }
  //         // newItem[key] = {
  //         //   ...newItem[key],
  //         //   value: ''  // Initialize empty value
  //         // };
  //       }
  //     });
  //     return newItem;
  //   });

  //   setUpdatedTableData(initialData);

  //   if (!currentKpiData) {
  //     setEditiableInput(tableDraftRendering?.map((item) => ({ ...item })));
  //   } else {
  //     setEditiableInput(currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]);
  //   }
  // }, [tableDraftRendering, currentKpiData, frequency, kpicode, orgdept, orgplt]);

  useEffect(() => {
    // Initialize table data with default values
    const initialData = tableData.map(initializeItemWithDefaults);
    setUpdatedTableData(initialData);

    // Set editable input based on current KPI data or draft rendering
    const editableData = currentKpiData
      ? currentKpiData[`${frequency}~${kpicode}~${orgdept}~${orgplt}`]
      : tableDraftRendering?.map(item => ({ ...item }));

    setEditiableInput(editableData);
  }, [
    tableDraftRendering,
    currentKpiData,
    frequency,
    kpicode,
    orgdept,
    orgplt,
    tableData,
  ]);

  const openPopup = () => {
    setShowModal(true);
  };

  const handleCommentBoxOpen = (detail) => {
    setCommentBoxOpen(true);
  };
  const handleCommentBoxClose = () => {
    setCommentBoxOpen(false);
  };

  const handleNavigate = async () => {
    if (!confirm) {
      const returnedValue = await handleClick();
      if (returnedValue !== "0") {
        setShowModal(false);
        setFilledKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
      dispatch(resetMatrixKPIState());
    } else {
      setShowModal(false);
    }
  };

  const handleNavigateSaveDraft = async () => {
    if (!confirm) {
      const returnedValue = await handleClickDraft();
      if (returnedValue !== "0") {
        setShowModal(false);
        setDraftedKpis(returnedValue);
        setSecondModal(true);
        // navigate("/reporting_dashboard");
      } else {
        setShowModal(false);
        setSecondModal(false);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleClick = () => {
    setLoading(true);
    try {
      const returnValue = handleSubmit(
        dispatch,
        updateDrafTableData("saved"),
        formData,
        fdata,
        currentYears,
        currentMonths,
        data,
        setLoading,
        setShowConfirmModal,
        setModalInView,
        modalInView,
        [], // editedFields
        "" // countryCode
      );
      return returnValue;
    } catch (error) {
      console.error("Error in handleClick:", error);
      setLoading(false);
      return "0";
    }
  };

  const showToast = true;
  const handleClickDraft = () => {
    const returnValue = handleSaveDraft(
      dispatch,
      updateDrafTableData("drafted"),
      formData,
      fdata,
      currentYears,
      currentMonths,
      data,
      setLoading,
      setIsSavingDraft,
      [],
      "",
      showToast
    );
    return returnValue;
  };

  const closePopup = () => {
    setShowModal(false);
    setSecondModal(false);
    setShowConfirmModal(false);
    setModalInView(false);
    // fetchData();
  };



  const handleInputChange = (index, key, value) => {
    setUpdatedTableData(prevData => {
      // Only update if the value is actually different
      if (prevData[index]?.[key]?.value === value) {
        return prevData; // Return same reference if no change
      }

      const newData = [...prevData];
      if (newData[index] && newData[index][key]) {
        newData[index] = {
          ...newData[index],
          [key]: {
            ...newData[index][key],
            value: value
          }
        };
      }
      return newData;
    });
    // Create a deep copy of updatedTableData
    // let updatedData = JSON.parse(JSON.stringify(updatedTableData));

    // // Update the value property of the corresponding object
    // if (updatedData[index] && updatedData[index][key]) {
    //   updatedData[index][key].value = value;
    // }
    // Update the state with the modified data
    // setUpdatedTableData(updatedData);
  };

  // console.log("UpdatedTableData", updatedTableData);



  const handleEditInputChange = (index, key, value) => {
    setEditiableInput((prevInput) => {
      if (!prevInput || !Array.isArray(prevInput)) {
        console.error("prevInput is not an array:", prevInput);
        return prevInput; // Return unchanged if it's not an array
      }
      const newInput = [...prevInput];
      if (!newInput[index]) {
        console.error("No item at index:", index);
        return prevInput; // Return unchanged if index doesn't exist
      }
      newInput[index] = {
        ...newInput[index],
        [key]: { ...newInput[index][key], value: value },
      };
      return newInput;
    });
  };

  const handleChange = (index, key, value, flag) => {

    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(updatedTableData));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        const format = updatedData[index][key].format?.split(":")[1] ?? updatedData[index][key].format;
        updatedData[index][key].format = format;
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setUpdatedTableData(updatedData);
    }
  };

  const handleDraftChange = (index, key, value, flag) => {
    if (flag) {
      // Create a deep copy of updatedTableData
      let updatedData = JSON.parse(JSON.stringify(editableInput));

      // Update the value property of the corresponding object
      if (updatedData[index] && updatedData[index][key]) {
        updatedData[index][key].value = value;
      }

      // Update the state with the modified data
      setEditiableInput(updatedData);
      // // // console.log("editableInput", editableInput);
    }
  };

  const submit = () => {
    const kpi_data = {
      [frequency + "-" + kpicode]: updatedTableData,
    };

    // Create the structure for current submission
    const currentData = {
      [frequency]: updatedTableData
    };

    // Update accumulated data by merging with any existing data for this KPI
    const kpiKey = `${kpicode}-${orgdept}-${orgplt}`;

    // Create a deep copy of updatedTableData
    let updatedData = JSON.parse(JSON.stringify(updatedTableData));


    const transformedData = updatedData.map(item => {
      const newItem = { ...item };
      Object.keys(newItem).forEach(key => {
        if (newItem[key]?.format) {
          // Check if this is an input field that needs format restoration
          if (typeof newItem[key].format === 'string' &&
            (newItem[key].format.includes('input-decimal') ||
              newItem[key].format.includes('input-wholeNumber') ||
              newItem[key].format.includes('input-number') ||
              newItem[key].format.includes('input-percent'))
          ) {

            // Get the reference key from the format (everything after the colon)
            const referenceKey = newItem[key].format?.split(":")[1] ?? newItem[key].format;

            newItem[key] = {
              ...newItem[key],
              format: referenceKey
            };
          }
        }
      });
      return newItem;
    });

    // console.log("trans", transformedData);

    // Update Redux state
    dispatch(updatePreservedInput({
      kpiCode: kpiKey,
      period: frequency,
      value: transformedData,
      department: orgdept,
      operationalUnit: orgplt
    }));

    setFieldsSaved(true);
    toast.success("Fields saved successfully");
    // setKpiinputdata(kpi_data);
  };

  const submitForDraft = () => {
    if (typeof setKpiinputdata === "function") {

      const kpi_data = {
        [frequency + "~" + kpicode]: editableInput,
      };

      const redux_kpi_key = {
        [frequency + "~" + kpicode + "~" + orgdept + "~" + orgplt]: editableInput
      }

      toast.success("Fields saved successfully");
      dispatch(setMatrixKpiInputdata(redux_kpi_key));
      setKpiinputdata(kpi_data);
    } else {
      console.error("setKpiinputdata is not a function");
    }
  };

  function valnum(value) {
    const regex = /^(^$)|(^(0(\.\d{0,2})?|([1-9]\d{0,12})(\.\d{0,2})?))$/;
    return regex.test(value);
  }

  const validateNumber = (value, onChange, key, index) => {
    // Remove 'e' and other non-numeric characters immediately
    const sanitizedValue = value.replace(/[eE]/g, '');
    // Validate number: maximum 13 places and two decimal places
    if (valnum(sanitizedValue)) {
      if (status === "drafted") {
        handleDraftChange(index, key, value, valnum(sanitizedValue));
      } else {
        onChange(sanitizedValue);
      }
    } else {
      toast.error("Invalid number format!");
    }
  };
  const validatePerc = (value) => {
    const regex = /^(100(\.00?)?|\d{0,2}(\.\d{0,2})?)$/;
    return regex.test(value);
  };
  const validatePercent = (value, onChange, index, key) => {
    if (validatePerc(value)) {
      if (status === "drafted") {
        handleEditInputChange(index, key, value)
      } else {
        onChange(value);
      }
    } else {
      toast.error("Invalid percent format!");
    }
  };

  const validateURL = (value, onChange, index, key) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = regex.test(value);
    if (isValid) {
      if (status === "drafted") {
        handleEditInputChange(index, key, value)
      } else {
        onChange(value)
      }
    } else {
      const validationToastId = 'invalid-URL-toast';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid URL format!", {
          toastId: validationToastId,
        });
      }
    }
  };

  const validateEmail = (value, onChange, index, key) => {
    const regex = /^[a-zA-Z0-9._@]*$/;
    const isValid = regex.test(value);
    if (isValid) {
      if (status === "drafted") {
        handleEditInputChange(index, key, value)
      } else {
        onChange(value)
      }
    } else {
      const validationToastId = 'invalid-email-toast';
      if (!toast.isActive(validationToastId)) {
        toast.error("Invalid Email format!", {
          toastId: validationToastId,
        });
      }
    }
  };

  const updateDrafTableData = (draftStatus) => {
    const determineFrequency = () => {
      if (!frequency) {
        console.warn(`No frequency provided for KPI code: ${kpicode}`);
        return {};
      }

      // Check if the frequency is in the format 'MMM\'YY' (e.g., 'Aug\'24')
      if (/^\w{3}'\d{2}$/.test(frequency)) {
        return { Month: frequency };
      }

      // Check if the frequency is in the format 'YYYY-YYYY' (e.g., '2024-2025')
      if (/^\d{4}-\d{4}$/.test(frequency)) {
        return { Year: frequency };
      }

      // If it's neither a month, a year, nor a year range format we recognize, log a warning and return as-is
      console.warn(`Unexpected frequency format: ${frequency}`);
      return { Frequency: frequency };
    };

    // // // console.log("determineFrequency", determineFrequency());

    const transformedData = editableInput.map(item => {
      const newItem = { ...item };
      Object.keys(newItem).forEach(key => {
        if (newItem[key]?.format) {
          // Check if this is an input field that needs format restoration
          if (typeof newItem[key].format === 'string' &&
            (newItem[key].format.includes('input-decimal') ||
              newItem[key].format.includes('input-wholeNumber') ||
              newItem[key].format.includes('input-number') ||
              newItem[key].format.includes('input-percent'))
          ) {

            // Get the reference key from the format (everything after the colon)
            const referenceKey = newItem[key].format?.split(":")[1] ?? newItem[key].format;

            newItem[key] = {
              ...newItem[key],
              format: referenceKey
            };
          }
        }
      });
      return newItem;
    });

    return [
      {
        KPICode: kpicode,
        KPIInput: transformedData,
        format: setTableFromat,
        draftStatus: draftStatus,
        frequency: determineFrequency(),
        department: orgdept,
        operationalUnit: orgplt,
        userEmail: getUserEmail(),
      },
    ];
  };


  const handleBack = () => {
    navigate(-1);
  };

  // console.log("updatedTableData", updatedTableData);


  // Input Renderer Utility
  const InputRenderers = {
    // Utility to extract options from format string
    extractOptions: (format) => {
      const optionsMatch = format.match(/\(([^)]+)\)/);
      return optionsMatch ? optionsMatch[1].split('|') : [];
    },

    // Generic input type handlers
    renderDropdown: (
      inputType,
      value,
      onChange,
      extraProps
    ) => {
      const options = InputRenderers.extractOptions(inputType);

      return (
        <select
          value={value === "Not Filled" ? "" : value || ""}
          onChange={(e) => onChange(e.target.value)}
          {...extraProps}
        >
          <option value="">Select Option</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    },


    renderWholeNumberInput: (
      value,
      onChange,
      inputType,
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={parseInt(value) > 0 ? value : "" || ""}
          placeholder={`Enter Number (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "0" : value;
            validateNumber(newValue, onChange)
          }}
          inputProps={{
            maxLength: limit,
          }}
        />
      );
    },

    renderOpenTextInput: (
      value,
      onChange,
      inputType,
    ) => {
      const limit = inputType.match(/\(([^)]+)\)/)?.[1];

      return (
        <TextField
          type="text"
          value={value === "Not Filled" ? "" : value || ""}
          placeholder={`Enter Open Text (max ${limit})`}
          onChange={(e) => {
            const { value } = e.target;
            const newValue = value.trim() === "" ? "Not Filled" : value;
            onChange(newValue)
          }}
          inputProps={{
            maxLength: limit,
          }}
        />
      );
    },

    // Main input renderer
    renderInput: (
      inputType,
      value,
      onChange
    ) => {

      if (inputType?.includes('dropDown(') || inputType?.includes('Binary(')) {
        return InputRenderers.renderDropdown(inputType, value, onChange);
      }

      if (inputType?.includes('wholeNumber(')) {
        return InputRenderers.renderWholeNumberInput(value, onChange, inputType);
      }

      if (inputType?.includes('openText(')) {
        return InputRenderers.renderOpenTextInput(value, onChange, inputType);
      }

      // Default type-based renderers
      switch (inputType) {
        case 'openText':
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
            />
          );

        case 'wholeNumber':
          return (
            <TextField
              type="number"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateNumber(newValue, onChange)
              }}
              placeholder="Enter whole number"
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
            />
          );

        case 'percent':
          return (
            <TextField
              type="percent"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validatePercent(newValue, onChange)
              }}
              placeholder="Enter percentage"
            />
          );

        case 'decimal':
        case 'number':
          return (
            <TextField
              type="number"
              step="any"
              value={parseInt(value) > 0 ? value : "" || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "0" : value;
                validateNumber(
                  newValue,
                  onChange
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
              placeholder="Enter number"
            />
          );

        case 'currency':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="number"
                value={parseInt(value) > 0 ? value : "" || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  const newValue = value.trim() === "" ? "0" : value;
                  validateNumber(
                    newValue,
                    onChange
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter currency"
                style={{ marginRight: '8px' }}
              />
              <span>{getCountryCurrency()}</span>
            </div>
          );

        case 'url':
          return (
            <TextField
              type="text"
              placeholder="Enter URL"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                validateURL(
                  newValue,
                  onChange
                );
              }}
            />
          );

        case 'Binary':
          return InputRenderers.renderDropdown(
            'input-dropDown(Yes|No)',
            value,
            onChange
          );

        case 'eMail':
          return (
            <TextField
              variant="outlined"
              type="email"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                validateEmail(
                  newValue,
                  onChange
                );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              placeholder="Enter Email"
            />
          );
        case 'DatePicker':
          return (
            <div style={{ width: "200px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={value === "Not Filled" ? "" : value ? (() => {
                    const [day, month, year] = value.split('/');
                    return new Date(year, month - 1, day);
                  })() : null}
                  onChange={(value) => {
                    if (value && value instanceof Date && !isNaN(value)) {
                      const day = value.getDate().toString().padStart(2, '0');
                      const month = (value.getMonth() + 1).toString().padStart(2, '0');
                      const year = value.getFullYear();
                      const formattedDate = `${day}/${month}/${year}`;
                      onChange(formattedDate);
                    } else {
                      onChange(null);
                    }
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
          );
        default:
          return (
            <TextField
              type="text"
              value={value === "Not Filled" ? "" : value || ""}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value.trim() === "" ? "Not Filled" : value;
                onChange(newValue)
              }}
              placeholder="Open Text"
            />
          );
      }
    }
  };

  const renderCellValue = (item, value, index, key) => {
    // const fieldValue = value.format.replace(/"/g, "");
    if (value?.format?.includes("input")) {
      let fieldType
      const currentValue = item[key]?.value || '';
      if (value.format.includes(":")) {
        const field = value.format.split("-")[1];
        fieldType = field.split(":")[0]
      } else {
        const field = value.format.split("-")[1];
        fieldType = field
      }

      return InputRenderers.renderInput(
        fieldType,
        currentValue,
        (value) => handleInputChange(index, key, value),
      );


      // if (value.format.includes("dropDown") || value.format.includes("Binary(")) {
      //   const options = item[key]?.format.match(/\(([^)]+)\)/)[1].split("|");
      //   return (
      //     <select
      //       onChange={(e) => handleInputChange(index, key, e.target.value)}
      //     >
      //       <option value="">Select Option</option>
      //       {options.map((option, idx) => (
      //         <option key={idx} value={option}>
      //           {option}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // } else if (fieldType === "Binary") {
      //   return (
      //     <select
      //       onChange={(e) => handleInputChange(index, key, e.target.value)}
      //     >
      //       <option value="">Select Option</option>
      //       <option value="Yes">Yes</option>
      //       <option value="No">No</option>
      //     </select>
      //   );
      // } else if (
      //   typeof item[key]?.format === "string" &&
      //   item[key].format.includes("input")
      // ) {
      //   switch (fieldType) {
      //     case "number":
      //     case "decimal":
      //     case "wholeNumber":
      //       return (
      //         <TextField
      //           type="text"
      //           required
      //           placeholder="Enter Whole Number"
      //           onChange={(e) => {
      //             validateNumber(e.target.value, handleInputChange, key, index);
      //           }}
      //           onKeyDown={(e) => {
      //             if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
      //               e.preventDefault();
      //             }
      //           }}
      //           pattern="^[0-9]*\.?[0-9]*$"
      //           onKeyPress={(e) => {
      //             if (!/[0-9.]/.test(e.key)) {
      //               e.preventDefault();
      //             }
      //             // Prevent multiple decimal points
      //             if (e.key === '.' && e.target.value.includes('.')) {
      //               e.preventDefault();
      //             }
      //           }}
      //         />
      //       );
      //     case "currency":
      //       return (
      //         <>
      //           <TextField
      //             type="number"
      //             placeholder="Enter Currency"
      //             required
      //             onChange={(e) => {
      //               validateNumber(
      //                 e.target.value,
      //                 handleInputChange,
      //                 key,
      //                 index
      //               );
      //             }}
      //           />
      //           <span>{getCountryCurrency()} </span>
      //         </>
      //       );
      //     case "percent":
      //       return (
      //         <TextField
      //           type="percent"
      //           required
      //           placeholder="Enter Percent"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             validatePercent(value, handleInputChange, index, key);
      //           }}
      //         />
      //       );
      //     case "openText":
      //       return (
      //         <TextField
      //           type="text"
      //           required
      //           inputProps={{ maxLength: 500 }}
      //           placeholder="Enter Text"
      //           onChange={(e) => handleInputChange(index, key, e.target.value)}
      //         />
      //       );
      //     case "url":
      //       return (
      //         <TextField
      //           type="text"
      //           required
      //           placeholder="Enter URL"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             if (validateURL(value)) {
      //               handleInputChange(index, key, value);
      //             }
      //           }}
      //         />
      //       );

      //     case "eMail":
      //       return (
      //         <TextField
      //           type="text"
      //           required
      //           placeholder="Enter Email"
      //           onChange={(e) => {
      //             const { value } = e.target;
      //             if (validateEmail(value)) {
      //               handleInputChange(index, key, value);
      //             }
      //           }}
      //         />
      //       );
      //     default:
      //       return (
      //         <TextField
      //           type="text"
      //           required
      //           placeholder="Enter Textt"
      //           onChange={(e) => handleInputChange(index, key, e.target.value)}
      //         />
      //       );
      //   }
      // }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      return (
        <TextField
          type="text"
          required
          value={item[fieldValue]}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          //TODO : Add Enter Number in place holder
          placeholder="no proper field being recived"
        />
      );
    }
    // if (
    //   value?.format?.includes("(") &&
    //   value?.format?.includes(")") &&
    //   (value?.format?.includes("*") ||
    //     value?.format?.includes("/") ||
    //     value?.format?.includes("+") ||
    //     value?.format?.includes("-"))
    // ) {
    //   return null;
    // }
    if (
      (value?.format?.includes("(") && value?.format?.includes(")")) ||
      value?.format?.includes("*") ||
      value?.format?.includes("/") ||
      value?.format?.includes("+") ||
      value?.format?.includes("-")
    ) {
      return null;
    }
    if (
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-")) &&
      item.autoCompute === "False"
    ) {
      return null;
    }

    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      // value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submit}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const renderCellValue1 = (value, item) => {
    if (value?.format === "Header" || value?.format === "Headers") {
      // return item.Table_name.replaceAll("_", " ");
      return item.Table_name;
    } else if (item.Field_button === "True") {
      return null;
    } else {
      if (typeof value?.format !== "string") {
        // value = value?.format.replaceAll("_", " ");
        value = value?.format;
        return value;
      } else {

        return typeof value?.value === "string"
          ? value.value.replaceAll("_", " ")
          : value?.value;
      }
    }
  };

  // console.log("editiableInput", editableInput);

  const renderEditableCellValue = (item, value, index, key) => {
    if (!item || !value) {
      console.error("Invalid item or value:", { item, value, index, key });
      return null;
    }
    if (value?.format?.includes("input")) {
      let fieldType
      if (value.format?.includes(":")) {
        const field = value.format.split("-")[1];
        fieldType = field.split(":")[0]
      } else {
        const field = value.format.split("-")[1];
        fieldType = field
      }
      const existingValue = item[key]?.value || "";

      if (fieldType?.includes('openText(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];
        return (
          <TextField
            type="text"
            value={existingValue === "Not Filled" ? "" : existingValue || ""}
            placeholder={`Enter Open Text (max ${limit})`}
            onChange={(e) => {
              const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
              handleEditInputChange(index, key, newValue)
            }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }

      if (fieldType?.includes('wholeNumber(')) {
        const limit = fieldType.match(/\(([^)]+)\)/)?.[1];
        return (
          <TextField
            type="text"
            value={parseInt(existingValue) > 0 ? existingValue : ""}
            placeholder={`Enter Number (max ${limit})`}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value.trim() === "" ? "0" : value;
              validateNumber(newValue, handleEditInputChange, key, index)
            }}
            inputProps={{
              maxLength: limit,
            }}
          />
        );
      }
      if (fieldType?.includes('dropDown(')) {
        const options = InputRenderers.extractOptions(fieldType);
        return (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Option"
              // sx={{ width: '100px' }}
              value={existingValue === "Not Filled" ? "" : existingValue || ""}
              onChange={(e) => handleEditInputChange(index, key, e.target.value)}
            >
              <MenuItem value="Not Filled">Select Option</MenuItem>
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }

      if (value.format?.includes("Binary(")) {
        const options = InputRenderers.extractOptions(fieldType);
        return (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Option"
              // sx={{ width: '100px' }}
              value={existingValue === "Not Filled" ? "" : existingValue || ""}
              onChange={(e) => handleEditInputChange(index, key, e.target.value)}
            >
              <MenuItem value="Not Filled">Select Option</MenuItem>
              {options.map((option, idx) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else if (fieldType === "Binary") {
        return (
          <select
            value={existingValue}
            onChange={(e) => handleEditInputChange(index, key, e.target.value)}
          >
            <option value="Not Filled">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      } else if (
        typeof item[key]?.format === "string" &&
        item[key].format.includes("input")
      ) {
        switch (fieldType) {
          case "number":
          case "decimal":
          case "wholeNumber":
          case "currency":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : ""}
                placeholder="Enter Number"
                required
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                  validateNumber(
                    newValue,
                    handleEditInputChange,
                    key,
                    index
                  );
                }}
              />
            );
          case "percent":
            return (
              <TextField
                type="text"
                value={parseInt(existingValue) > 0 ? existingValue : ""}
                placeholder="Enter Percent"
                required
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "0" : e.target.value;
                  validatePercent(
                    newValue,
                    handleEditInputChange,
                    index,
                    key
                  );
                }}
              />
            );
          case "url":
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter URL"
                required
                onChange={(e) => {
                  const isValid = validateURL(e.target.value);
                  if (isValid) {
                    const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                    handleEditInputChange(index, key, newValue);
                  }
                }}
              />
            );
          case "eMail":
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Email"
                required
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                  validateEmail(newValue,
                    handleEditInputChange,
                    index,
                    key);
                }}
              />
            );
          case 'DatePicker':
            return (
              <div style={{ width: "200px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={getInitialValue(value)}
                    onChange={(value) => {
                      if (value && value instanceof Date && !isNaN(value)) {
                        const day = value.getDate().toString().padStart(2, '0');
                        const month = (value.getMonth() + 1).toString().padStart(2, '0');
                        const year = value.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;
                        handleEditInputChange(index, key, formattedDate);
                      } else {
                        handleEditInputChange(index, key, null);
                      }
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
            );
          case "openText":
          default:
            return (
              <TextField
                type="text"
                value={existingValue === "Not Filled" ? "" : existingValue || ""}
                placeholder="Enter Text"
                required
                onChange={(e) => {
                  const newValue = e.target.value.trim() === "" ? "Not Filled" : e.target.value;
                  handleEditInputChange(index, key, newValue)
                }}
              />
            );
        }
      }
    }
    if (
      value?.format?.match(/".*"/) &&
      !value?.format?.includes("*") &&
      !value?.format?.includes("/") &&
      !value?.format?.includes("+") &&
      !value?.format?.includes("-") &&
      !value?.format?.includes("input")
    ) {
      // replaced all back slash
      const fieldValue = value?.format?.replace(/"/g, "");
      const existingValue = item[key]?.value || "";

      return (
        <TextField
          type="text"
          value={existingValue}
          onChange={(e) => {
            validateNumber(e.target.value, handleInputChange, key, index);
          }}
          placeholder="Enter Number"
          required
        />
      );
    }

    if (
      (value?.format?.includes("(") && value?.format?.includes(")")) ||
      value?.format?.includes("*") ||
      value?.format?.includes("/") ||
      value?.format?.includes("+") ||
      value?.format?.includes("-")
    ) {
      return null;
    }

    if (
      (value?.format?.includes("*") ||
        value?.format?.includes("/") ||
        value?.format?.includes("+") ||
        value?.format?.includes("-")) &&
      item.autoCompute === "False"
    ) {
      return null;
    }
    if (value?.format === "Header" || value?.format === "Headers") {
      return item.Table_name;
    } else if (value?.format === "Add Details") {
      return (
        <Button style={{ border: "1px solid #3079bd" }}>Add details</Button>
      );
    } else if (
      value?.format === "False" ||
      value?.format === "False " ||
      // value?.format === "Total" ||
      value?.format === "auto"
    ) {
      return null;
    } else if (item[key]?.format?.includes("Please save")) {
      return null;
    } else if (item.Field_button === "True") {
      return (
        <>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              border: "1px solid white",
              background: "#45b653",
              borderRadius: "20px",
              color: "white",
              padding: "5px 30px",
            }}
            onClick={submitForDraft}
          >
            Save
          </Button>
        </>
      );
    } else {
      if (typeof value?.format === "string") {
        value = value?.format?.replaceAll("_", " ") || value?.format;
        return value;
      } else {
        return value?.format;
      }
    }
  };

  const sortFieldKeys = (keys) => {
    return keys.sort((a, b) => {
      const fieldA = a.match(/^Field_(\d+)$/);
      const fieldB = b.match(/^Field_(\d+)$/);

      if (fieldA && fieldB) {
        return parseInt(fieldA[1]) - parseInt(fieldB[1]);
      } else if (fieldA) {
        return -1;
      } else if (fieldB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const closeSimplePopup = () => {
    setSecondModal(false);
    navigate("/reporting/dashboard");
  };
  const role = getUserRole();
  return (
    <>
      {getUserRole() === "User" &&
        <div style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: 'end',
          marginRight: "20px"
        }}>

          {/* {detail.Description && ( */}
          <Tooltip title="Guide">
            <IconButton
              // disabled={detail.Description === undefined}

              className="thumbdown"
            // onClick={() =>
            //   handleOpenDecsModal(detail, i)
            // }
            >
              <MenuBookIcon style={{ color: "rgb(69, 182, 83)" }}
              />{" "}
            </IconButton>
          </Tooltip>
          {/* )}{" "} */}
          <Tooltip title="Comment">
            <span>
              <IconButton
                className="thumbdown"
                onClick={() => handleCommentBoxOpen()}
                disabled={(status ===
                  "approved") || (status ===
                    "unfilled") || (status ===
                      "saved") || (reviewedBy === undefined)}
              >
                <CommentIcon style={{ color: "#3079bd" }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
      <TableContainer>
        {(status === "unfilled" || (status === "drafted" && role !== "SPOC")) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsInfoCircle
                style={{ color: "red", fontWeight: "600", marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                Please Save the Table Details Before Submitting
              </Typography>
            </div>
            <Button
              sx={{ textTransform: "none" }}
              style={{
                border: "1px solid white",
                background: "#45b653",
                borderRadius: "20px",
                color: "white",
                padding: "5px 30px",
              }}
              onClick={status === "drafted" ? submitForDraft : submit}
            >
              Save
            </Button>
          </Box>
        )}
        <Table style={{ overflow: "scroll" }}>
          <TableBody
            style={{
              padding: "2rem",
            }}
          >
            {status === "unfilled" &&
              updatedTableData?.map((item, index) => {
                const header = item.Field_1;
                if (item.autoCompute !== "Y") {
                  return (
                    <TableRow
                      key={index}
                      style={
                        header === "Header" || header === "Headers"
                          ? // ? { background: "#3079bd", color: "white" }
                          { background: "#E3F4F4" }
                          : {}
                      }
                    >
                      {
                        sortFieldKeys(
                          Object.keys(item).filter(
                            (keyFilter) =>
                              keyFilter.startsWith("Field_") ||
                              keyFilter.includes("autoCompute")
                          )).map((keyMap, keyIndex) => {

                            return (
                              <>
                                <TableCell
                                  key={keyIndex}
                                  style={{
                                    // wordBreak: "break-word",
                                    backgroundColor:
                                      keyIndex === 0 ? "#E3F4F4" : "",

                                    visibility:
                                      (item[keyMap].format &&
                                        item[keyMap].format.includes("Please")) ||
                                        (item[keyMap].format &&
                                          item[keyMap].format.includes("save"))
                                        ? "hidden"
                                        : "visible",
                                  }}
                                >

                                  {renderCellValue(
                                    item,
                                    item[keyMap],
                                    index,
                                    keyMap
                                  )}
                                </TableCell>
                              </>
                            );
                          })}
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}

            {(status === "saved" || status === "approved" || (status === "drafted" && isreviewed === "yes")) &&
              tableRendering?.map((item, index) => {
                const header = item.Field_1;
                // if (item.autoCompute !== "Y") {
                return (
                  <TableRow
                    key={index}
                    style={
                      header === "Header" || header === "Headers"
                        ? { background: "#E3F4F4" }
                        : {}
                    }
                  >
                    {sortFieldKeys(
                      Object.keys(item).filter(
                        (keyFilter) =>
                          keyFilter.startsWith("Field_") ||
                          keyFilter.includes("autoCompute")
                      )
                    ).map((keyMap, keyIndex) => {
                      return (
                        <TableCell
                          key={keyIndex}
                          style={{
                            // wordBreak: "break-word",
                            backgroundColor:
                              keyIndex === 0 ? "#E3F4F4" : "",
                          }}
                        >

                          {renderCellValue1(item[keyMap], item, keyIndex)}

                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
                // } else {
                // return null;
                // }
              })}
            {status === "drafted" &&
              editableInput?.map((item, index) => {
                const header = item.Field_1;
                if (item.autoCompute !== "Y") {
                  return (
                    <>
                      <TableRow
                        key={index}
                        style={
                          header === "Header" || header === "Headers"
                            ? { background: "#E3F4F4" }
                            : {}
                        }
                      >
                        {sortFieldKeys(Object.keys(item)
                          .filter((keyFilter) => keyFilter.startsWith("Field_"))
                          // .sort()
                        )
                          .map((keyMap, keyIndex) => {
                            return (
                              <TableCell
                                key={keyIndex}
                                style={{
                                  // wordBreak: "break-word",
                                  backgroundColor:
                                    keyIndex === 0 ? "#E3F4F4" : "",
                                }}
                              >
                                {renderEditableCellValue(
                                  item,
                                  item[keyMap],
                                  index,
                                  keyMap
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </>
                  );
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        type="button"
        onClick={openPopup}
        style={
          status === "saved" || status === "approved"
            ? buttoncontaindedfixeddisabled
            : buttoncontaindedfixed
        }
        disabled={status === "saved" || status === "approved"}
      >
        Next
      </Button>

      {showModal && (
        <BasicModal
          message="Please select the action to be performed."
          closePopup={closePopup}
          openPopup={openPopup}
          handleNavigate={handleNavigate}
          confirm={confirm}
          setConfirm={setConfirm}
          handleClickDraft={handleClickDraft}
          handleNavigateSaveDraft={handleNavigateSaveDraft}
        />
      )}

      {secondModal && (
        <SimpleModal
          openPopup={secondModal}
          message={`Good Job! You have filled ${filledKpis} response`}
          draftMessage={`Good Job! You have Drafted ${draftedKpis} response`}
          closeSimplePopup={closeSimplePopup}
          closePopup={closePopup}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          title={"Confirm Submitting"}
          onClose={closePopup}
          message={"Drafted Data will also we saved after confirming"}
          confirmText={"confirm"}
          cancelText={"cancel"}
          onConfirm={handleNavigate}
        />
      )}
      {commentBoxOpen &&
        <CommentPopUp
          open={commentBoxOpen}
          handleClose={handleCommentBoxClose}
          // onSubmitComment={handleAddComment}
          // submittedComments={submittedComments}
          // draftComment={draftComment}
          // setDraftComment={setDraftComment}
          // onDeleteComment={handleDeleteComment}
          // onEditComment={handleEditComment}
          reviewedBy={reviewedBy}
          commentsArray={comments}
        />}
    </>
  );
};
