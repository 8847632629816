import React, { useState } from "react";
import "../../styles.css";
import { Button } from "@mui/material";
import ForgotPassword from "./ForgotPasswords";
import NewPassword from "../../components/Newpassword/NewPassword";
import { buttonoutlined } from "../../styles";

export default function FP() {
  const [type, setType] = useState("ForgotPassword");
  const handleOnClick = (text) => {
    if (text !== type) {
      setType(text);
      return;
    }
  };
  const containerClass =
    "container " + (type === "ForgotPassword" ? "right-panel-active" : "");
  return (
    <div className="Login">
      <div className={containerClass} id="container">
        <ForgotPassword clcike={handleOnClick} />
        <NewPassword clcike={handleOnClick} />
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <div className="div-for-i">
                <img
                  alt="logo"
                  src="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
                  className="logo-i"
                />
              </div>
              <br />
              <h1>EnvSaGe</h1>
              <p className="h1">The digital ESG pathway</p>
              <br />
            </div>
            <div className="overlay-panel overlay-right">
              <div className="div-for-i">
                <img
                  alt="logo"
                  src="https://envintglobal.com/wp-content/uploads/2024/03/envint-circle-logo.png"
                  className="logo-i"
                />
              </div>
              <h1>EnvSaGe</h1>
              <p className="h1">The digital ESG pathway</p>
              <Button
                variant="outlined"
                className="button-s "
                style={buttonoutlined}
                id="About"
                onClick={() => handleOnClick("ForgotPassword")}
              >
                Send OTP
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
