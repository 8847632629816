import React, {useRef} from "react";
import {motion, useInView} from "framer-motion";
import {Box, Card, CardContent, CardMedia} from "@mui/material";
import {MdEmail} from "react-icons/md";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {getOrg, getUserRole} from "../../services/loggedIn";

const OrganizationCards = ({
  handleSelectChange,
  org,
  index,
  selectedOrg,
  onkeyPress,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.1 });

  const handleDoubleClick = () => {
    handleSelectChange(org);
  };

  const userRole = getUserRole();
  const currentOrg = getOrg();
    // const isConsultant = userRole === "Consultant";
  const isDisabled = org.organizationName === currentOrg;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
    >
      <Card
        onClick={() => !isDisabled && handleSelectChange(org)}
        raised={selectedOrg === org.organizationEmail}
        sx={{
          padding: "10px",
          borderRadius: "10px",
          display: "flex",
          height: "100px",
          cursor: isDisabled ? "not-allowed" : "pointer",
          "&:hover": {
            boxShadow: isDisabled ? "none" : 6,
          },
          opacity: isDisabled ? 0.8 : 1,
          border:
            selectedOrg === org.organizationEmail ? "1px solid #3079bd" : "",
          pointerEvents: isDisabled ? "none" : "auto",
          backgroundColor: isDisabled ? "#f0f0f0" : "white",
        }}

      >
        <CardMedia
          component="img"
          sx={{
            width: 100,
            height: "100%",
            objectFit: "contain",
          }}
          image={org.image || "https://via.placeholder.com/100"}
          alt={org.organizationName}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
            width: "calc(100% - 100px)", // Ensure content area has a fixed width
          }}
          
        >
          <CardContent
            sx={{
              flex: "1 0 auto",
              padding: "12px",
              "&:last-child": { paddingBottom: "12px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CorporateFareIcon
                style={{
                  padding: "5px",
                  fontSize: "18px",
                  color: "white",
                  borderRadius: "50%",
                  margin: "0 5px",
                  backgroundColor: "#45b653",
                }}
              />
              {org.organizationName}
            </h4>
            <h5
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                color: "grey",
              }}
            >
              <MdEmail style={{ padding: "0px 4px", marginBottom: "-2px" }} />

              <span>{org.organizationEmail}</span>
            </h5>
          </CardContent>
        </Box>
      </Card>
    </motion.div>
  );
};

export default OrganizationCards;
