import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";

export const ArrowFilterDropdown = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <Select
      // fullWidth
      value={selectedOption}
      onChange={(e) => handleSelect(e.target.value)}
      style={{
        fontSize: "12px",
        height: "30px",
        width: "100px",
        borderRadius: "5px",
      }}
    >
      <MenuItem value="">All</MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};
