import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Backdrop,Box,Button,Checkbox,CircularProgress,Table,TableBody,TableCell,TableContainer,TableRow,Tooltip} from "@mui/material";
import { FramerScroll } from "../FramerScroll/FramerScroll";
import { backBtnfixed, buttoncontaindedfixed } from "../../styles";
import Hamburger from "../Navbars/Hamburger";
import { toast } from "react-toastify";
import encrypt from "../../services/crypto";
import { addUser } from "../../services/UsermanagementApi";
import UserMultiStep from "../../pages/MultiStepAddUser/UserMultiStep";

const Department = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkedDepartments, setCheckedDepartments] = useState(
    location.state?.checkedDepartments || {}
  );
  const [loading, setLoading] = useState(false);
  const userData = location.state.data;
  const data = location.state.backenddata;


  const data0 = data.filter(
    (x) => x.organizationEmail === userData.organizationEmail // modifiedKKOrgName
  );
  const operationalUnitsData = JSON.parse(
    JSON.parse(data0[0].operationalUnits)
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const toggleCheckbox = (plant, category) => {
    setCheckedDepartments((prevCheckedDepartments) => {
      const currentCheckedDepartments = prevCheckedDepartments[plant] || [];
      const updatedDepartments = currentCheckedDepartments.includes(category)
        ? currentCheckedDepartments.filter((dept) => dept !== category)
        : [...currentCheckedDepartments, category];

      return { ...prevCheckedDepartments, [plant]: updatedDepartments };
    });
  };

  const selectAll = () => {
    const newCheckedDepartments = {};
    allPlants.forEach((plant) => {
      newCheckedDepartments[plant] = uniqueCategories;
    });
    setCheckedDepartments(newCheckedDepartments);
  };

  const clearAll = () => {
    setCheckedDepartments({});
  };

  const allCategories = operationalUnitsData.reduce((categories, unit) => {
    return categories.concat(Object.values(unit)[0].split(","));
  }, []);
  const allPlants = operationalUnitsData.map((unit) => Object.keys(unit)[0]);

  const uniqueCategories = Array.from(
    new Set(
      operationalUnitsData.flatMap((unit) => Object.values(unit)[0].split(", "))
    )
  );

  const handlesubmit = async () => {
    const filteredCheckedDepartments = Object.fromEntries(
      Object.entries(checkedDepartments).filter(
        ([key, value]) => value.length > 0
      )
    );
    const converted_data = Object.entries(filteredCheckedDepartments).map(
      ([key, valueList]) => ({ [key]: valueList })
    );
    userData.operationalUnits = converted_data;

    const encryptedUserData = encrypt({ userData });

    try {
      setLoading(true);
      const response = await addUser(encryptedUserData);
      navigate("/organization_list", {
        state: {
          organizationName: location.state?.organizationName,
          organizationEmail: location.state?.orgEmail,
        },
      });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/user/add-user", {
      state: {
        data: userData,
        backenddata: data,
        checkedDepartments: checkedDepartments,
      },
    });
  };

  const step = 1;

  return (
    <div style={{ padding: "10px" }}>
      <Hamburger />
      <FramerScroll />
      <div
        style={{
          marginTop: "5%",
          padding: "20px",
          borderRadius: "20px",
          backgroundColor: "#f9f8f9",
        }}
      >
        <UserMultiStep step={step} />
        <Box style={{ display: "flex", margin: "10px 0 20px 0" }}>
          <h3 className="frame_heading">
            Add Departments and Operational Units
          </h3>
        </Box>
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              borderRadius: "20px",
              border: "1px solid gray",
              margin: "0 10px",
            }}
            onClick={clearAll}
          >
            Clear All
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              borderRadius: "20px",
              border: "1px solid gray",
            }}
            onClick={selectAll}
          >
            Select All
          </Button>
        </div>
        <br />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ background: "white" }}>
                <TableCell
                  style={{
                    color: "#3079bd",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                  }}
                >
                  Category/Plant
                </TableCell>
                {allPlants.map((plant, index) => (
                  <Tooltip title={plant} key={index}>
                    <TableCell
                      style={{
                        color: "#3079bd",
                        fontWeight: "600",
                        fontSize: "1.2rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                      }}
                    >
                      {plant}
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
              {uniqueCategories.map((category, index) => (
                <TableRow key={index}>
                  <TableCell>{category}</TableCell>
                  {allPlants.map((plant, index) => (
                    <TableCell key={index}>
                      {operationalUnitsData.find((unit) =>
                        unit[plant]?.includes(category)
                      ) ? (
                        <Checkbox
                          checked={
                            checkedDepartments[plant]?.includes(category) ||
                            false
                          }
                          onChange={() => toggleCheckbox(plant, category)}
                        />
                      ) : (
                        <p>N/a</p>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />

        <Button onClick={handleBack} style={backBtnfixed}>
          Back
        </Button>
        <Button onClick={handlesubmit} style={buttoncontaindedfixed}>
          Submit
        </Button>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Department;